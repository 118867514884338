<template>
  <main class="width100 height100">
    <div v-if="action === 'delete'"><ThumbnailDelete /></div>
    <div v-if="action === 'create'"><ThumbnailCreate /></div>
    <div v-if="action === 'update'"><ThumbnailUpdate /></div>
  </main>
</template>

<script>
import ThumbnailDelete from "../dynamicThumbnails/ThumbnailDelete.vue";
import ThumbnailCreate from "../dynamicThumbnails/ThumbnailCreate.vue";
import ThumbnailUpdate from "../dynamicThumbnails/ThumbnailUpdate.vue";
export default {
  props: ["action"],
  components: {
    ThumbnailDelete,
    ThumbnailCreate,
    ThumbnailUpdate,
  },
  mounted() {
    console.log("ACTION PASSED: ", this.action);
  },
};
</script>

<style scoped>
</style>
