<template>
  <main class="confirm-action-container" v-if="action && item">
    <h1 class="-xltb">{{ action }} {{ item.context }}</h1>
    <section class="modal-body">
      <div class="modal-body-wrapper">
        <div class="thumbnail-wrapper">
          <ThumbnailManager :action="action" />
        </div>
        <!-- <img :src="updateFile" v-if="getAction === 'update'" /> -->
        <div class="modal-content-wrapper">
          <p class="-ntb" :class="{ 'delete-h': action === 'delete' }">
            {{ item.title }}
          </p>
          <p class="-str">
            {{ item.msg }}
          </p>
        </div>
      </div>
      <!-- <a
        href="https://storyset.com/woman"
        target="”_blank”"
        class="storyset-ref"
        >Illustration by Storyset</a
      > -->
    </section>

    <section class="form-btns-group">
      <div class="cancel" @click="$emit('closeModal')">
        <p class="-xstb">Cancel</p>
      </div>

      <div :class="getAction" @click="handleClick(item.reference)">
        <p class="-xstb">Confirm</p>
      </div>
    </section>
  </main>
</template>

<script>
import BaseCancelSave from "../../utils/BaseCancelSave.vue";
import ThumbnailManager from "../../utils/ThumbnailManager.vue";
export default {
  components: {
    BaseCancelSave,
    ThumbnailManager,
  },
  emits: ["closeModal", "confirmAction"],
  props: ["item", "action"],
  //item prop should be an object with a title, reference and context

  //***SCHEMA***//
  // <Modal
  //   v-if="modalConfig.opened"
  //   :setModal="modalConfig.state.type"
  //   :action="'delete'"
  //   :item="{
  //     title: unit.unit_title,
  //     reference: unit.unit_reference,
  //     context: 'unit',
  //   }"
  //   @confirmAction="deleteUnit(unit.id)"
  //   @closeModal="modalConfig.opened = false"
  //>

  //THE CONFIG FILE SCHEME
  // modalConfig: {
  // opened: false,
  // state: {
  //   type: "confirmAction",
  //   action: null,
  // },

  data() {
    return {
      actions: ["delete", "update", "create"],
    };
  },
  methods: {
    handleClick(id) {
      this.$emit("confirmAction", id);
    },
  },
  computed: {
    getAction() {
      console.log(this.actions.find((a) => a === this.action));
      return this.actions.find((action) => action === this.action);
    },
  },
  mounted() {
    console.log("ACTION PASSED: ", this.action);

    if (!this.action || !this.item) this.$emit("closeModal");

    console.log(this.item);
  },
};
</script>

<style scoped>
.confirm-action-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: space-evenly;
  overflow: auto;
  overflow-x: hidden;
  padding: 16px;
  /* background: red; */
}
h1 {
  text-transform: capitalize;
}
.modal-body {
  width: 100%;
  position: relative;
}
.modal-body-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 8px;
}
.modal-body-wrapper p {
  width: 100%;
}
.thumbnail-wrapper {
  min-width: 320px;
  max-width: 320px;
  transition: all 200ms;
  border-radius: 50%;
}
.modal-content-wrapper {
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding: 1rem;
  margin-bottom: 4rem;
  gap: 2rem;
}
.modal-content-wrapper > .-str {
  animation: reverse-slide-hleft-text 1.2s cubic-bezier(0, -0.01, 0.01, 1);
}
a {
  position: absolute;
  /* bottom: 1rem; */
  left: 1rem;
}
.form-btns-group div {
  padding: 8px;
  transition: background 400ms;
  cursor: pointer;
  user-select: none;
  border-radius: 4px;
}
.form-btns-group div:active {
  scale: 0.9;
}
.delete-h {
  color: var(--error);
}
.delete {
  background: rgba(255, 30, 30, 0.2);
  color: white;
}
.delete:hover {
  background: rgba(255, 30, 30, 0.6);
}
.update,
.create {
  background: rgba(84, 141, 187, 0.2);
}
.update:hover,
.create:hover {
  background: rgba(84, 141, 187, 0.4);
}

.cancel {
  background: rgba(212, 212, 212, 0.2);
}
.cancel:hover {
  background: rgba(212, 212, 212, 0.4);
}

@media screen and (max-width: 1279px) {
  thumbnail-wrapper {
    min-width: 400px;
    max-width: 400px;
  }
}
@media screen and (max-width: 1023px) {
  thumbnail-wrapper {
    min-width: 320px;
    max-width: 320px;
  }
}
@media screen and (max-width: 719px) {
  .modal-body-wrapper {
    flex-flow: column;
    align-items: center;
  }
}
</style>