import { validateLength } from "./validator";
import { addErrInputText, removeErrInputText } from "./styleToggler";

//all the parameters are arrays
export const validateForm = (
  arrayOfElements, //arrayOfElements is populated with the elements from this.$refs
  infoToValidate, //infoToValidate is populated with the values from the elements
  minlengths, //minlengths and maxlengths are populated
  maxlengths // with the respective values from the elements
) => {
  const expectedSize = arrayOfElements.length;
  let counter = 0;
  if (!minlengths) minlengths = []; //this is to avoid errors when minlengths is not passed as a parameter
  if (!maxlengths) maxlengths = []; //this is to avoid errors when maxlengths is not passed as a parameter

  arrayOfElements.forEach((element, index) => {
    const getMinLength = minlengths[index] ? minlengths[index] : 1;
    const getMaxLength = maxlengths[index] ? maxlengths[index] : 5;

    validateLength(infoToValidate[index], getMinLength, getMaxLength)
      ? counter++
      : addErrInputText(element);
  });

  return expectedSize === counter;
};
