<template>
  <main class="width100 height100">
    <svg
      class="animated"
      id="freepik_stories-create"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 500 500"
      version="1.1"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      xmlns:svgjs="http://svgjs.com/svgjs"
    >
      <g
        id="freepik--background-complete--inject-6"
        class="animable"
        style="transform-origin: 253.96px 255.64px"
      >
        <g id="elqhn7nrkf67c">
          <rect
            x="389.66"
            y="123.02"
            width="54.81"
            height="69.2"
            style="
              fill: rgb(255, 255, 255);
              transform-origin: 417.065px 157.62px;
              transform: rotate(-0.23deg);
            "
            class="animable"
          ></rect>
        </g>
        <path
          d="M389.8,192.33l1,0,2.94,0,11.12-.1,39.73-.28-.21.22c-.09-19.76-.21-43.57-.33-69.19h0l.26.26-54.81.2.23-.23c0,20.51.07,37.78.09,49.94v14.18c0,1.63,0,2.89,0,3.76,0,.42,0,.74,0,1s0,.33,0,.33,0-.1,0-.31l0-1c0-.86,0-2.1,0-3.72,0-3.28-.07-8.06-.12-14.12-.08-12.19-.19-29.51-.32-50.09v-.24h.24l54.81-.25h.26v.26h0c.09,25.62.17,49.43.24,69.19v.22h-.21l-39.88,0H393.67l-2.89,0C390.13,192.35,389.8,192.33,389.8,192.33Z"
          style="
            fill: rgb(224, 224, 224);
            transform-origin: 417.155px 157.555px;
          "
          id="el59dn0ajzj2"
          class="animable"
        ></path>
        <g id="elsetpradl5ec">
          <rect
            x="413.75"
            y="190.67"
            width="7.6"
            height="1.85"
            style="
              fill: rgb(224, 224, 224);
              transform-origin: 417.55px 191.595px;
              transform: rotate(-0.24deg);
            "
            class="animable"
          ></rect>
        </g>
        <g id="elhg47g92ata">
          <rect
            x="409.11"
            y="147.23"
            width="16.63"
            height="24.01"
            style="
              fill: rgb(224, 224, 224);
              transform-origin: 417.425px 159.235px;
              transform: rotate(-0.23deg);
            "
            class="animable"
          ></rect>
        </g>
        <g id="el817uo4lpv7l">
          <rect
            x="414.54"
            y="122.08"
            width="7.6"
            height="1.58"
            style="
              fill: rgb(224, 224, 224);
              transform-origin: 418.34px 122.87px;
              transform: rotate(-0.24deg);
            "
            class="animable"
          ></rect>
        </g>
        <polygon
          points="136.41 78.91 86.88 78.91 86.88 143.5 136.41 144.74 136.41 78.91"
          style="
            fill: rgb(224, 224, 224);
            transform-origin: 111.645px 111.825px;
          "
          id="elpv8vti2r4kq"
          class="animable"
        ></polygon>
        <polygon
          points="133.15 81.21 89.81 81.21 89.81 139.79 133.15 141.25 133.15 81.21"
          style="fill: rgb(255, 255, 255); transform-origin: 111.48px 111.23px"
          id="els5vsodhcatf"
          class="animable"
        ></polygon>
        <polygon
          points="125.56 91.85 96.8 90.76 96.8 131.7 125.56 131.7 125.56 91.85"
          style="fill: rgb(224, 224, 224); transform-origin: 111.18px 111.23px"
          id="el2swp6o0vacb"
          class="animable"
        ></polygon>
        <polygon
          points="96.8 131.7 125.56 110.67 96.8 90.76 96.8 131.7"
          style="fill: rgb(245, 245, 245); transform-origin: 111.18px 111.23px"
          id="elqvbs8y46hyj"
          class="animable"
        ></polygon>
        <polygon
          points="96.8 178.26 62.99 178.26 62.99 222.34 96.8 223.19 96.8 178.26"
          style="fill: rgb(224, 224, 224); transform-origin: 79.895px 200.725px"
          id="elhx0z7wwpcv7"
          class="animable"
        ></polygon>
        <polygon
          points="94.57 179.83 64.99 179.83 64.99 219.81 94.57 220.81 94.57 179.83"
          style="fill: rgb(255, 255, 255); transform-origin: 79.78px 200.32px"
          id="el4c34bw27uvc"
          class="animable"
        ></polygon>
        <polygon
          points="89.39 187.1 69.76 186.35 69.76 214.29 89.39 214.29 89.39 187.1"
          style="fill: rgb(224, 224, 224); transform-origin: 79.575px 200.32px"
          id="elhuqsjidticc"
          class="animable"
        ></polygon>
        <polygon
          points="69.76 214.29 89.39 199.94 69.76 186.35 69.76 214.29"
          style="fill: rgb(245, 245, 245); transform-origin: 79.575px 200.32px"
          id="elmvn2ow1vzw"
          class="animable"
        ></polygon>
        <path
          d="M388.47,422.32c-1.33-1.14-4.29-4.73-2-8.91.84-1.53,2.62-2.29,4.23-3a17,17,0,0,0,4.64-2.39c2.51-2.13,3.21-5.66,4.87-8.5,1.92-3.26,5.5-5.71,9.27-5.53,4.33.19,7.94,3.71,9.57,7.72s1.69,8.46,1.73,12.79c0,2.4-.09,5.09-1.86,6.71s-4.08,1.51-6.28,1.44L389,422"
          style="
            fill: rgb(245, 245, 245);
            transform-origin: 403.204px 408.332px;
          "
          id="elv2ms4ypy27"
          class="animable"
        ></path>
        <path
          d="M429.88,366.18H373.35l10.19,60.43a6.74,6.74,0,0,0,4.82,5.38l.2.06c.17,0,.33.1.5.13a8.8,8.8,0,0,0,1.15.13v.06l.28-.05h25.19a7.12,7.12,0,0,0,1.39-.13,6.79,6.79,0,0,0,5.71-5.91l4.91-41.64.22-1.66h0l1.94-16.49Zm-10.67,38.55h-5.8l.59-17.54h6.72Zm2.07-17.54h5.56l-2.07,17.54h-5Zm-16.57,37-.35-15.23h8.35l-.51,15.23Zm-9-15.23h8.12l.35,15.23h-7.28Zm.63,15.23H389.5c-.64-4.11-1.49-9.46-2.4-15.23h8Zm-1-19.44L394,387.19h9.31l.41,17.54Zm8.91,0-.4-17.54h9.58l-.59,17.54Zm-.5-21.75-.38-16.24h10.74L413.58,383Zm-.56,0h-9.55l-1.26-16.24h10.43Zm-10.11,0H383c-.36-2.24-.7-4.36-1-6.27-1.05-6.57-1.45-9-1.67-10h11.5Zm.33,4.21,1.37,17.54h-8.35c-.28-1.78-.57-3.58-.85-5.38-.65-4.14-1.31-8.28-1.93-12.16Zm-7.55,17.54h-5.45l-3-17.54h5.64C384,392.61,384.91,398.64,385.87,404.73Zm.67,4.21c.83,5.27,1.65,10.47,2.39,15.23h-5.24l-2.56-15.23Zm26.23,15.23.5-15.23h5.58l-1.3,15.23ZM414.14,383l.54-16.24h7.8L421.08,383Zm-34.35-16.24c.22,1.18,1.26,7.57,2.64,16.24h-5.68L374,366.74Zm42.69,57.43h-4.37l1.31-15.23h4.85ZM427.33,383h-5.69l1.4-16.24h6.21Z"
          style="
            fill: rgb(224, 224, 224);
            transform-origin: 401.615px 399.275px;
          "
          id="elbu3qa419k76"
          class="animable"
        ></path>
      </g>
      <g
        id="freepik--Floor--inject-6"
        class="animable"
        style="transform-origin: 254.87px 432.75px"
      >
        <path
          d="M468.49,432.75c0,.21-95.65.38-213.61.38S41.25,433,41.25,432.75s95.63-.38,213.63-.38S468.49,432.54,468.49,432.75Z"
          style="fill: rgb(38, 50, 56); transform-origin: 254.87px 432.75px"
          id="elazrnnst9qcm"
          class="animable"
        ></path>
      </g>
      <g
        id="freepik--Sketches--inject-6"
        class="animable"
        style="transform-origin: 257.444px 128.29px"
      >
        <g id="el9h2qmp1cozn">
          <rect
            x="179.87"
            y="107.81"
            width="61.84"
            height="86.29"
            style="
              fill: rgb(245, 245, 245);
              transform-origin: 210.79px 150.955px;
              transform: rotate(0.22deg);
            "
            class="animable"
          ></rect>
        </g>
        <path
          d="M193.89,168.31h.92l2.63,0,9.85,0-.14.15c0-4.36-.05-9.76-.08-15.94v-.2h.19l9.65-.17h.22v.22c.1,4.92.21,10.34.33,16.05l-.25-.24,13.74.09-.25.27c-.13-4.67-.25-9.53-.38-14.5-.1-3.75-.2-7.45-.29-11.06l.1.2L211,128.29h.3l-17.67,16,.06-.14c.09,7.44.16,13.46.21,17.65,0,2.07,0,3.69,0,4.82v1.27a3,3,0,0,1,0,.44,1.87,1.87,0,0,1,0-.4c0-.3,0-.71,0-1.23,0-1.13-.07-2.73-.13-4.79-.09-4.21-.23-10.27-.4-17.75v-.08l.06-.06L211,127.93l.14-.13.16.12,19.2,14.74.09.08v.12q.13,5.41.29,11.06c.12,5,.25,9.83.37,14.49v.27H231l-13.74-.12H217v-.24c-.1-5.71-.2-11.13-.28-16l.22.22-9.64.13.19-.2c0,6.18,0,11.58,0,15.94v.14h-.14l-9.94-.11-2.59,0-.66,0Z"
          style="fill: rgb(38, 50, 56); transform-origin: 212.31px 148.24px"
          id="elzb5k2p4p689"
          class="animable"
        ></path>
        <path
          d="M183.74,110.43h3.83l11,.08,39.93.36h.2v.21c0,21.94,0,49.39,0,79.11v.26h-.67l-54.15.33h-.24V132.7c0-7,0-12.61,0-16.44,0-1.89,0-3.35,0-4.35,0-.48,0-.85,0-1.11s0-.37,0-.37,0,.14,0,.4l0,1.13c0,1,0,2.49,0,4.4,0,3.84.06,9.43.1,16.49.06,14.12.14,34.12.23,57.69l-.23-.24,54.15-.37h.41l-.26.26c0-29.72,0-57.17,0-79.11l.21.21-39.79-.58-11-.19-2.9,0Z"
          style="fill: rgb(38, 50, 56); transform-origin: 211.17px 150.605px"
          id="el7tncem5pmd7"
          class="animable"
        ></path>
        <polygon
          points="230.92 104.67 243.94 117.59 246.22 114.64 233.12 102.89 230.92 104.67"
          style="fill: rgb(69, 90, 100); transform-origin: 238.57px 110.24px"
          id="elzapl9zuxoc"
          class="animable"
        ></polygon>
        <polygon
          points="175.15 186.43 188.16 199.35 190.45 196.4 177.34 184.66 175.15 186.43"
          style="fill: rgb(69, 90, 100); transform-origin: 182.8px 192.005px"
          id="el2qfn9ccpxel"
          class="animable"
        ></polygon>
        <path
          d="M238.47,186.2a24.73,24.73,0,0,1-3.49.21c-2.16.06-5.15.11-8.45.14-3.07,0-5.88,0-8,0l.19-.2c0,1.23,0,2.22,0,2.91a4.46,4.46,0,0,1-.06,1.07,4,4,0,0,1-.15-1.07c-.06-.68-.13-1.67-.19-2.89l0-.2h.21c2.09-.06,4.89-.11,8-.13,3.3,0,6.28,0,8.44,0A21.72,21.72,0,0,1,238.47,186.2Z"
          style="fill: rgb(38, 50, 56); transform-origin: 228.395px 188.178px"
          id="elqnng7t36wap"
          class="animable"
        ></path>
        <g id="el2z5xsjhl344">
          <rect
            x="283.13"
            y="127.11"
            width="43.27"
            height="60.37"
            style="
              fill: rgb(235, 235, 235);
              transform-origin: 304.765px 157.295px;
              transform: rotate(-11.87deg);
            "
            class="animable"
          ></rect>
        </g>
        <path
          d="M295.75,171.65l.16-.05.47-.11,1.8-.41,6.71-1.5-.1.17c-.66-3-1.48-6.67-2.41-10.89l0-.19.19,0,6.57-1.53.21-.05.05.21c.79,3.35,1.67,7,2.58,10.93l-.28-.18,9.41-2-.2.31c-.76-3.17-1.56-6.48-2.38-9.86-.62-2.55-1.22-5.06-1.82-7.52l.14.17-15.27-7.31.29-.07-9.76,13.52,0-.15,2.69,12c.31,1.41.55,2.52.72,3.31l.19.87a2.8,2.8,0,0,1,.05.31s0-.08-.08-.27-.13-.49-.22-.83l-.8-3.24c-.69-2.89-1.68-7-2.91-12.1l0-.08,0-.07,9.68-13.58.11-.16.18.09,15.3,7.27.11,0,0,.12c.59,2.45,1.2,5,1.81,7.52.82,3.38,1.62,6.69,2.38,9.86l.06.26-.26.06-9.42,1.93-.23,0,0-.23c-.9-3.89-1.76-7.58-2.54-10.94l.27.17L302.62,159l.15-.24c.89,4.23,1.66,7.93,2.29,10.92l0,.14-.14,0-6.83,1.36-1.78.34Z"
          style="fill: rgb(38, 50, 56); transform-origin: 306.565px 156.475px"
          id="elk7mem7goerf"
          class="animable"
        ></path>
        <path
          d="M280.33,133.54l.65-.16,1.95-.42,7.52-1.57,27.38-5.63.2-.05,0,.21L329.71,180l.05.25-.25.06-.28.06-37,8.15-.24,0-.05-.23c-3.45-16.18-6.37-29.9-8.44-39.61-1-4.8-1.81-8.61-2.37-11.24-.26-1.29-.47-2.28-.61-3-.06-.32-.11-.56-.15-.75s0-.26,0-.25a1.23,1.23,0,0,1,.07.27c.05.19.11.45.19.78.16.7.38,1.71.68,3,.58,2.64,1.43,6.46,2.5,11.28,2.12,9.65,5.11,23.31,8.64,39.4l-.29-.18,37-8.2.28-.06-.2.31c-4.33-20.33-8.33-39.11-11.54-54.13l.25.16-27.29,5.39L283,133l-2,.38Z"
          style="fill: rgb(38, 50, 56); transform-origin: 305.045px 157.115px"
          id="ely7t8uvocqwn"
          class="animable"
        ></path>
        <polygon
          points="311.76 122.68 322.56 129.61 323.69 127.26 313.01 121.15 311.76 122.68"
          style="fill: rgb(69, 90, 100); transform-origin: 317.725px 125.38px"
          id="elouogjd6kybi"
          class="animable"
        ></polygon>
        <polygon
          points="285.58 186.79 296.38 193.72 297.51 191.37 286.82 185.26 285.58 186.79"
          style="fill: rgb(69, 90, 100); transform-origin: 291.545px 189.49px"
          id="elgdg7uygtdb"
          class="animable"
        ></polygon>
        <path
          d="M328.87,177.36a11.8,11.8,0,0,1-2.35.71c-1.46.37-3.5.85-5.75,1.35-2.1.47-4,.87-5.46,1.15l.15-.24a14.34,14.34,0,0,1,.49,2.74,14.59,14.59,0,0,1-.87-2.64l-.05-.19.2-.05c1.41-.36,3.33-.81,5.43-1.28s4.3-.92,5.78-1.2A11.62,11.62,0,0,1,328.87,177.36Z"
          style="fill: rgb(38, 50, 56); transform-origin: 321.95px 180.215px"
          id="elqr7i1m1ur4"
          class="animable"
        ></path>
        <g id="elduz23ghi41a">
          <rect
            x="292.5"
            y="55.82"
            width="37.19"
            height="51.89"
            style="
              fill: rgb(235, 235, 235);
              transform-origin: 311.095px 81.765px;
              transform: rotate(-80.56deg);
            "
            class="animable"
          ></rect>
        </g>
        <path
          d="M301.34,90.86l.45,0L303,91l4.63.58-.16.12c.27-2.07.6-4.61,1-7.52l0-.19.19,0,4.56.54.22,0,0,.22c-.27,2.32-.57,4.88-.88,7.58l-.21-.27,6.47.93-.29.23c.24-2.21.5-4.51.76-6.85.2-1.78.4-3.53.59-5.23l.08.21-8.06-8.23.3,0-9.39,6.36.08-.13c-.46,3.46-.83,6.28-1.1,8.29-.14.95-.24,1.71-.32,2.28s-.13.81-.14.82a4,4,0,0,1,.05-.76l.23-2.25c.22-2,.53-4.91.92-8.43v-.08l.07,0,9.32-6.44.17-.11.14.14,8.08,8.19.09.09v.12c-.19,1.71-.39,3.46-.59,5.23-.26,2.35-.52,4.65-.77,6.86l0,.26-.27,0-6.46-1-.24,0,0-.24c.33-2.69.65-5.25.93-7.58l.19.25-4.55-.58.22-.17c-.42,2.9-.78,5.43-1.08,7.5l0,.14-.14,0-4.69-.75-1.21-.2A1.61,1.61,0,0,1,301.34,90.86Z"
          style="fill: rgb(38, 50, 56); transform-origin: 310.864px 83.115px"
          id="eloihp1vxtnco"
          class="animable"
        ></path>
        <path
          d="M284.43,93.92s0-.19.08-.57.15-1,.27-1.69c.25-1.52.62-3.71,1.08-6.51,1-5.68,2.35-13.81,4-23.69l0-.21.2,0,47,7.58.25,0,0,.26,0,.24c-1.79,11.47-3.5,22.38-5,32.18l0,.24-.23,0-34.36-5.63-9.75-1.62-2.56-.44-.66-.12-.21,0,.25,0,.68.1,2.62.39,9.82,1.51L332,101.31l-.27.2c1.52-9.81,3.21-20.72,5-32.19l0-.24.21.29L290.06,61.7l.24-.17c-1.76,9.81-3.21,17.88-4.22,23.53l-1.19,6.53-.33,1.73C284.49,93.71,284.43,93.92,284.43,93.92Z"
          style="fill: rgb(38, 50, 56); transform-origin: 310.87px 81.5px"
          id="eluy3eilpmgkp"
          class="animable"
        ></path>
        <polygon
          points="285.56 65.36 294.48 58.87 292.95 57.23 284.72 63.88 285.56 65.36"
          style="fill: rgb(69, 90, 100); transform-origin: 289.6px 61.295px"
          id="elbve1raqemc7"
          class="animable"
        ></polygon>
        <polygon
          points="328.72 106.35 337.64 99.86 336.11 98.22 327.88 104.87 328.72 106.35"
          style="fill: rgb(69, 90, 100); transform-origin: 332.76px 102.285px"
          id="elvthxhn2v1uo"
          class="animable"
        ></polygon>
        <path
          d="M334.68,68.73a48.55,48.55,0,0,1-.83,7.14c-.29,1.83-.57,3.49-.8,4.73l-.17-.22a10.13,10.13,0,0,1,2.34.48,10.14,10.14,0,0,1-2.39-.1l-.19,0,0-.2c.15-1.25.38-2.92.66-4.75A51.39,51.39,0,0,1,334.68,68.73Z"
          style="fill: rgb(38, 50, 56); transform-origin: 333.93px 74.8098px"
          id="eli1aq514c4"
          class="animable"
        ></path>
      </g>
      <g
        id="freepik--Desk--inject-6"
        class="animable"
        style="transform-origin: 270.905px 308px"
      >
        <path
          d="M192.6,277.19c-.18,1,0,155.56,0,155.56h-6.86V277.19Z"
          style="fill: rgb(69, 90, 100); transform-origin: 189.17px 354.97px"
          id="elghcz5ebnk5r"
          class="animable"
        ></path>
        <path
          d="M311.36,290.78c-.18,1,0,141.69,0,141.69H304.5V290.78Z"
          style="fill: rgb(69, 90, 100); transform-origin: 307.93px 361.625px"
          id="elzufjo47e8b9"
          class="animable"
        ></path>
        <path
          d="M345.86,265c-.17,1,0,167.5,0,167.5H339V265Z"
          style="fill: rgb(69, 90, 100); transform-origin: 342.43px 348.75px"
          id="elss1gv6fav2f"
          class="animable"
        ></path>
        <path
          d="M343.38,219.13c.56-.93,0,50.31,0,50.31l-6.32,1,1.5-50.67Z"
          style="fill: rgb(69, 90, 100); transform-origin: 340.344px 244.779px"
          id="elx40y6sav3nk"
          class="animable"
        ></path>
        <path
          d="M307.7,293.84c-.47,1.42,51-94.19,51-94.19l-.95-4.32L232.58,188.1,185,278.67Z"
          style="fill: rgb(38, 50, 56); transform-origin: 271.85px 240.978px"
          id="elfpzgdeq0wk7"
          class="animable"
        ></path>
        <path
          d="M307.18,290.43C307.06,288,357.75,195,357.75,195L230,183.25,183.11,275Z"
          style="fill: rgb(69, 90, 100); transform-origin: 270.43px 236.84px"
          id="el4vugywde8h8"
          class="animable"
        ></path>
        <polygon
          points="247.81 264.43 291.87 269.03 320.12 197.67 256.75 189.87 225 261.59 247.81 264.43"
          style="fill: rgb(245, 245, 245); transform-origin: 272.56px 229.45px"
          id="elqoqoqb8wvqm"
          class="animable"
        ></polygon>
        <path
          d="M288.26,208.08a6.34,6.34,0,0,1,1.05.28,10.31,10.31,0,0,1,2.76,1.42,12.1,12.1,0,0,1,3.35,3.59,10.94,10.94,0,0,1,1.61,6.28,12.27,12.27,0,0,1-2.9,7.29,10.12,10.12,0,0,1-3.51,2.72c-1.38.64-2.87,1.11-4.36,1.64-.75.27-1.5.54-2.25.85-.37.15-.73.32-1.1.49l-.54.28a2.57,2.57,0,0,0-.41.36,8.53,8.53,0,0,0-1.41,2c-.38.72-.6,1.56-1,2.36a4.67,4.67,0,0,1-1.79,2.06,4.74,4.74,0,0,1-2.74.38l-5.4-.63c-.91-.12-1.82-.25-2.72-.43a5.49,5.49,0,0,1-2.58-1.11,1.61,1.61,0,0,1-.54-1.41,4.83,4.83,0,0,1,.5-1.31c.39-.8.81-1.56,1.23-2.32l1.24-2.25.29-.56.14-.28a.54.54,0,0,0,.05-.21,4,4,0,0,0-.16-1.16c-.41-1.59-1-3.13-1.29-4.71a12.14,12.14,0,0,1,1.81-8.79,15.21,15.21,0,0,1,5.76-5.37,18.53,18.53,0,0,1,6.17-2,20.6,20.6,0,0,1,4.92,0c1.34.15,2.37.33,3.06.45l.79.15a.78.78,0,0,1,.27.07l-.27,0-.8-.1c-.7-.08-1.74-.23-3.07-.35a21.15,21.15,0,0,0-4.86.08,18.54,18.54,0,0,0-6,2.09,14.74,14.74,0,0,0-5.56,5.27,11.69,11.69,0,0,0-1.71,8.46c.31,1.54.87,3.06,1.3,4.69a4.58,4.58,0,0,1,.18,1.32,1.07,1.07,0,0,1-.1.39l-.14.29-.3.56L266,233.1c-.42.76-.84,1.52-1.22,2.3a4.69,4.69,0,0,0-.45,1.16,1.07,1.07,0,0,0,.39,1,5.1,5.1,0,0,0,2.33,1c.87.18,1.77.31,2.68.43l5.38.63a4.37,4.37,0,0,0,2.45-.32,4.17,4.17,0,0,0,1.57-1.84c.39-.74.61-1.58,1-2.36a9.16,9.16,0,0,1,1.49-2.11,3.44,3.44,0,0,1,.53-.44l.56-.29,1.14-.51c.76-.31,1.52-.58,2.27-.84,1.5-.52,3-1,4.33-1.61a9.49,9.49,0,0,0,3.37-2.58,12,12,0,0,0,2.87-7,10.68,10.68,0,0,0-1.5-6.11,12,12,0,0,0-3.22-3.58,11.28,11.28,0,0,0-2.68-1.49C288.62,208.2,288.26,208.1,288.26,208.08Z"
          style="
            fill: rgb(159, 209, 255);
            transform-origin: 280.406px 223.766px;
          "
          id="el0ejidnwlgw9"
          class="animable"
        ></path>
        <path
          d="M279.61,236.9a7.21,7.21,0,0,1-.51,1.32c-.36.83-.9,2-1.52,3.52-.31.75-.63,1.57-.95,2.47a6.93,6.93,0,0,1-1.5,2.73,6.31,6.31,0,0,1-3.11,1.51,13.81,13.81,0,0,1-3.66.28,13.6,13.6,0,0,1-3.6-.7,8.15,8.15,0,0,1-3-1.65,3.67,3.67,0,0,1-1.26-2.88,6.64,6.64,0,0,1,.93-2.55c.73-1.36,1.34-2.5,1.82-3.38a7,7,0,0,1,.73-1.21,7.17,7.17,0,0,1-.55,1.3c-.44.9-1,2.07-1.67,3.46a6.39,6.39,0,0,0-.83,2.41,3.3,3.3,0,0,0,1.15,2.5,8.15,8.15,0,0,0,2.86,1.52,13.53,13.53,0,0,0,7,.4,6,6,0,0,0,2.89-1.35,6.69,6.69,0,0,0,1.43-2.54c.34-.89.68-1.72,1-2.46.67-1.49,1.26-2.66,1.67-3.47A8.4,8.4,0,0,1,279.61,236.9Z"
          style="
            fill: rgb(159, 209, 255);
            transform-origin: 270.054px 242.556px;
          "
          id="el652ov85g7h4"
          class="animable"
        ></path>
        <path
          d="M277.74,241.42a10.81,10.81,0,0,1-2.36.06c-1.45,0-3.45-.1-5.66-.21s-4.2-.25-5.65-.37a9.89,9.89,0,0,1-2.34-.31,10.68,10.68,0,0,1,2.35-.06c1.45,0,3.46.1,5.67.22s4.21.24,5.65.36A9.77,9.77,0,0,1,277.74,241.42Z"
          style="
            fill: rgb(159, 209, 255);
            transform-origin: 269.735px 241.005px;
          "
          id="elk8eyh4kpqf"
          class="animable"
        ></path>
        <path
          d="M276.2,244.61a58,58,0,0,1-7.68.24,55.33,55.33,0,0,1-7.67-.28,57.78,57.78,0,0,1,7.67-.24A57.49,57.49,0,0,1,276.2,244.61Z"
          style="fill: rgb(159, 209, 255); transform-origin: 268.525px 244.59px"
          id="el5w8qr2p7ht9"
          class="animable"
        ></path>
        <path
          d="M270.09,248.43c.05,0-.08.51-.69,1a4.09,4.09,0,0,1-2.83.69,4.39,4.39,0,0,1-1.59-.34,1.78,1.78,0,0,1-1-1,1.2,1.2,0,0,1,0-.93c.1-.21.21-.27.24-.25a1.58,1.58,0,0,0,.08,1c.25.61,1.18.94,2.25,1a4.38,4.38,0,0,0,2.58-.47C269.78,248.77,270,248.38,270.09,248.43Z"
          style="
            fill: rgb(159, 209, 255);
            transform-origin: 266.993px 248.874px;
          "
          id="elz9aju13b5wm"
          class="animable"
        ></path>
        <path
          d="M272.34,239.48a5.59,5.59,0,0,1,.58-1.17l1.75-3.07c.78-1.32,1.68-2.84,2.66-4.52a6,6,0,0,0,1.17-2.67,2,2,0,0,0-1.95-1.85,3.63,3.63,0,0,0-2.77,1.19,16,16,0,0,0-1.83,2.42c-1.09,1.64-2,3.18-2.66,4.49s-1.2,2.41-1.55,3.17a6,6,0,0,1-.58,1.16,5.87,5.87,0,0,1,.39-1.24c.3-.78.76-1.91,1.41-3.25a44.23,44.23,0,0,1,2.59-4.59,16.13,16.13,0,0,1,1.87-2.53,4.13,4.13,0,0,1,3.17-1.34A2.55,2.55,0,0,1,279,228a3.31,3.31,0,0,1-.48,1.62c-.26.47-.53.88-.79,1.31L275,235.43l-1.9,3A7.36,7.36,0,0,1,272.34,239.48Z"
          style="fill: rgb(159, 209, 255); transform-origin: 273.08px 232.579px"
          id="elikkp55k6m5"
          class="animable"
        ></path>
        <path
          d="M274.75,219.9a9.86,9.86,0,0,1,.55,3.11,9.66,9.66,0,0,1,0,3.15,9.86,9.86,0,0,1-.55-3.11A9.34,9.34,0,0,1,274.75,219.9Z"
          style="fill: rgb(159, 209, 255); transform-origin: 275.023px 223.03px"
          id="elu7j306svs2"
          class="animable"
        ></path>
        <path
          d="M274.28,219.33c.06,0,0,.19.12.34s.26.24.42.08.1-.31-.07-.42-.3-.07-.34-.13.08-.27.44-.22a.64.64,0,0,1,.5.36.72.72,0,0,1-.94.94.67.67,0,0,1-.36-.51C274,219.41,274.24,219.27,274.28,219.33Z"
          style="
            fill: rgb(159, 209, 255);
            transform-origin: 274.724px 219.653px;
          "
          id="elut1su5ideck"
          class="animable"
        ></path>
        <path
          d="M285,222.44A12.41,12.41,0,0,1,282,225a12.62,12.62,0,0,1-3.49,1.9,19.89,19.89,0,0,1,3.2-2.33A19.15,19.15,0,0,1,285,222.44Z"
          style="fill: rgb(159, 209, 255); transform-origin: 281.755px 224.67px"
          id="ele9nvin7c3j"
          class="animable"
        ></path>
        <path
          d="M285.32,221.66c-.06,0,.16-.3.6-.18a.73.73,0,0,1,.49.6.81.81,0,0,1-1.4.66.67.67,0,0,1-.11-.79.47.47,0,0,1,.49-.23c.12,0,.16.11.14.14s-.25,0-.3.25a.31.31,0,0,0,.12.29.37.37,0,0,0,.39.06.35.35,0,0,0,.19-.33.34.34,0,0,0-.16-.31C285.56,221.67,285.35,221.73,285.32,221.66Z"
          style="
            fill: rgb(159, 209, 255);
            transform-origin: 285.618px 222.228px;
          "
          id="elwczbyefrqgg"
          class="animable"
        ></path>
        <path
          d="M285.23,221.77a52.59,52.59,0,0,1-10.12-2.14,50.26,50.26,0,0,1,10.12,2.14Z"
          style="fill: rgb(159, 209, 255); transform-origin: 280.17px 220.7px"
          id="elb9lon8sp3kr"
          class="animable"
        ></path>
        <path
          d="M253.25,234c.06.14-.8.62-1.9,1.09a4.88,4.88,0,0,1-2.11.6c-.06-.13.8-.62,1.9-1.08A4.84,4.84,0,0,1,253.25,234Z"
          style="fill: rgb(38, 50, 56); transform-origin: 251.245px 234.845px"
          id="elom8xh1ogon"
          class="animable"
        ></path>
        <path
          d="M265.08,203.08c.14.06-4.35,10.55-10,23.42s-10.38,23.27-10.51,23.22,4.36-10.55,10-23.43S265,203,265.08,203.08Z"
          style="fill: rgb(38, 50, 56); transform-origin: 254.825px 226.4px"
          id="elej111lwqos"
          class="animable"
        ></path>
        <path
          d="M286,204.7c.13.06-4.6,11.09-10.56,24.63s-10.91,24.48-11,24.42,4.59-11.08,10.56-24.63S285.83,204.64,286,204.7Z"
          style="fill: rgb(38, 50, 56); transform-origin: 275.221px 229.225px"
          id="el1e1xpsgr3x8j"
          class="animable"
        ></path>
        <path
          d="M279.35,251.56c0,.14-8.15-.76-18.16-2s-18.11-2.39-18.09-2.53,8.14.75,18.16,2S279.37,251.41,279.35,251.56Z"
          style="fill: rgb(38, 50, 56); transform-origin: 261.225px 249.295px"
          id="el71t6q3tbaqe"
          class="animable"
        ></path>
        <path
          d="M285,239.11a6.79,6.79,0,0,1-1.44-.08l-3.89-.4c-3.29-.37-7.82-.91-12.83-1.53s-9.53-1.23-12.81-1.68l-3.87-.57a8.94,8.94,0,0,1-1.42-.28,6.78,6.78,0,0,1,1.44.08l3.9.4c3.28.37,7.82.9,12.83,1.53s9.53,1.22,12.8,1.68l3.88.57A7.69,7.69,0,0,1,285,239.11Z"
          style="fill: rgb(38, 50, 56); transform-origin: 266.87px 236.84px"
          id="eldi4g24yw2va"
          class="animable"
        ></path>
        <path
          d="M298.88,209.24a7.1,7.1,0,0,1-1.44-.07l-3.89-.41c-3.29-.36-7.83-.9-12.83-1.53s-9.54-1.22-12.81-1.68L264,205a6.75,6.75,0,0,1-1.41-.28,9.06,9.06,0,0,1,1.44.08l3.89.4c3.29.37,7.83.91,12.83,1.53s9.54,1.23,12.81,1.69l3.88.56A8.94,8.94,0,0,1,298.88,209.24Z"
          style="fill: rgb(38, 50, 56); transform-origin: 280.735px 206.984px"
          id="eljmruvqdsmks"
          class="animable"
        ></path>
        <path
          d="M266.28,203.79c.08.12-.6.64-1.51,1.16s-1.7.83-1.77.71.6-.65,1.51-1.16S266.21,203.66,266.28,203.79Z"
          style="fill: rgb(38, 50, 56); transform-origin: 264.641px 204.724px"
          id="elwv2f4hjrol"
          class="animable"
        ></path>
        <path
          d="M248,246.29a6.17,6.17,0,0,1-2.23,1.24,6.6,6.6,0,0,1-2.44.76,6.34,6.34,0,0,1,2.23-1.24A6.55,6.55,0,0,1,248,246.29Z"
          style="fill: rgb(38, 50, 56); transform-origin: 245.665px 247.29px"
          id="el3qkmeimba3b"
          class="animable"
        ></path>
        <path
          d="M266.69,250.86a6.88,6.88,0,0,1,.25-1.67l.18.29c-.46,0-1-.11-1.57-.19l.28-.18v0c-.19.58-.37,1.12-.53,1.57l-.18-.29a6.39,6.39,0,0,1,1.57.45c.06.07-.57.09-1.64,0l-.25,0,.07-.27c.12-.46.28-1,.46-1.59v0l.07-.21.22,0,1.57.26.26.06-.08.23C267,250.36,266.73,250.94,266.69,250.86Z"
          style="fill: rgb(38, 50, 56); transform-origin: 266.125px 249.835px"
          id="el0mqw1juyawwo"
          class="animable"
        ></path>
        <polygon
          points="307.7 293.84 358.7 263.5 324.25 263.5 307.7 293.84"
          style="fill: rgb(38, 50, 56); transform-origin: 333.2px 278.67px"
          id="el1dxa6zqqi8y"
          class="animable"
        ></polygon>
        <polygon
          points="309.78 193.88 320.92 207.56 323.05 203.44 314.29 192.53 309.78 193.88"
          style="fill: rgb(38, 50, 56); transform-origin: 316.415px 200.045px"
          id="elutgyigt12ko"
          class="animable"
        ></polygon>
        <polygon
          points="358.7 263.5 358.7 258.12 327.52 257.46 313.48 283.37 358.7 263.5"
          style="fill: rgb(38, 50, 56); transform-origin: 336.09px 270.415px"
          id="el73mnu3uytmy"
          class="animable"
        ></polygon>
      </g>
      <g
        id="freepik--Character--inject-6"
        class="animable"
        style="transform-origin: 250.017px 317.472px"
      >
        <rect
          x="218.08"
          y="370.46"
          width="5.48"
          height="98.07"
          style="fill: rgb(69, 90, 100); transform-origin: 220.82px 419.495px"
          id="elk6ix9hkdpg"
          class="animable"
        ></rect>
        <path
          d="M264,362.71c0,7.23-18.09,13.08-40.42,13.08s-40.42-5.85-40.42-13.08c0-2.26-1-7,.44-11.55,2.49-7.57,24.65-1.52,40-1.52,16.46,0,37.21-3,39.84,4.28C264.58,357.21,264,360.82,264,362.71Z"
          style="fill: rgb(38, 50, 56); transform-origin: 223.485px 361.57px"
          id="elyv96as703y"
          class="animable"
        ></path>
        <ellipse
          cx="223.56"
          cy="353.1"
          rx="40.42"
          ry="13.08"
          style="fill: rgb(69, 90, 100); transform-origin: 223.56px 353.1px"
          id="elrjhmmv9p71"
          class="animable"
        ></ellipse>
        <path
          d="M223.46,419.21s30.63-2.1,32,40.61h-5s2.5-35.05-27.08-36.05V420"
          style="fill: rgb(69, 90, 100); transform-origin: 239.42px 439.507px"
          id="elvlm4g6nvdb"
          class="animable"
        ></path>
        <path
          d="M219.8,419.21s-30.63-2.1-32,40.61h5s-2.5-35.05,27.08-36.05V420"
          style="fill: rgb(69, 90, 100); transform-origin: 203.84px 439.507px"
          id="elfheynfdrv27"
          class="animable"
        ></path>
        <g id="elv5pahmdmvjf">
          <rect
            x="244.33"
            y="203.85"
            width="48.39"
            height="5.59"
            style="
              fill: rgb(159, 209, 255);
              transform-origin: 268.525px 206.645px;
              transform: rotate(-7.48089deg);
            "
            class="animable"
          ></rect>
        </g>
        <path
          d="M259,417.84l4.66-9.75-18.21-8.85-7,14.19,1.09.62c4.89,2.73,25.05,13.5,28.91,14.11C272.76,428.85,259,417.84,259,417.84Z"
          style="
            fill: rgb(159, 209, 255);
            transform-origin: 253.868px 413.716px;
          "
          id="elhcvc5bns25d"
          class="animable"
        ></path>
        <g id="el3v68zcqkafg">
          <g
            style="opacity: 0.3; transform-origin: 242px 412.3px"
            class="animable"
          >
            <path
              d="M245.13,416.5a7,7,0,0,0-.44-5,6.85,6.85,0,0,0-3.61-3.4l-2.48,5.09Z"
              style="fill: rgb(255, 255, 255); transform-origin: 242px 412.3px"
              id="eldixmyrxyg6"
              class="animable"
            ></path>
          </g>
        </g>
        <g id="elpwjz3ekggs">
          <path
            d="M265.58,423.49s4.66,4.2,3.55,4.76-21.9-9.55-30.67-14.82l.22-.39,24.15,12S264.5,423,265.58,423.49Z"
            style="
              fill: rgb(255, 255, 255);
              opacity: 0.3;
              transform-origin: 253.88px 420.656px;
            "
            class="animable"
          ></path>
        </g>
        <path
          d="M238.4,412.89l.3.19.87.49,3.24,1.75c2.75,1.46,6.57,3.43,10.83,5.51s8.17,3.88,11,5.15l3.38,1.47.92.39.33.11-.31-.16-.9-.43-3.34-1.54c-2.83-1.31-6.72-3.13-11-5.21s-8.09-4-10.86-5.44l-3.27-1.69-.9-.45A1.3,1.3,0,0,0,238.4,412.89Z"
          style="fill: rgb(38, 50, 56); transform-origin: 253.835px 420.42px"
          id="el37dhlw0x7h3"
          class="animable"
        ></path>
        <path
          d="M262.68,425.33a8.79,8.79,0,0,1,1.39-1.28,7.78,7.78,0,0,1,1.75-.71,2.66,2.66,0,0,0-1.88.51A2.74,2.74,0,0,0,262.68,425.33Z"
          style="fill: rgb(38, 50, 56); transform-origin: 264.25px 424.327px"
          id="elkkk9jrvp53b"
          class="animable"
        ></path>
        <path
          d="M258.64,420c0,.06.45-.13.91-.42s.83-.57.79-.63a2.17,2.17,0,0,0-.91.42C259,419.65,258.6,419.93,258.64,420Z"
          style="fill: rgb(38, 50, 56); transform-origin: 259.49px 419.481px"
          id="elkq59ojudlim"
          class="animable"
        ></path>
        <path
          d="M257.55,418.49c0,.07.4,0,.85-.11s.8-.29.78-.35-.4,0-.85.11S257.53,418.43,257.55,418.49Z"
          style="fill: rgb(38, 50, 56); transform-origin: 258.365px 418.262px"
          id="el8s1r8633rti"
          class="animable"
        ></path>
        <path
          d="M257.45,416.32c0,.06.37.32.89.59a2.37,2.37,0,0,0,1,.36c0-.06-.37-.32-.89-.58A2.31,2.31,0,0,0,257.45,416.32Z"
          style="fill: rgb(38, 50, 56); transform-origin: 258.395px 416.795px"
          id="elo6wx1h8xdcn"
          class="animable"
        ></path>
        <path
          d="M257.72,415c-.05.05.32.48.91.83a2.08,2.08,0,0,0,1.17.39,6.14,6.14,0,0,0-1.05-.6A6.52,6.52,0,0,0,257.72,415Z"
          style="fill: rgb(38, 50, 56); transform-origin: 258.758px 415.61px"
          id="elojs1aimzfqq"
          class="animable"
        ></path>
        <path
          d="M260,418.7a3.7,3.7,0,0,0,1.17.57,8.25,8.25,0,0,0,1.38.33,3.94,3.94,0,0,0,.86,0,.76.76,0,0,0,.47-.22.54.54,0,0,0,0-.56,2.39,2.39,0,0,0-3-.66,2.18,2.18,0,0,0-.76.6c-.15.17-.2.29-.19.3a3.64,3.64,0,0,1,1-.74,2.33,2.33,0,0,1,1.32-.17,2,2,0,0,1,1.34.78c.12.23-.06.41-.32.43a3.58,3.58,0,0,1-.8,0,8.46,8.46,0,0,1-1.35-.27C260.5,418.88,260.05,418.67,260,418.7Z"
          style="fill: rgb(38, 50, 56); transform-origin: 261.944px 418.756px"
          id="elnpk90c51jx"
          class="animable"
        ></path>
        <path
          d="M260.31,418.91s.32-.19.53-.75a2.16,2.16,0,0,0,.09-1,1.3,1.3,0,0,0-.68-1.07.51.51,0,0,0-.62.37,1.34,1.34,0,0,0-.06.62,3.67,3.67,0,0,0,.21,1c.19.54.41.82.44.8s-.13-.32-.28-.85a4.06,4.06,0,0,1-.14-.94c0-.32.06-.82.38-.73s.46.55.53.87a2.14,2.14,0,0,1,0,.92A5.58,5.58,0,0,1,260.31,418.91Z"
          style="fill: rgb(38, 50, 56); transform-origin: 260.255px 417.492px"
          id="el09soxd271k2e"
          class="animable"
        ></path>
        <path
          d="M241.2,408.09s.55.2,1.32.7a6.58,6.58,0,0,1,2.35,2.64,6.51,6.51,0,0,1,.57,3.48c-.1.92-.33,1.46-.29,1.47s.09-.12.18-.37a4.74,4.74,0,0,0,.28-1.07,6.14,6.14,0,0,0-3-6.3,5.75,5.75,0,0,0-1-.46C241.35,408.1,241.2,408.07,241.2,408.09Z"
          style="fill: rgb(38, 50, 56); transform-origin: 243.444px 412.232px"
          id="ely6vphf7hz6"
          class="animable"
        ></path>
        <path
          d="M246.78,401.43a33,33,0,0,0-2,3.66,34.23,34.23,0,0,0-1.76,3.78,37,37,0,0,0,2-3.67A36,36,0,0,0,246.78,401.43Z"
          style="fill: rgb(38, 50, 56); transform-origin: 244.9px 405.15px"
          id="elfw5b0z2555m"
          class="animable"
        ></path>
        <path
          d="M248.28,415.84a16.47,16.47,0,0,0,5.3,2.64c0-.07-1.2-.57-2.66-1.3S248.32,415.78,248.28,415.84Z"
          style="fill: rgb(38, 50, 56); transform-origin: 250.93px 417.158px"
          id="el5wsfz0nnxi2"
          class="animable"
        ></path>
        <path
          d="M244.72,414.1c-.07,0,0,.3,0,.63s-.06.6,0,.63.24-.24.25-.62S244.78,414.07,244.72,414.1Z"
          style="fill: rgb(38, 50, 56); transform-origin: 244.83px 414.73px"
          id="elsrqivu2zghs"
          class="animable"
        ></path>
        <path
          d="M244.19,411.77c-.07,0,0,.27.05.55s.23.49.29.47,0-.27-.05-.55S244.25,411.75,244.19,411.77Z"
          style="fill: rgb(38, 50, 56); transform-origin: 244.358px 412.28px"
          id="el0cdrvn0zz3x"
          class="animable"
        ></path>
        <path
          d="M242.64,410.07c0,.07.22.18.44.41s.35.46.42.44,0-.35-.24-.62S242.65,410,242.64,410.07Z"
          style="fill: rgb(38, 50, 56); transform-origin: 243.083px 410.479px"
          id="el448en4ntmne"
          class="animable"
        ></path>
        <path
          d="M241.44,409c-.05,0,0,.19.13.32s.27.2.32.16,0-.19-.14-.32S241.49,409,241.44,409Z"
          style="fill: rgb(38, 50, 56); transform-origin: 241.665px 409.246px"
          id="el9usxhv0w6gm"
          class="animable"
        ></path>
        <path
          d="M259.51,428l5.86-9.09-17-11.08-8.71,13.2,1,.76c4.5,3.32,23.14,16.55,26.89,17.64C271.79,440.69,259.51,428,259.51,428Z"
          style="fill: rgb(159, 209, 255); transform-origin: 254.05px 423.674px"
          id="elsgnuowliira"
          class="animable"
        ></path>
        <g id="elss884vcd7d">
          <g
            style="opacity: 0.3; transform-origin: 243.204px 420.55px"
            class="animable"
          >
            <path
              d="M245.94,425a7,7,0,0,0,.2-5A6.83,6.83,0,0,0,243,416.1l-3.1,4.73Z"
              style="
                fill: rgb(255, 255, 255);
                transform-origin: 243.204px 420.55px;
              "
              id="elt8of34nk4i"
              class="animable"
            ></path>
          </g>
        </g>
        <g id="elr064efw3xw">
          <path
            d="M265.35,434.46s4.09,4.76,2.92,5.18-20.52-12.24-28.56-18.58l.27-.36,22.44,15S264.33,433.86,265.35,434.46Z"
            style="
              fill: rgb(255, 255, 255);
              opacity: 0.3;
              transform-origin: 254.095px 430.175px;
            "
            class="animable"
          ></path>
        </g>
        <path
          d="M239.72,420.52l.27.22.81.6,3,2.15c2.54,1.79,6.08,4.23,10.05,6.83s7.62,4.88,10.27,6.49l3.17,1.89.87.5a1.6,1.6,0,0,0,.31.16,1.88,1.88,0,0,0-.29-.2l-.84-.54-3.12-1.95c-2.64-1.65-6.27-3.95-10.24-6.55s-7.52-5-10.08-6.78l-3-2.08-.83-.56A1.15,1.15,0,0,0,239.72,420.52Z"
          style="fill: rgb(38, 50, 56); transform-origin: 254.095px 429.94px"
          id="elnc076xvouif"
          class="animable"
        ></path>
        <path
          d="M262.24,435.93a8,8,0,0,1,1.54-1.1,8.84,8.84,0,0,1,1.82-.49,2.76,2.76,0,0,0-1.93.27A2.7,2.7,0,0,0,262.24,435.93Z"
          style="fill: rgb(38, 50, 56); transform-origin: 263.92px 435.101px"
          id="ell6zivaxdi1m"
          class="animable"
        ></path>
        <path
          d="M258.9,430.12a2.27,2.27,0,0,0,1-.3c.51-.23.89-.47.86-.53a2.27,2.27,0,0,0-1,.3C259.26,429.82,258.88,430.06,258.9,430.12Z"
          style="fill: rgb(38, 50, 56); transform-origin: 259.83px 429.705px"
          id="elhd0u2jfq2om"
          class="animable"
        ></path>
        <path
          d="M258,428.49c0,.07.39.07.85,0s.84-.19.83-.25-.4-.07-.86,0S258,428.43,258,428.49Z"
          style="fill: rgb(38, 50, 56); transform-origin: 258.84px 428.367px"
          id="elsjcb4ill9c"
          class="animable"
        ></path>
        <path
          d="M258.19,426.33c0,.05.32.36.81.69a2.64,2.64,0,0,0,.94.49c0-.06-.32-.37-.81-.7S258.22,426.27,258.19,426.33Z"
          style="fill: rgb(38, 50, 56); transform-origin: 259.065px 426.915px"
          id="elwrjt92pbbkp"
          class="animable"
        ></path>
        <path
          d="M258.63,425c-.06,0,.25.52.8.94a2,2,0,0,0,1.1.53c0-.07-.44-.33-.95-.73A8.1,8.1,0,0,0,258.63,425Z"
          style="fill: rgb(38, 50, 56); transform-origin: 259.576px 425.735px"
          id="elk2z24jx09dl"
          class="animable"
        ></path>
        <path
          d="M260.45,429a3.68,3.68,0,0,0,1.09.7,7.16,7.16,0,0,0,1.33.5,3.47,3.47,0,0,0,.84.17.75.75,0,0,0,.49-.17.52.52,0,0,0,.12-.54,2.38,2.38,0,0,0-2.85-1,2.2,2.2,0,0,0-.83.49c-.17.16-.24.27-.22.28a3.74,3.74,0,0,1,1.1-.61,2.4,2.4,0,0,1,1.33,0,2,2,0,0,1,1.23.94c.09.24-.11.4-.37.39a5.22,5.22,0,0,1-.79-.14,8.64,8.64,0,0,1-1.31-.45C260.88,429.25,260.47,429,260.45,429Z"
          style="fill: rgb(38, 50, 56); transform-origin: 262.383px 429.443px"
          id="el91ap4jh26l"
          class="animable"
        ></path>
        <path
          d="M260.69,429.25s.35-.15.62-.67a2.22,2.22,0,0,0,.23-1,1.33,1.33,0,0,0-.55-1.15.52.52,0,0,0-.66.29,1.23,1.23,0,0,0-.13.6,3.82,3.82,0,0,0,.08,1,2,2,0,0,0,.33.85s-.09-.34-.16-.88a4.15,4.15,0,0,1,0-.95c0-.33.17-.81.48-.68s.39.6.41.93a2.12,2.12,0,0,1-.15.91A4.15,4.15,0,0,1,260.69,429.25Z"
          style="fill: rgb(38, 50, 56); transform-origin: 260.868px 427.824px"
          id="el6aunddwyhal"
          class="animable"
        ></path>
        <path
          d="M243.1,416.11s.52.27,1.23.86a6.59,6.59,0,0,1,2,2.92,6.49,6.49,0,0,1,.13,3.52c-.22.9-.51,1.4-.47,1.42a1.11,1.11,0,0,0,.22-.34,5.49,5.49,0,0,0,.42-1,6.24,6.24,0,0,0-.07-3.65,6.17,6.17,0,0,0-2.11-3,5.18,5.18,0,0,0-.95-.59A1.28,1.28,0,0,0,243.1,416.11Z"
          style="fill: rgb(38, 50, 56); transform-origin: 244.985px 420.47px"
          id="eluk6n42gchhg"
          class="animable"
        ></path>
        <path
          d="M249.48,410.21a33.84,33.84,0,0,0-2.43,3.38,36.63,36.63,0,0,0-2.22,3.52,33.36,33.36,0,0,0,2.42-3.38A36.61,36.61,0,0,0,249.48,410.21Z"
          style="fill: rgb(38, 50, 56); transform-origin: 247.155px 413.66px"
          id="elvitmmbhgrq"
          class="animable"
        ></path>
        <path
          d="M249.15,424.69a11.88,11.88,0,0,0,2.32,1.87,11.4,11.4,0,0,0,2.6,1.42c0-.06-1.11-.72-2.47-1.63S249.2,424.64,249.15,424.69Z"
          style="fill: rgb(38, 50, 56); transform-origin: 251.61px 426.334px"
          id="elyan5gyn8s1n"
          class="animable"
        ></path>
        <path
          d="M245.84,422.51c-.07,0-.07.3-.12.63s-.14.59-.08.62.27-.21.32-.59S245.9,422.5,245.84,422.51Z"
          style="fill: rgb(38, 50, 56); transform-origin: 245.796px 423.136px"
          id="el36xkejwpoxw"
          class="animable"
        ></path>
        <path
          d="M245.6,420.14c-.06,0-.07.26,0,.55s.16.52.23.51.07-.27,0-.56S245.67,420.12,245.6,420.14Z"
          style="fill: rgb(38, 50, 56); transform-origin: 245.717px 420.67px"
          id="el9a7luuj862t"
          class="animable"
        ></path>
        <path
          d="M244.28,418.25c0,.07.2.21.39.46s.29.51.36.5.08-.34-.16-.65S244.3,418.19,244.28,418.25Z"
          style="fill: rgb(38, 50, 56); transform-origin: 244.677px 418.72px"
          id="elxdq1qla6i18"
          class="animable"
        ></path>
        <path
          d="M243.23,417.08c-.06,0,0,.19.09.34s.23.23.29.19,0-.19-.09-.33S243.28,417,243.23,417.08Z"
          style="fill: rgb(38, 50, 56); transform-origin: 243.42px 417.338px"
          id="elrfvy3vkugx"
          class="animable"
        ></path>
        <g id="elyqbytd63b2">
          <polygon
            points="243.89 414.73 260.98 425.74 262.02 424.13 244.93 412.79 243.89 414.73"
            style="opacity: 0.2; transform-origin: 252.955px 419.265px"
            class="animable"
          ></polygon>
        </g>
        <polygon
          points="261.42 339.51 241.43 405.19 259.16 412.07 284.83 353.1 261.42 339.51"
          style="fill: rgb(38, 50, 56); transform-origin: 263.13px 375.79px"
          id="el7b9lmj2y9qu"
          class="animable"
        ></polygon>
        <path
          d="M219.92,355.69s69.38-17.28,71.27-18.12c0,0,7.74-22.41-8.49-30.89-15.45-8.08-66.79,19.25-66.79,19.25Z"
          style="fill: rgb(38, 50, 56); transform-origin: 254.472px 330.438px"
          id="el4wot1ob03i4"
          class="animable"
        ></path>
        <path
          d="M197.9,351.23c7.92,1.21,16.84,4,22,4.46,14,1.28,20.67-4.45,32.31-1.33.06-8.5-.57-24.25-.57-24.25l-64.77-8.59S188.07,344.09,197.9,351.23Z"
          style="fill: rgb(38, 50, 56); transform-origin: 219.542px 338.7px"
          id="elofrmbrpguua"
          class="animable"
        ></path>
        <path
          d="M315.86,350.15h0c1.87-3.48,3.26-7.62,2.48-11.57-1.55-7.85-10.95-8.71-20.76-9.89-22.05-2.66-50.67-6.43-50.67-6.43l5.36,32.11,26.11,1.89-35.69,55.07,25.45,17.07,46.38-76c.28-.44.56-.91.84-1.38Z"
          style="fill: rgb(38, 50, 56); transform-origin: 280.626px 375.33px"
          id="elfl6uhfhyxw8"
          class="animable"
        ></path>
        <path
          d="M295.54,328.44a13.22,13.22,0,0,1-1.78-.06c-1.15-.07-2.81-.2-4.85-.4-4.09-.38-9.73-1.06-15.94-1.88s-11.84-1.55-15.92-2l-4.83-.56a10.23,10.23,0,0,1-1.76-.26,13.22,13.22,0,0,1,1.78.06c1.15.07,2.81.2,4.85.4,4.09.38,9.73,1.06,15.94,1.88s11.84,1.55,15.92,2l4.83.56A10.23,10.23,0,0,1,295.54,328.44Z"
          style="fill: rgb(69, 90, 100); transform-origin: 273px 325.86px"
          id="ell2pnhzcoqd"
          class="animable"
        ></path>
        <path
          d="M279.24,325.34c.07.13-.14.39-.42.66s-.56.47-.68.39,0-.45.32-.77S279.17,325.22,279.24,325.34Z"
          style="fill: rgb(69, 90, 100); transform-origin: 278.674px 325.847px"
          id="el3g4clwv46rf"
          class="animable"
        ></path>
        <path
          d="M279.56,353.37a2.7,2.7,0,0,1-.33.57l-1.05,1.59-3.9,5.78c-3.29,4.89-7.79,11.67-12.62,19.25s-9.1,14.51-12.15,19.55c-1.5,2.48-2.72,4.5-3.6,6l-1,1.61a2.65,2.65,0,0,1-.38.55,4.76,4.76,0,0,1,.29-.6c.23-.43.54-1,.91-1.66.8-1.44,2-3.51,3.47-6.06,3-5.09,7.18-12.06,12-19.65s9.39-14.33,12.76-19.16c1.68-2.42,3.06-4.37,4-5.7l1.13-1.53A3.77,3.77,0,0,1,279.56,353.37Z"
          style="fill: rgb(69, 90, 100); transform-origin: 262.045px 380.82px"
          id="eldkv8dl3mjs"
          class="animable"
        ></path>
        <path
          d="M277.58,356.2a24.42,24.42,0,0,1-3.48,0c-2.15-.07-5.11-.17-8.39-.36s-6.24-.48-8.37-.76c-1.07-.14-1.93-.27-2.52-.38a3.25,3.25,0,0,1-.92-.22,26.88,26.88,0,0,1,3.48.23c2.14.19,5.09.42,8.36.61s6.24.35,8.38.51A22.84,22.84,0,0,1,277.58,356.2Z"
          style="fill: rgb(69, 90, 100); transform-origin: 265.74px 355.371px"
          id="el58rglcdu2vw"
          class="animable"
        ></path>
        <path
          d="M297.41,229s1.16-1.54,2.49-.55,7.19,5.73,7.19,5.73l4,10.84-5.69,10.39-9.82-4.21a46.12,46.12,0,0,1,2.14-7.12s-6.59-5.1-4.54-7.11c0,0,.54-1,3.34,1.32l2.79,2.32s-4.9-6.84-4.59-8.4c0,0,0,0,0,0a1.92,1.92,0,0,1,2.42-1.7h0Z"
          style="
            fill: rgb(255, 190, 157);
            transform-origin: 301.937px 241.764px;
          "
          id="el7a6sc9i8eyp"
          class="animable"
        ></path>
        <path
          d="M291.24,231.68l4.05,1,17.6,15.55a1.54,1.54,0,0,1,.15,2.17h0a1.54,1.54,0,0,1-2.19.17l-17.54-15.13Z"
          style="
            fill: rgb(159, 209, 255);
            transform-origin: 302.329px 241.314px;
          "
          id="el5heizkrfvor"
          class="animable"
        ></path>
        <g id="els5ewxt87sl">
          <path
            d="M310.54,250.29l1.94-2.44.66.65a1.54,1.54,0,0,1,.09,2.07h0a1.53,1.53,0,0,1-2.21.15Z"
            style="
              fill: rgb(255, 255, 255);
              opacity: 0.4;
              transform-origin: 312.063px 249.486px;
            "
            class="animable"
          ></path>
        </g>
        <g id="elmfu0ogkovpo">
          <path
            d="M291.91,232.8l.68-.8,2.76.71a.93.93,0,0,1,0,.93c-.27.35-.79.06-.79.06a1.1,1.1,0,0,1,0,1c-.25.36-.87.21-.87.21a.51.51,0,0,1-.07.78l-.25-.22Z"
            style="
              fill: rgb(255, 255, 255);
              opacity: 0.4;
              transform-origin: 293.692px 233.845px;
            "
            class="animable"
          ></path>
        </g>
        <g id="elz8muxntakh">
          <path
            d="M294.51,233.7,312,248.37l-.92,1.15-17.45-14.61a.84.84,0,0,0,.89-.29A.92.92,0,0,0,294.51,233.7Z"
            style="opacity: 0.4; transform-origin: 302.815px 241.61px"
            class="animable"
          ></path>
        </g>
        <path
          d="M296.2,248.21a39.2,39.2,0,0,0,1.87-4.87c.22-1.21-1.89-9.08,1.26-9.7l1,.47L303,246.77l-5.23,5Z"
          style="fill: rgb(255, 190, 157); transform-origin: 299.6px 242.705px"
          id="elhky57txjqe"
          class="animable"
        ></path>
        <path
          d="M303.64,239.14c-.09,0-.26-.73-.81-1.83a10.18,10.18,0,0,0-2.92-3.67,11.22,11.22,0,0,1-2.11-1.72,2.86,2.86,0,0,1-.78-2.15,1.9,1.9,0,0,1,.78-1.34c.32-.21.54-.19.54-.17s-.18.1-.41.33a1.78,1.78,0,0,0-.54,1.21,2.64,2.64,0,0,0,.77,1.81,13.55,13.55,0,0,0,2.06,1.61,9.4,9.4,0,0,1,2.95,3.94A4.66,4.66,0,0,1,303.64,239.14Z"
          style="
            fill: rgb(235, 153, 110);
            transform-origin: 300.327px 233.694px;
          "
          id="el5oqd00ievte"
          class="animable"
        ></path>
        <path
          d="M302.37,244.81a5.8,5.8,0,0,1-.26-1c-.14-.65-.42-1.56-.73-2.71a15.71,15.71,0,0,1-.38-1.93c-.09-.7-.13-1.45-.25-2.21a7.39,7.39,0,0,0-.26-1.13,7.48,7.48,0,0,0-.44-1.09.34.34,0,0,0-.33-.17,1.79,1.79,0,0,0-.52.18,2.1,2.1,0,0,0-.83.84,4.7,4.7,0,0,0-.56,2.41,11.66,11.66,0,0,0,.3,2.39,9.37,9.37,0,0,1,.25,2.26,5.07,5.07,0,0,1-.52,1.94,7.57,7.57,0,0,0-.92,2.59,6.36,6.36,0,0,0,.05,1,1.51,1.51,0,0,1-.25-1,7,7,0,0,1,.78-2.73,5,5,0,0,0,.43-1.8,9.45,9.45,0,0,0-.29-2.15,11.43,11.43,0,0,1-.34-2.49,5,5,0,0,1,.63-2.68,3.48,3.48,0,0,1,.43-.58,1.79,1.79,0,0,1,.61-.45,1.91,1.91,0,0,1,.72-.23.84.84,0,0,1,.77.38,5.85,5.85,0,0,1,.5,1.24,6.91,6.91,0,0,1,.26,1.21c.11.8.14,1.55.21,2.23a15.54,15.54,0,0,0,.31,1.89c.26,1.15.49,2.1.57,2.76A3.42,3.42,0,0,1,302.37,244.81Z"
          style="
            fill: rgb(235, 153, 110);
            transform-origin: 299.548px 241.124px;
          "
          id="elc7sw9wb3sz4"
          class="animable"
        ></path>
        <polygon
          points="204.94 235.28 231.92 209.16 245.7 219.5 217.57 252.98 204.94 235.28"
          style="fill: rgb(159, 209, 255); transform-origin: 225.32px 231.07px"
          id="elciytmf30y2n"
          class="animable"
        ></polygon>
        <g id="elw91iietr87g">
          <polygon
            points="204.94 235.28 231.92 209.16 245.7 219.5 217.57 252.98 204.94 235.28"
            style="opacity: 0.4; transform-origin: 225.32px 231.07px"
            class="animable"
          ></polygon>
        </g>
        <path
          d="M248.19,206.48l12.22-1.61c-.89-.75-3.8-2.72-11-4-9.1-1.58-16.8,8.76-16.8,8.76l11.18,8.46,4.17-4.16s6.46-.94,10-1.75a3.12,3.12,0,0,0,2-1.62l-.18,0C254.51,210.19,249.81,208.78,248.19,206.48Z"
          style="fill: rgb(255, 190, 157); transform-origin: 246.51px 209.398px"
          id="elf49m50ev2o7"
          class="animable"
        ></path>
        <path
          d="M253.11,209.46a8.62,8.62,0,0,1-5.74-4.38c.12-.09,1.12,1.21,2.7,2.4S253.16,209.32,253.11,209.46Z"
          style="
            fill: rgb(235, 153, 110);
            transform-origin: 250.241px 207.268px;
          "
          id="elfq3m26r8p9"
          class="animable"
        ></path>
        <path
          d="M204.06,179.93l-.15,47.71,20.47,1L225,217s7.28,1.45,9.15-5.31c1.2-4.31.9-37.65.9-37.65Z"
          style="fill: rgb(255, 190, 157); transform-origin: 219.497px 201.34px"
          id="elise6t0h49e"
          class="animable"
        ></path>
        <path
          d="M233.85,166.66c-6.23,2-7.73,2.69-10.53,2.91s-21.75.23-23.48,10.92l-.13,1.31v-.05c0,.05-4.09-1.65-3,5.75.56,3.86,2.14,7.42,3.61,11.66,1,6.28-2.57,7.85-5.52,9.92-.73.52-1.61,1.16-1.49,2s1,1.22,1.81,1.35a6.74,6.74,0,0,0,4.93-1.14,28.46,28.46,0,0,1-1.4,2.68c-.4.93-.36,2.22.53,2.7a2.28,2.28,0,0,0,1.63.08,8.31,8.31,0,0,0,4.29-2.57,1.82,1.82,0,0,0,.69,2.32,4.32,4.32,0,0,0,2.56.55c4-.06,8.93,0,12.66-4.14,3.45-3.84,4.27-6.3,4.64-11.61,0,0-4.07-.67-3.6-3.94.66-4.52,4.12-4.64,6.69-2.62l3-7.95s7.51-4.93,7.94-8.07S240.07,164.64,233.85,166.66Z"
          style="fill: rgb(38, 50, 56); transform-origin: 216.567px 191.741px"
          id="el4d7el0eni2c"
          class="animable"
        ></path>
        <path
          d="M258.6,288c-6.16-7.54-11.87-14.3-11.87-14.3.26,9.86,1.28,18.4,1.28,18.4l2.93,30.51a9,9,0,0,1-6.83,9.56c-19.13,4.62-41.52,5.93-54.06-2.68a12.14,12.14,0,0,1-5.2-10v-26l-3.29-34.94a56.35,56.35,0,0,1,1.49-16.38,22.77,22.77,0,0,1,20.54-16.23c6.92-.39,14.67-1,21.61-1.09a36.51,36.51,0,0,1,22.66,7.41c10.22,7.76,17.94,25.58,25.16,36.93l19.77-23.85,16.35,10.44-22.34,33A18.57,18.57,0,0,1,258.6,288Z"
          style="
            fill: rgb(159, 209, 255);
            transform-origin: 245.306px 280.325px;
          "
          id="elpjcd9miaffk"
          class="animable"
        ></path>
        <path
          d="M196.37,248.88a21.43,21.43,0,0,1,1.47-4.55,22.56,22.56,0,0,1,2-4.36,22.91,22.91,0,0,1-1.47,4.55A22.56,22.56,0,0,1,196.37,248.88Z"
          style="fill: rgb(38, 50, 56); transform-origin: 198.105px 244.425px"
          id="el8elj12ezgmq"
          class="animable"
        ></path>
        <path
          d="M219.12,260.28a26.34,26.34,0,0,1-4.57-5.8,26.34,26.34,0,0,1,4.57,5.8Z"
          style="fill: rgb(38, 50, 56); transform-origin: 216.835px 257.38px"
          id="eltzlncs9cypj"
          class="animable"
        ></path>
        <path
          d="M232.43,241.67a19.63,19.63,0,0,1-6,1.93,9.92,9.92,0,0,1,2.94-1.22A9.72,9.72,0,0,1,232.43,241.67Z"
          style="fill: rgb(38, 50, 56); transform-origin: 229.43px 242.635px"
          id="elokwkqo53owe"
          class="animable"
        ></path>
        <path
          d="M198.66,273.32a13.62,13.62,0,0,1,1.36-3.6,14.55,14.55,0,0,1,1.83-3.38,14.53,14.53,0,0,1-1.36,3.6A14.29,14.29,0,0,1,198.66,273.32Z"
          style="fill: rgb(38, 50, 56); transform-origin: 200.255px 269.83px"
          id="elbux9on5n6f"
          class="animable"
        ></path>
        <path
          d="M197.38,292.89a16.32,16.32,0,0,1-2.52-3.23,15.81,15.81,0,0,1-2.09-3.52,16.82,16.82,0,0,1,2.52,3.23A16,16,0,0,1,197.38,292.89Z"
          style="fill: rgb(38, 50, 56); transform-origin: 195.075px 289.515px"
          id="elyzzxdka68uk"
          class="animable"
        ></path>
        <path
          d="M228.1,285.67a23.36,23.36,0,0,1-4.4,2,22.74,22.74,0,0,1-4.58,1.51,21.37,21.37,0,0,1,4.39-2A22,22,0,0,1,228.1,285.67Z"
          style="fill: rgb(38, 50, 56); transform-origin: 223.61px 287.425px"
          id="el5lqqegqrkdf"
          class="animable"
        ></path>
        <path
          d="M210.45,281.51a9.71,9.71,0,0,1,0-6.27,18.34,18.34,0,0,1,0,3.14A18.24,18.24,0,0,1,210.45,281.51Z"
          style="fill: rgb(38, 50, 56); transform-origin: 210.224px 278.375px"
          id="el5tauqfurw5j"
          class="animable"
        ></path>
        <path
          d="M237.29,274.74a18,18,0,0,1-2-4.9,17.82,17.82,0,0,1-1-5.21c.14,0,.67,2.28,1.51,5.06A51.76,51.76,0,0,1,237.29,274.74Z"
          style="fill: rgb(38, 50, 56); transform-origin: 235.79px 269.685px"
          id="elw8hxptpwgec"
          class="animable"
        ></path>
        <path
          d="M256.09,257.85a31.57,31.57,0,0,1-3.61-.83,29.38,29.38,0,0,1-3.62-.75,8.64,8.64,0,0,1,3.73.25A8.77,8.77,0,0,1,256.09,257.85Z"
          style="fill: rgb(38, 50, 56); transform-origin: 252.475px 257.011px"
          id="ell5r1cd63g"
          class="animable"
        ></path>
        <path
          d="M264.81,271a15.07,15.07,0,0,1-3.68,1.42,14.89,14.89,0,0,1-3.83.93,29.26,29.26,0,0,1,7.51-2.35Z"
          style="fill: rgb(38, 50, 56); transform-origin: 261.055px 272.175px"
          id="elzut1ipd68i"
          class="animable"
        ></path>
        <path
          d="M273,287.31a15.22,15.22,0,0,1,.65-8.95c.14,0-.31,2-.48,4.46S273.17,287.29,273,287.31Z"
          style="fill: rgb(38, 50, 56); transform-origin: 273.144px 282.835px"
          id="el0ky9b6ct4rj"
          class="animable"
        ></path>
        <path
          d="M288.17,274.3a35.77,35.77,0,0,1-2.81-2.27c-1.57-1.21-3-2-2.93-2.14a8,8,0,0,1,3.24,1.73A7.82,7.82,0,0,1,288.17,274.3Z"
          style="fill: rgb(38, 50, 56); transform-origin: 285.299px 272.095px"
          id="elqdr6xo7hwe"
          class="animable"
        ></path>
        <path
          d="M297.88,257.86a36.68,36.68,0,0,1-7.72,4.35,19.38,19.38,0,0,1,3.73-2.4A19.78,19.78,0,0,1,297.88,257.86Z"
          style="fill: rgb(38, 50, 56); transform-origin: 294.02px 260.035px"
          id="elyjzofnmlsc"
          class="animable"
        ></path>
        <path
          d="M237.25,307.35a19.81,19.81,0,0,1,1.17-4.44,20,20,0,0,1,1.66-4.27,19.81,19.81,0,0,1-1.17,4.44A20.67,20.67,0,0,1,237.25,307.35Z"
          style="fill: rgb(38, 50, 56); transform-origin: 238.665px 302.995px"
          id="elhv9iwzuxoio"
          class="animable"
        ></path>
        <path
          d="M213.09,311.54a20.13,20.13,0,0,1-2.8-3.48c-.95-1.34-1.74-2.54-2.12-3.2l-.49-.86.68.68c.29.29.43.46.4.5s-.23-.07-.57-.3l.19-.17c.51.57,1.38,1.71,2.33,3A18.87,18.87,0,0,1,213.09,311.54Z"
          style="fill: rgb(38, 50, 56); transform-origin: 210.385px 307.77px"
          id="elce7st8gm01j"
          class="animable"
        ></path>
        <path
          d="M225.26,310.24a24.1,24.1,0,0,1,1.11,4.78,23.71,23.71,0,0,1,.6,4.88,23.11,23.11,0,0,1-1.12-4.79A22.43,22.43,0,0,1,225.26,310.24Z"
          style="fill: rgb(38, 50, 56); transform-origin: 226.115px 315.07px"
          id="el3yhwqlftca9"
          class="animable"
        ></path>
        <path
          d="M199.36,320.13a28.33,28.33,0,0,1-5.31-5.55,14.37,14.37,0,0,1,2.85,2.6A14.18,14.18,0,0,1,199.36,320.13Z"
          style="fill: rgb(38, 50, 56); transform-origin: 196.705px 317.355px"
          id="elvkz5a1qqtee"
          class="animable"
        ></path>
        <path
          d="M219.63,330.15a23.76,23.76,0,0,1-7,0,23.76,23.76,0,0,1,7,0Z"
          style="fill: rgb(38, 50, 56); transform-origin: 216.13px 330.15px"
          id="elr5bwfudofc"
          class="animable"
        ></path>
        <path
          d="M244,319.6a17.91,17.91,0,0,1-3.14,2.93,17.56,17.56,0,0,1-3.47,2.52,18.36,18.36,0,0,1,3.14-2.93A17.89,17.89,0,0,1,244,319.6Z"
          style="fill: rgb(38, 50, 56); transform-origin: 240.695px 322.325px"
          id="el1zbavmw4754h"
          class="animable"
        ></path>
      </g>
      <g
        id="freepik--Plant--inject-6"
        class="animable"
        style="transform-origin: 116.862px 385.63px"
      >
        <path
          d="M110.11,384.44A10.94,10.94,0,0,0,108,373.7,14.47,14.47,0,0,0,97.84,369c-1.77-.1-3.73.15-4.92,1.46a4.68,4.68,0,0,0-.7,4.68,9.48,9.48,0,0,0,3,3.87c4.37,3.63,9.27,6.34,14.88,5.44"
          style="
            fill: rgb(159, 209, 255);
            transform-origin: 101.292px 376.802px;
          "
          id="el55jtlwzko43"
          class="animable"
        ></path>
        <path
          d="M119.1,389.71A25.51,25.51,0,0,1,135.33,373c1.95-.64,4.43-.9,5.76.66s.68,4-.31,5.79a21.79,21.79,0,0,1-21.62,10.83"
          style="fill: rgb(159, 209, 255); transform-origin: 130.47px 381.472px"
          id="elwpgf2m5tudr"
          class="animable"
        ></path>
        <g id="elr5smoj127k">
          <path
            d="M119.1,389.71A25.51,25.51,0,0,1,135.33,373c1.95-.64,4.43-.9,5.76.66s.68,4-.31,5.79a21.79,21.79,0,0,1-21.62,10.83"
            style="
              fill: rgb(255, 255, 255);
              opacity: 0.3;
              transform-origin: 130.47px 381.472px;
            "
            class="animable"
          ></path>
        </g>
        <path
          d="M121.71,338.51a12.77,12.77,0,0,0-7.55,6.56,22.59,22.59,0,0,0-2.22,10c-.13,6.63,2.7,13.72,6,19.48,6.15-4.07,8.53-12.28,9.48-16.52,1.5-6.7.14-19.73-5.71-19.48"
          style="fill: rgb(159, 209, 255); transform-origin: 119.96px 356.53px"
          id="el62djepgphmi"
          class="animable"
        ></path>
        <path
          d="M114.77,404.05a28.3,28.3,0,0,0,.13-3.33,46.91,46.91,0,0,0-2.58-12.26,23.41,23.41,0,0,0-7.24-10.19,15.4,15.4,0,0,0-3.28-2,4.39,4.39,0,0,0-1.33-.44,33.35,33.35,0,0,1,4.28,2.82,24.63,24.63,0,0,1,6.88,10.05,54.21,54.21,0,0,1,2.75,12"
          style="fill: rgb(38, 50, 56); transform-origin: 107.688px 389.94px"
          id="el250v60td9na"
          class="animable"
        ></path>
        <path
          d="M114.75,404.16c.19-1.6.43-3.69.72-6.21.59-5.25,1.43-12.5,2.46-20.5S120,362.23,120.75,357l.89-6.19a13.22,13.22,0,0,0,.23-2.29,14.08,14.08,0,0,0-.52,2.24c-.29,1.45-.67,3.56-1.12,6.16-.89,5.21-2,12.43-3,20.44s-1.78,15.27-2.24,20.53c-.23,2.64-.4,4.77-.49,6.25"
          style="fill: rgb(38, 50, 56); transform-origin: 118.185px 376.34px"
          id="elv84dcstye8m"
          class="animable"
        ></path>
        <path
          d="M133.65,379.1a4.57,4.57,0,0,0-1.29.33,10.54,10.54,0,0,0-1.47.54,19,19,0,0,0-1.88.88,27.52,27.52,0,0,0-4.53,3,28.83,28.83,0,0,0-8,10.45,26.36,26.36,0,0,0-1.71,5.14,15.36,15.36,0,0,0-.37,2,11.24,11.24,0,0,0-.15,1.56c0,.86.19,1.11.24,1.12a45.21,45.21,0,0,1,.79-4.6,29.88,29.88,0,0,1,1.82-5,34.26,34.26,0,0,1,3.38-5.53,32.94,32.94,0,0,1,4.47-4.68,30.45,30.45,0,0,1,4.34-3.05C131.89,379.81,133.7,379.24,133.65,379.1Z"
          style="fill: rgb(38, 50, 56); transform-origin: 123.95px 391.61px"
          id="el5kvcespd1yg"
          class="animable"
        ></path>
        <g id="elirma9ooxnfj">
          <path
            d="M110.11,384.44A10.94,10.94,0,0,0,108,373.7,14.47,14.47,0,0,0,97.84,369c-1.77-.1-3.73.15-4.92,1.46a4.68,4.68,0,0,0-.7,4.68,9.48,9.48,0,0,0,3,3.87c4.37,3.63,9.27,6.34,14.88,5.44"
            style="opacity: 0.2; transform-origin: 101.292px 376.802px"
            class="animable"
          ></path>
        </g>
        <path
          d="M97.34,402.83h34.25v12.8a17.13,17.13,0,0,1-17.13,17.12h0a17.13,17.13,0,0,1-17.12-17.12Z"
          style="fill: rgb(69, 90, 100); transform-origin: 114.465px 417.79px"
          id="el688vhykqmra"
          class="animable"
        ></path>
        <path
          d="M131.56,411.07a6.34,6.34,0,0,1-1.34,0l-3.65-.11c-3.08-.1-7.33-.23-12-.31s-9-.09-12-.09l-3.65,0a6.2,6.2,0,0,1-1.34-.07,7.19,7.19,0,0,1,1.33-.13c.86-.05,2.11-.11,3.65-.15,3.09-.09,7.35-.14,12.06-.06s9,.27,12,.47c1.54.09,2.78.19,3.64.27A6.13,6.13,0,0,1,131.56,411.07Z"
          style="fill: rgb(38, 50, 56); transform-origin: 114.57px 410.608px"
          id="elgvrgci3teck"
          class="animable"
        ></path>
        <path
          d="M131.55,413a7.43,7.43,0,0,1-1.35,0l-3.64-.11c-3.08-.1-7.34-.23-12-.31s-9-.09-12-.09l-3.65,0a6.29,6.29,0,0,1-1.34-.07,7.4,7.4,0,0,1,1.34-.13c.86-.05,2.11-.11,3.65-.15,3.08-.09,7.34-.14,12-.06s9,.27,12,.47c1.54.09,2.79.19,3.65.27A6.13,6.13,0,0,1,131.55,413Z"
          style="fill: rgb(38, 50, 56); transform-origin: 114.56px 412.535px"
          id="elviv709ta7ob"
          class="animable"
        ></path>
      </g>
      <defs>
        <filter id="active" height="200%">
          <feMorphology
            in="SourceAlpha"
            result="DILATED"
            operator="dilate"
            radius="2"
          ></feMorphology>
          <feFlood
            flood-color="#32DFEC"
            flood-opacity="1"
            result="PINK"
          ></feFlood>
          <feComposite
            in="PINK"
            in2="DILATED"
            operator="in"
            result="OUTLINE"
          ></feComposite>
          <feMerge>
            <feMergeNode in="OUTLINE"></feMergeNode>
            <feMergeNode in="SourceGraphic"></feMergeNode>
          </feMerge>
        </filter>
        <filter id="hover" height="200%">
          <feMorphology
            in="SourceAlpha"
            result="DILATED"
            operator="dilate"
            radius="2"
          ></feMorphology>
          <feFlood
            flood-color="#ff0000"
            flood-opacity="0.5"
            result="PINK"
          ></feFlood>
          <feComposite
            in="PINK"
            in2="DILATED"
            operator="in"
            result="OUTLINE"
          ></feComposite>
          <feMerge>
            <feMergeNode in="OUTLINE"></feMergeNode>
            <feMergeNode in="SourceGraphic"></feMergeNode>
          </feMerge>
          <feColorMatrix
            type="matrix"
            values="0   0   0   0   0                0   1   0   0   0                0   0   0   0   0                0   0   0   1   0 "
          ></feColorMatrix>
        </filter>
      </defs>
    </svg>
  </main>
</template>


<style scoped>
svg#freepik_stories-create:not(.animated) .animable {
  opacity: 0;
}
svg#freepik_stories-create.animated #freepik--background-complete--inject-6 {
  animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) slideUp;
  animation-delay: 0s;
}
svg#freepik_stories-create.animated #freepik--Floor--inject-6 {
  animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) slideLeft;
  animation-delay: 0s;
}
svg#freepik_stories-create.animated #freepik--Sketches--inject-6 {
  animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) zoomOut;
  animation-delay: 0s;
}
svg#freepik_stories-create.animated #freepik--Desk--inject-6 {
  animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) slideUp;
  animation-delay: 0s;
}
svg#freepik_stories-create.animated #freepik--Character--inject-6 {
  animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) slideLeft;
  animation-delay: 0s;
}
svg#freepik_stories-create.animated #freepik--Plant--inject-6 {
  animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) zoomOut;
  animation-delay: 0s;
}
@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: inherit;
  }
}
@keyframes slideLeft {
  0% {
    opacity: 0;
    transform: translateX(-30px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes zoomOut {
  0% {
    opacity: 0;
    transform: scale(1.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
</style>

