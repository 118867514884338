<template>
  <main
    class="dropdown-wrapper"
    ref="dropdownWrapperRef"
    @mouseleave="searchResultsState = false"
  >
    <section class="dropdown-header">
      <img :src="selectedItem.img" v-if="selectedItem.img" />
      <div
        class="dropdown pointer"
        @click="getDistanceToBottom(), openDropdown()"
        ref="dropdownRef"
        :title="selectedItem.title"
      >
        <p class="-xstr" v-if="!selectedItem.title">{{ placeholder }}</p>
        <p
          class="-xstr"
          style="overflow: hidden; text-overflow: ellipsis"
          v-else
        >
          {{ selectedItem.title }}
        </p>
        <i
          class="i-arrow-open-down"
          :class="{ rotateIcon: searchResultsState }"
        ></i>
      </div>
    </section>

    <section
      :class="[
        dropdownOpenBottom ? 'search-wrapper-bottom' : 'search-wrapper-top',
      ]"
      v-show="searchResultsState"
      ref="searchWrapperRef"
    >
      <div class="search-input-wrapper">
        <input
          type="text"
          v-model="search"
          placeholder="Search..."
          autocomplete="off"
          class="-xstr search-input"
          :ref="searchInputRef"
          tabindex="0"
        />
      </div>

      <ul
        class="-xstr search-results"
        v-if="searchResultsState"
        ref="searchResultsRef"
        @scroll.native="handleScroll"
      >
        <li
          v-for="title in getItems"
          :key="title"
          @click="[commitItem(title), (searchResultsState = false)]"
          class="search-item"
        >
          <img :src="getImg(title)" class="search-img" v-if="imgs" />
          <p class="-xstr">{{ title }}</p>
        </li>
      </ul>
    </section>
  </main>
</template>

<script>
export default {
  //imgs is an array of img urls, the setImgState is if the object should be built with an img or not, titles are strings,
  props: ["placeholder", "titles", "imgs", "setImgState"],
  emits: ["setItem"],
  data() {
    return {
      search: "",
      searchResultsState: false,
      selectedItem: { title: "", img: null },
      dropdownOpenBottom: false,
      //refs
      dropdownWrapperRef: "dropdownWrapperRef",
      dropdownRef: "dropdownRef",
      searchInputRef: "searchInputRef",
      searchWrapperRef: "searchWrapperRef",
      searchResultsRef: "searchResultsRef",
      // infinite scroll state
      itemsPerPage: 20,
    };
  },
  methods: {
    openDropdown() {
      this.searchResultsState = !this.searchResultsState;
      this.setSearchWrapperWidth();

      const searchInput = this.$refs[this.searchInputRef];
      this.$nextTick(() => searchInput.focus());
    },
    getImg(title) {
      //return the imgs array item that matches the title index being passed in
      return this.imgs[this.titles.indexOf(title)];
    },
    commitItem(title) {
      this.selectedItem.title = title;

      if (this.setImgState && this.imgs.length)
        this.selectedItem.img = this.getImg(title);

      this.search = "";
      this.$emit("setItem", this.selectedItem);
    },
    setSearchWrapperWidth() {
      const domEl = this.$refs[this.dropdownRef];
      this.$nextTick(() => {
        this.$refs[this.searchWrapperRef].style.width =
          domEl.offsetWidth + "px";
      });
    },
    getDistanceToBottom() {
      const domEl = this.$refs[this.dropdownWrapperRef];
      const maxDropdownResultsHeight = 265;
      this.$nextTick(() => {
        // Get the position of the element relative to the viewport
        //DOMRect object which is the smallest rectangle which contains the entire element, including its padding and border-width
        const rect = domEl.getBoundingClientRect();

        //the result is in pixels
        const distanceToBottom = window.innerHeight - rect.bottom;

        distanceToBottom > maxDropdownResultsHeight
          ? (this.dropdownOpenBottom = true)
          : (this.dropdownOpenBottom = false);
      });
    },
    handleScroll() {
      const searchResults = this.$refs[this.searchResultsRef];
      // This code checks if the user has scrolled to the bottom of the search results
      // and if so, loads the next page of search results.
      if (
        searchResults.scrollTop + searchResults.offsetHeight >=
        searchResults.scrollHeight
      ) {
        this.itemsPerPage += this.itemsPerPage;
      }
    },
  },
  computed: {
    getItems() {
      const filtered = this.titles.filter((item) =>
        item.toLowerCase().includes(this.search.toLowerCase())
      );

      return filtered.slice(0, this.itemsPerPage);
    },
  },
};
</script>

<style scoped>
.dropdown-wrapper {
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: flex-end;
}

.dropdown-header {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
}

.dropdown-header > img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
}

input[type="text"],
.dropdown {
  border: 1px solid var(--gray5);
  border-radius: 2px;
  padding: 8px;
  outline: none;
}

input[type="text"] {
  height: 32px;
}

.dropdown {
  height: 38px;
  background: var(--white1);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

i {
  width: 14px;
}

.rotateIcon {
  transform: rotateZ(180deg);
}

input[type="text"],
.dropdown {
  width: 100%;
}

span {
  color: var(--error);
}

input[type="text"]:focus,
.dropdown:focus {
  border-color: var(--focus1);
  box-shadow: 0 0 1px 4px var(--focus2);
}

.search-wrapper-bottom {
  background: var(--white1);
  user-select: none;
  border: 1px solid var(--gray5);
  border-top: none;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  animation: slide-bottom 200ms;
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 100;
  display: flex;
  flex-flow: column;
}

.search-wrapper-top {
  background: var(--white1);
  user-select: none;
  border: 1px solid var(--gray5);
  border-bottom: none;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  animation: slide-bottom 200ms;
  position: absolute;
  bottom: 100%;
  right: 0;
  z-index: 100;
  display: flex;
  flex-flow: column-reverse;
}

.search-input-wrapper {
  padding: 4px;
}

.search-input {
  box-shadow: none !important;
}

.search-results {
  top: 100%;
  right: 0;
  max-height: 220px;
  overflow: auto;
}

.search-item {
  width: 100%;
  padding: 8px;
  cursor: pointer;
  background: var(--white1);
  display: flex;
  gap: 8px;
  align-items: center;
}

.search-item:hover {
  background: var(--focus3);
}

.search-img {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  object-fit: cover;
}

@keyframes slide-bottom {
  from {
    transform: translateY(-10px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}
</style>
