import apiClient from "../../axios/axios";
import store from "../../../store/index";
import { objectFilters } from "../../../helpers/objectFilters";

export async function createProject(data) {
  try {
    const endpoint = "/create_project";
    const res = await apiClient.post(endpoint, data);
    return { status: res.status, data: res.data };
  } catch (err) {
    console.log("Err on projectJs");
  }
}

export async function deleteProject(id) {
  try {
    const endpoint = `/delete_project/${id}`;
    const res = await apiClient.delete(endpoint);

    return res.status;
  } catch (error) {
    console.log("Err on projectJs");
    return res;
  }
}

export async function createUnit(data) {
  try {
    const proj_id = store.state.project.proj_id;
    const endpoint = `/create_unit/${proj_id}`;
    const res = await apiClient.post(endpoint, data);
    console.log("RES FROM AXIOS: ", res);
    return res.status;
  } catch (err) {
    console.log("Err on projectJs");
  }
}

export async function deleteUnit(id) {
  try {
    const endpoint = `/delete_unit/${id}`;
    const res = await apiClient.delete(endpoint);

    return res.status;
  } catch (error) {
    console.log("Err on projectJs");
    return res;
  }
}
