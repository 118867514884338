<template>
  <main class="width100 height100">
    <svg
      class="animated"
      id="freepik_stories-inbox-cleanup"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 500 500"
      version="1.1"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      xmlns:svgjs="http://svgjs.com/svgjs"
    >
      <g
        id="freepik--background-complete--inject-23"
        class="animable"
        style="transform-origin: 246.495px 249.38px"
      >
        <path
          d="M353.16,435.37c-1.59-1.36-5.14-5.68-2.4-10.71,1-1.84,3.13-2.75,5.05-3.56a20.58,20.58,0,0,0,5.55-2.87c3-2.56,3.84-6.81,5.83-10.22,2.3-3.92,6.57-6.86,11.09-6.65,5.18.23,9.49,4.46,11.45,9.28s2,10.17,2.07,15.37c0,2.88-.11,6.13-2.22,8.07-1.95,1.79-4.88,1.81-7.52,1.73l-28.28-.87"
          style="
            fill: rgb(245, 245, 245);
            transform-origin: 370.777px 418.592px;
          "
          id="elrza57dae0j"
          class="animable"
        ></path>
        <path
          d="M407.25,357.6H332.87l13.4,79.89a8.92,8.92,0,0,0,6.35,7.11l.26.07c.22.06.44.13.66.17a9.43,9.43,0,0,0,1.52.18v.07l.36,0h33.15a9.14,9.14,0,0,0,1.82-.19,9,9,0,0,0,7.53-7.8L404.39,382l.29-2.19h0L407.2,358Zm-14,51h-7.64l.78-23.19h8.84Zm2.72-23.19h7.31l-2.72,23.19H394Zm-21.81,48.89-.46-20.14h11L384,434.26Zm-11.88-20.14h10.68l.46,20.14h-9.57Zm.83,20.14h-9c-.85-5.44-2-12.5-3.16-20.14h10.55Zm-1.26-25.7L360,385.37h12.25l.53,23.19Zm11.72,0L373,385.37h12.61l-.77,23.19Zm-.66-28.75-.5-21.47h14.13l-.71,21.47Zm-.74,0H359.58l-1.66-21.47h13.73Zm-13.3,0H345.56l-1.32-8.29c-1.39-8.69-1.91-11.95-2.2-13.18h15.14Zm.44,5.56,1.8,23.19h-11c-.37-2.35-.74-4.73-1.12-7.11-.86-5.48-1.72-10.95-2.53-16.08Zm-9.94,23.19h-7.17l-3.89-23.19h7.41C346.82,392.54,348.08,400.51,349.34,408.56Zm.88,5.56c1.09,7,2.17,13.84,3.15,20.14h-6.89l-3.38-20.14Zm34.51,20.14.67-20.14h7.34L391,434.26Zm1.81-54.45.71-21.47h10.26l-1.84,21.47Zm-45.2-21.47c.3,1.55,1.65,10,3.47,21.47h-7.46l-3.61-21.47Zm56.17,75.92h-5.75l1.72-20.14h6.39Zm6.39-54.45h-7.49l1.84-21.47h8.17Z"
          style="fill: rgb(224, 224, 224); transform-origin: 370.06px 401.345px"
          id="elx3kgxeejzvq"
          class="animable"
        ></path>
        <path
          d="M114.24,188.36s-.17-.31-.46-.89l-1.29-2.58-4.86-9.82c-4.18-8.47-10.15-20.59-17.4-35.3l.29.1L23.92,172.5h0l.12-.35,23.79,48.69-.32-.11,48.08-23.39,13.71-6.64L113,189l.94-.44.34-.15-.3.16L113,189l-3.59,1.79-13.6,6.72L47.72,221.16l-.21.11-.11-.22L23.57,172.39l-.12-.24.24-.11h0l66.64-32.54.19-.09.09.19,17.26,35.51,4.74,9.82,1.23,2.57Z"
          style="fill: rgb(224, 224, 224); transform-origin: 68.865px 180.34px"
          id="elngqhhe5rcx"
          class="animable"
        ></path>
        <path
          d="M90.42,139.68a.88.88,0,0,1-.06.26q-.09.3-.24.75c-.23.7-.55,1.66-1,2.87-.87,2.53-2.11,6.11-3.64,10.52L73.34,188.73l-.34.16h-.06l-.08,0-.16-.05-.32-.11-.64-.21-1.26-.42L68,187.22l-4.89-1.63-9.25-3.09-15.65-5.26-10.54-3.59-2.85-1-.74-.27a.85.85,0,0,1-.25-.11.88.88,0,0,1,.26.06l.76.22,2.89.91,10.59,3.43L54,182l9.26,3.06,4.9,1.63,2.5.83,1.27.42.63.21.32.1.16.06.08,0h.06c-.14.07.34-.15-.31.17,4.81-13.5,9.16-25.71,12.32-34.6L89,143.49c.44-1.2.8-2.15,1-2.83l.29-.74Z"
          style="fill: rgb(224, 224, 224); transform-origin: 57.125px 164.285px"
          id="el16gkx4vwtti"
          class="animable"
        ></path>
        <path
          d="M56.63,183.18c.14,0-1.76,8.57-4.25,19.07s-4.63,19-4.77,18.95,1.77-8.57,4.26-19.07S56.49,183.14,56.63,183.18Z"
          style="fill: rgb(224, 224, 224); transform-origin: 52.12px 202.19px"
          id="elxtx8cgvsbv"
          class="animable"
        ></path>
        <path
          d="M114.94,188.72c-.06.12-8.06-3.8-17.87-8.76s-17.71-9.09-17.64-9.22,8.07,3.79,17.88,8.76S115,188.59,114.94,188.72Z"
          style="fill: rgb(224, 224, 224); transform-origin: 97.1849px 179.73px"
          id="eltnl9cyxxg2"
          class="animable"
        ></path>
        <path
          d="M443.73,194.08s.15-.24.45-.67l1.34-1.89,5.1-7.14,18.47-25.72,0,.3-51.4-37.13h0l.37-.06-25.55,35.28-.05-.33,36.92,26.86,10.54,7.7,2.81,2.06.72.54c.16.13.25.19.25.2l-.24-.16-.72-.5-2.8-2-10.57-7.57L392.21,157.1,392,157l.14-.2,25.51-35.3.16-.21.21.15h0l51.35,37.21.17.12-.13.17L450.71,184.6c-2.23,3-4,5.41-5.17,7.05-.58.79-1,1.4-1.35,1.82S443.73,194.08,443.73,194.08Z"
          style="fill: rgb(224, 224, 224); transform-origin: 430.77px 157.685px"
          id="el96193k0x5e5"
          class="animable"
        ></path>
        <path
          d="M469.26,158.79a7.12,7.12,0,0,1-.87.15l-2.49.35-9.17,1.17-30.27,3.69c-.57-.42-.16-.13-.29-.23h0v-.1l0-.14-.05-.27-.11-.55-.22-1.08c-.15-.72-.29-1.44-.44-2.15-.28-1.42-.57-2.82-.84-4.2-.55-2.75-1.09-5.41-1.59-7.94-1-5-1.91-9.6-2.67-13.44s-1.33-6.89-1.75-9.08l-.45-2.47a7.94,7.94,0,0,1-.13-.87,4.94,4.94,0,0,1,.23.85c.13.6.32,1.42.55,2.45l1.91,9c.79,3.84,1.73,8.38,2.78,13.43.51,2.53,1.06,5.18,1.62,7.93.28,1.38.56,2.78.85,4.2.15.71.29,1.42.44,2.14l.22,1.09.11.54.05.28,0,.13v.12c-.12-.09.29.22-.28-.19l30.29-3.53,9.19-1,2.5-.25A5.25,5.25,0,0,1,469.26,158.79Z"
          style="fill: rgb(224, 224, 224); transform-origin: 443.59px 142.89px"
          id="eldanu18jh50w"
          class="animable"
        ></path>
        <path
          d="M423.57,149.77c0,.14-7,1.87-15.64,3.87s-15.73,3.5-15.77,3.36,7-1.87,15.65-3.87S423.54,149.63,423.57,149.77Z"
          style="
            fill: rgb(224, 224, 224);
            transform-origin: 407.865px 153.385px;
          "
          id="elwx8mouunvq"
          class="animable"
        ></path>
        <path
          d="M443.76,194.73c-.14,0-.64-7.31-1.1-16.34s-.72-16.36-.57-16.37.63,7.31,1.09,16.34S443.9,194.72,443.76,194.73Z"
          style="
            fill: rgb(224, 224, 224);
            transform-origin: 442.923px 178.375px;
          "
          id="elh1is5uz2d0w"
          class="animable"
        ></path>
        <path
          d="M342.92,377.24l.78.3,2.22.92,8.43,3.52,30.32,12.75-.27.11,25.7-60.67h0l.14.35L368.59,316.8l.31-.13L350.24,360.3c-2.29,5.33-4.1,9.56-5.36,12.47-.62,1.43-1.1,2.54-1.44,3.31l-.37.86-.15.3s0-.1.11-.29.2-.48.34-.84l1.37-3.29L350,360.35c4.53-10.73,10.94-25.93,18.5-43.87l.09-.22.22.1L410.45,334l.24.1-.11.24h0c-9.69,22.79-18.65,43.83-25.8,60.64l-.08.19-.19-.08-30.36-13-8.34-3.6-2.16-.94Z"
          style="
            fill: rgb(224, 224, 224);
            transform-origin: 376.805px 355.715px;
          "
          id="el6yqxgakdnzl"
          class="animable"
        ></path>
        <path
          d="M384.59,394.93a.93.93,0,0,1-.1-.21c-.07-.17-.15-.38-.26-.63-.22-.6-.53-1.42-.92-2.45-.8-2.17-1.93-5.24-3.31-9l-10.74-29.82c.28-.65.09-.18.15-.33h0l.07,0,.13-.06.26-.12.53-.24,1.05-.47,2.07-.94,4.06-1.83,7.67-3.45,13-5.82,8.78-3.88,2.4-1,.63-.25c.15-.06.22-.08.22-.07l-.2.12-.6.3-2.36,1.12-8.72,4-13,5.92-7.66,3.48-4,1.83-2.08.94-1,.48-.52.23-.27.12-.13.06-.06,0,0,0h0c.05-.14-.15.33.12-.31,4.13,11.64,7.87,22.18,10.59,29.86,1.32,3.8,2.4,6.89,3.16,9.07.35,1.05.63,1.87.83,2.48.08.26.15.47.21.65A.76.76,0,0,1,384.59,394.93Z"
          style="fill: rgb(224, 224, 224); transform-origin: 389.77px 364.644px"
          id="elg60vt29vn4g"
          class="animable"
        ></path>
        <path
          d="M383.15,346.56c-.13.06-3.51-6.64-7.54-15s-7.19-15.12-7.06-15.19,3.5,6.64,7.53,15S383.27,346.5,383.15,346.56Z"
          style="fill: rgb(224, 224, 224); transform-origin: 375.85px 331.465px"
          id="elmnt5gw04nq"
          class="animable"
        ></path>
        <path
          d="M342.27,377.42c0-.14,7.26-2.33,16.3-4.89S375,368,375,368.15s-7.26,2.33-16.3,4.89S342.31,377.56,342.27,377.42Z"
          style="
            fill: rgb(224, 224, 224);
            transform-origin: 358.635px 372.785px;
          "
          id="eljkdiob1e8h"
          class="animable"
        ></path>
        <rect
          x="108.56"
          y="53.67"
          width="298.99"
          height="251.35"
          style="
            fill: rgb(235, 235, 235);
            transform-origin: 258.055px 179.345px;
          "
          id="elvuwl0qhluag"
          class="animable"
        ></rect>
        <path
          d="M109.14,71c0-.14,66.68-.26,148.91-.26S407,70.89,407,71s-66.66.26-148.92.26S109.14,71.17,109.14,71Z"
          style="fill: rgb(224, 224, 224); transform-origin: 258.07px 71px"
          id="elq4kv18t4oxh"
          class="animable"
        ></path>
        <circle
          cx="118.24"
          cy="62.03"
          r="3.2"
          style="fill: rgb(224, 224, 224); transform-origin: 118.24px 62.03px"
          id="elrvypg1emsr9"
          class="animable"
        ></circle>
        <circle
          cx="127.13"
          cy="62.03"
          r="3.2"
          style="fill: rgb(224, 224, 224); transform-origin: 127.13px 62.03px"
          id="eltiaibgonby"
          class="animable"
        ></circle>
        <circle
          cx="137"
          cy="62.03"
          r="3.2"
          style="fill: rgb(224, 224, 224); transform-origin: 137px 62.03px"
          id="elgcrnwb5y539"
          class="animable"
        ></circle>
      </g>
      <g
        id="freepik--Floor--inject-23"
        class="animable"
        style="transform-origin: 242.68px 445.58px"
      >
        <path
          d="M460.65,445.58c0,.15-97.59.26-217.94.26s-218-.11-218-.26,97.57-.26,218-.26S460.65,445.44,460.65,445.58Z"
          style="fill: rgb(38, 50, 56); transform-origin: 242.68px 445.58px"
          id="elfer79arhdzr"
          class="animable"
        ></path>
      </g>
      <g
        id="freepik--Screen--inject-23"
        class="animable"
        style="transform-origin: 242.635px 202.675px"
      >
        <path
          d="M396,331.71c0-1.83-.09-102.53-.22-257.9L396,74l-306.76,0h0l.26-.26c0,93.46,0,181.61,0,257.9l-.24-.23,306.79.23-306.79.23H89v-.23c0-76.29,0-164.44,0-257.9v-.26h.29l306.76,0h.22v.22C396.13,229.18,396,329.88,396,331.71Z"
          style="
            fill: rgb(250, 250, 250);
            transform-origin: 242.635px 202.675px;
          "
          id="el60x4pv2lte8"
          class="animable"
        ></path>
        <rect
          x="89.25"
          y="73.81"
          width="306.79"
          height="257.9"
          style="fill: rgb(250, 250, 250); transform-origin: 242.645px 202.76px"
          id="elx4ezx9rgdmn"
          class="animable"
        ></rect>
        <rect
          x="89.85"
          y="91.62"
          width="110.16"
          height="25.25"
          style="fill: rgb(235, 235, 235); transform-origin: 144.93px 104.245px"
          id="elyy84tvex82h"
          class="animable"
        ></rect>
        <path
          d="M117.63,112s0-.33,0-.93,0-1.49,0-2.54c0-2.24-.06-5.34-.11-9.08l.21.21-18.27,0,.26-.26h0v6.51c0,2.1,0,4.11,0,6l-.24-.24,13.11.12,3.78.07,1,0,.37,0a10.07,10.07,0,0,1-1.29.06l-3.73.06-13.26.12h-.23V112c0-1.91,0-3.92,0-6v-3.22c0-.54,0-1.09,0-1.64V99.43h0c-.23.23.32-.32.27-.26l18.27,0h.21v.21c0,3.8-.08,6.94-.11,9.21,0,1,0,1.86,0,2.5A4.89,4.89,0,0,1,117.63,112Z"
          style="fill: rgb(38, 50, 56); transform-origin: 108.577px 105.633px"
          id="el21nd2syhdjk"
          class="animable"
        ></path>
        <path
          d="M117.63,99.41s-.21.29-.65.74-1.09,1.08-1.9,1.86c-1.62,1.56-3.89,3.69-6.41,6-.71,0-.2,0-.36,0h0l0,0-.06,0-.12-.11-.23-.22-.47-.43-.9-.84-1.71-1.59L101.91,102c-.81-.78-1.46-1.41-1.91-1.86a3.7,3.7,0,0,1-.65-.74s.31.18.79.59,1.17,1,2,1.74l2.94,2.64,1.72,1.57.91.83.47.43.23.22.12.1.06.06,0,0h-.32c2.52-2.32,4.82-4.41,6.51-5.9.84-.74,1.53-1.34,2-1.74A3.49,3.49,0,0,1,117.63,99.41Z"
          style="fill: rgb(38, 50, 56); transform-origin: 108.49px 103.705px"
          id="elpq8qd62xmfm"
          class="animable"
        ></path>
        <path
          d="M105.44,105a20.82,20.82,0,0,1-2.86,3.67A20.59,20.59,0,0,1,99.33,112a21,21,0,0,1,2.86-3.66A20.94,20.94,0,0,1,105.44,105Z"
          style="fill: rgb(38, 50, 56); transform-origin: 102.385px 108.5px"
          id="eld5bi8rskwg"
          class="animable"
        ></path>
        <path
          d="M117.75,112.11a21.46,21.46,0,0,1-3.16-3.51,21.77,21.77,0,0,1-2.76-3.85,21.28,21.28,0,0,1,3.16,3.52A21.51,21.51,0,0,1,117.75,112.11Z"
          style="fill: rgb(38, 50, 56); transform-origin: 114.79px 108.43px"
          id="elk9oypkhi3m8"
          class="animable"
        ></path>
        <path
          d="M187.36,103.8c0,.15-12.9.26-28.8.26s-28.79-.11-28.79-.26,12.89-.26,28.79-.26S187.36,103.66,187.36,103.8Z"
          style="fill: rgb(38, 50, 56); transform-origin: 158.565px 103.8px"
          id="elfpzc8l2dzm"
          class="animable"
        ></path>
        <rect
          x="198.61"
          y="203.78"
          width="197.43"
          height="43.26"
          style="fill: rgb(255, 114, 94); transform-origin: 297.325px 225.41px"
          id="el9l2mqztjwpg"
          class="animable"
        ></rect>
        <g id="elznffsi9yckq">
          <g
            style="opacity: 0.8; transform-origin: 297.325px 225.41px"
            class="animable"
          >
            <rect
              x="198.61"
              y="203.78"
              width="197.43"
              height="43.26"
              style="
                fill: rgb(255, 255, 255);
                transform-origin: 297.325px 225.41px;
              "
              id="elju92vtlvuy"
              class="animable"
            ></rect>
          </g>
        </g>
        <rect
          x="211.31"
          y="217.3"
          width="16.21"
          height="16.21"
          rx="2.25"
          style="fill: rgb(255, 114, 94); transform-origin: 219.415px 225.405px"
          id="elj5wlg5luwu"
          class="animable"
        ></rect>
        <path
          d="M218.19,229a1,1,0,0,1-.6-.2l-3.27-2.46a1,1,0,1,1,1.2-1.59l2.55,1.91,5.12-5.56a1,1,0,1,1,1.47,1.36l-5.73,6.22A1,1,0,0,1,218.19,229Z"
          style="
            fill: rgb(255, 255, 255);
            transform-origin: 219.387px 224.889px;
          "
          id="elus65tiqr6hk"
          class="animable"
        ></path>
        <rect
          x="198.61"
          y="160.3"
          width="197.43"
          height="43.26"
          style="fill: rgb(255, 114, 94); transform-origin: 297.325px 181.93px"
          id="elnexhxcbtp3"
          class="animable"
        ></rect>
        <path
          d="M394.85,203.72c0,.15-43.62.26-97.42.26s-97.42-.11-97.42-.26,43.61-.26,97.42-.26S394.85,203.58,394.85,203.72Z"
          style="fill: rgb(38, 50, 56); transform-origin: 297.43px 203.72px"
          id="elfcmm07diu7s"
          class="animable"
        ></path>
        <rect
          x="198.72"
          y="290.26"
          width="197.43"
          height="41.45"
          style="fill: rgb(255, 114, 94); transform-origin: 297.435px 310.985px"
          id="elh1md0y9xia"
          class="animable"
        ></rect>
        <g id="elldozqj8cb5d">
          <g
            style="opacity: 0.8; transform-origin: 297.435px 310.985px"
            class="animable"
          >
            <rect
              x="198.72"
              y="290.26"
              width="197.43"
              height="41.45"
              style="
                fill: rgb(255, 255, 255);
                transform-origin: 297.435px 310.985px;
              "
              id="elsdk42n2lyt"
              class="animable"
            ></rect>
          </g>
        </g>
        <rect
          x="211.41"
          y="303.79"
          width="16.21"
          height="16.21"
          rx="2.25"
          style="fill: rgb(255, 114, 94); transform-origin: 219.515px 311.895px"
          id="el5ne7037cbn8"
          class="animable"
        ></rect>
        <path
          d="M218.29,315.5a1,1,0,0,1-.6-.2l-3.27-2.46a1,1,0,0,1,1.2-1.6l2.55,1.92,5.12-5.56a1,1,0,1,1,1.47,1.35L219,315.18A1,1,0,0,1,218.29,315.5Z"
          style="
            fill: rgb(255, 255, 255);
            transform-origin: 219.544px 311.364px;
          "
          id="el3h25sqyngw"
          class="animable"
        ></path>
        <g id="el3qbarh82z7c">
          <g
            style="opacity: 0.8; transform-origin: 297.325px 181.93px"
            class="animable"
          >
            <rect
              x="198.61"
              y="160.3"
              width="197.43"
              height="43.26"
              style="
                fill: rgb(255, 255, 255);
                transform-origin: 297.325px 181.93px;
              "
              id="elpxg8lidatne"
              class="animable"
            ></rect>
          </g>
        </g>
        <rect
          x="211.31"
          y="173.82"
          width="16.21"
          height="16.21"
          rx="2.25"
          style="fill: rgb(255, 114, 94); transform-origin: 219.415px 181.925px"
          id="elvsikf6ts5q"
          class="animable"
        ></rect>
        <path
          d="M218.19,185.53a1,1,0,0,1-.6-.2l-3.27-2.46a1,1,0,1,1,1.2-1.59l2.55,1.91,5.12-5.56a1,1,0,0,1,1.41-.06,1,1,0,0,1,.06,1.42l-5.73,6.22A1,1,0,0,1,218.19,185.53Z"
          style="
            fill: rgb(255, 255, 255);
            transform-origin: 219.388px 181.419px;
          "
          id="el8nj0nfn4j8w"
          class="animable"
        ></path>
        <path
          d="M396,331.71c0-1.83-.09-102.53-.22-257.9L396,74l-306.76,0h0l.26-.26c0,93.46,0,181.61,0,257.9l-.24-.23,306.79.23-306.79.23H89v-.23c0-76.29,0-164.44,0-257.9v-.26h.29l306.76,0h.22v.22C396.13,229.18,396,329.88,396,331.71Z"
          style="fill: rgb(255, 114, 94); transform-origin: 242.635px 202.675px"
          id="elpp2waqwgd6i"
          class="animable"
        ></path>
        <path
          d="M89.85,91.62c0-.15,68.41-.26,152.79-.26s152.81.11,152.81.26-68.4.26-152.81.26S89.85,91.76,89.85,91.62Z"
          style="fill: rgb(255, 114, 94); transform-origin: 242.65px 91.62px"
          id="elrlr3gibnl4"
          class="animable"
        ></path>
        <circle
          cx="99.19"
          cy="82.38"
          r="3.28"
          style="fill: rgb(255, 114, 94); transform-origin: 99.19px 82.38px"
          id="elp6xug9b1ov"
          class="animable"
        ></circle>
        <circle
          cx="108.31"
          cy="82.38"
          r="3.28"
          style="fill: rgb(255, 114, 94); transform-origin: 108.31px 82.38px"
          id="elz7eyxeqwuam"
          class="animable"
        ></circle>
        <path
          d="M120.75,80.06a3.28,3.28,0,1,1-4.64,0A3.28,3.28,0,0,1,120.75,80.06Z"
          style="fill: rgb(255, 114, 94); transform-origin: 118.43px 82.3786px"
          id="el3j0vxs41i5t"
          class="animable"
        ></path>
        <path
          d="M130,127a2.9,2.9,0,0,1,.71,0l2.08-.06,7.62-.1h.1l.06.08,3.36,4.42.05.07v.08c0,2,0,4.22,0,6.52v.26h-3.32l-13.72,0h-.22V138c0-2.26,0-4.27.06-6V132l0,0,2.34-3.67A11.47,11.47,0,0,1,130,127a1.63,1.63,0,0,1-.18.38l-.57,1-2.16,3.69,0-.09c0,1.7,0,3.71.05,6l-.22-.22,13.72,0h3.07l-.26.26c0-2.3,0-4.5,0-6.52l.05.14-3.3-4.45.17.08-7.54-.1-2.1,0A4.19,4.19,0,0,1,130,127Z"
          style="fill: rgb(38, 50, 56); transform-origin: 135.35px 132.555px"
          id="eltrfte3f4zq"
          class="animable"
        ></path>
        <path
          d="M143.75,131.78a5.83,5.83,0,0,1-1.45.2c-.92.08-2.24.17-3.81.27l.21-.22a4.43,4.43,0,0,1-.79,2.33,3.09,3.09,0,0,1-1,.92,3.59,3.59,0,0,1-1.4.33,3.5,3.5,0,0,1-2.75-1.16,5.18,5.18,0,0,1-1.12-2.39l.22.18c-1.46,0-2.68-.05-3.53-.09A5.09,5.09,0,0,1,127,132a5.45,5.45,0,0,1,1.33-.12c.85,0,2.07-.07,3.53-.1H132l0,.19a4.86,4.86,0,0,0,1.05,2.12,3,3,0,0,0,2.36,1,2.76,2.76,0,0,0,1.18-.27,2.55,2.55,0,0,0,.88-.75,4.16,4.16,0,0,0,.74-2.05l0-.21h.19c1.58-.05,2.9-.08,3.83-.09A6,6,0,0,1,143.75,131.78Z"
          style="fill: rgb(38, 50, 56); transform-origin: 135.375px 133.655px"
          id="ely3xlqyv7tci"
          class="animable"
        ></path>
        <path
          d="M181.34,127.55a3.34,3.34,0,0,1,.85-.08v.16c-2,.1-8.19.18-15.45.18-4.5,0-8.58,0-11.53-.08l-3.49-.08a5.77,5.77,0,0,1-1.28-.1,5.77,5.77,0,0,1,1.28-.1l3.49-.08c2.95-.05,7-.08,11.53-.08,7.26,0,13.41.08,15.45.18v.16A2.83,2.83,0,0,1,181.34,127.55Z"
          style="fill: rgb(38, 50, 56); transform-origin: 166.315px 127.55px"
          id="elxkw98n3g0s"
          class="animable"
        ></path>
        <path
          d="M181.34,134.1a2.83,2.83,0,0,1,.85-.08v.16c-2,.1-8.19.18-15.45.18-4.5,0-8.58,0-11.53-.08l-3.49-.08a6.66,6.66,0,0,1-1.28-.1,6.07,6.07,0,0,1,1.28-.11l3.49-.08c2.95,0,7-.07,11.53-.07,7.26,0,13.41.07,15.45.18v.16A4.07,4.07,0,0,1,181.34,134.1Z"
          style="fill: rgb(38, 50, 56); transform-origin: 166.315px 134.1px"
          id="el3c14288zz29"
          class="animable"
        ></path>
        <path
          d="M181.34,140.64a3.34,3.34,0,0,1,.85-.08v.16c-2,.11-8.19.18-15.45.18-4.5,0-8.58,0-11.53-.07l-3.49-.09a5.77,5.77,0,0,1-1.28-.1,6.66,6.66,0,0,1,1.28-.1l3.49-.08c2.95-.05,7-.08,11.53-.08,7.26,0,13.41.08,15.45.18v.16A2.83,2.83,0,0,1,181.34,140.64Z"
          style="fill: rgb(38, 50, 56); transform-origin: 166.315px 140.64px"
          id="el728cz6koy0v"
          class="animable"
        ></path>
        <path
          d="M130,173.87a3.14,3.14,0,0,1,.71-.06l2.08-.05,7.62-.1h.1l.06.08,3.36,4.42.05.07v.08c0,2,0,4.22,0,6.52v.26h-3.32l-13.72,0h-.22v-.22c0-2.26,0-4.27.06-6v-.05l0,0,2.34-3.67a11.47,11.47,0,0,1,.85-1.24,1.63,1.63,0,0,1-.18.38l-.57,1L127.1,179l0-.09c0,1.7,0,3.71.05,6l-.22-.22,13.72,0h3.07l-.26.26c0-2.3,0-4.5,0-6.52l.05.14-3.3-4.45.17.08-7.54-.1-2.1,0A4.19,4.19,0,0,1,130,173.87Z"
          style="fill: rgb(38, 50, 56); transform-origin: 135.35px 179.375px"
          id="eldu171mwhx78"
          class="animable"
        ></path>
        <path
          d="M143.75,178.62a5.83,5.83,0,0,1-1.45.2c-.92.08-2.24.17-3.81.27l.21-.22a4.43,4.43,0,0,1-.79,2.33,3.09,3.09,0,0,1-1,.92,3.59,3.59,0,0,1-1.4.33,3.5,3.5,0,0,1-2.75-1.16,5.18,5.18,0,0,1-1.12-2.39l.22.18c-1.46,0-2.68-.05-3.53-.09a5.09,5.09,0,0,1-1.33-.13,5.7,5.7,0,0,1,1.33-.13l3.53-.09H132l0,.19a4.86,4.86,0,0,0,1.05,2.12,3,3,0,0,0,2.36,1,2.76,2.76,0,0,0,1.18-.27,2.55,2.55,0,0,0,.88-.75,4.16,4.16,0,0,0,.74-2.05l0-.21h.19c1.58,0,2.9-.08,3.83-.09A6,6,0,0,1,143.75,178.62Z"
          style="fill: rgb(38, 50, 56); transform-origin: 135.375px 180.501px"
          id="elv464w84js7a"
          class="animable"
        ></path>
        <path
          d="M181.34,174.39a3.34,3.34,0,0,1,.85-.08v.16c-2,.1-8.19.18-15.45.18-4.5,0-8.58,0-11.53-.08l-3.49-.08a5.77,5.77,0,0,1-1.28-.1,5.77,5.77,0,0,1,1.28-.1l3.49-.08c2.95-.05,7-.08,11.53-.08,7.26,0,13.41.08,15.45.18v.16A3.34,3.34,0,0,1,181.34,174.39Z"
          style="fill: rgb(38, 50, 56); transform-origin: 166.315px 174.39px"
          id="elxzdtil4mwc"
          class="animable"
        ></path>
        <path
          d="M181.34,180.94a2.83,2.83,0,0,1,.85-.08V181c-2,.1-8.19.18-15.45.18-4.5,0-8.58,0-11.53-.08l-3.49-.08a6.66,6.66,0,0,1-1.28-.1,6.07,6.07,0,0,1,1.28-.11l3.49-.08c2.95-.05,7-.07,11.53-.07,7.26,0,13.41.07,15.45.18V181A4.07,4.07,0,0,1,181.34,180.94Z"
          style="fill: rgb(38, 50, 56); transform-origin: 166.315px 180.92px"
          id="elkotc9md3g2"
          class="animable"
        ></path>
        <path
          d="M181.34,187.48a3.34,3.34,0,0,1,.85-.08v.16c-2,.11-8.19.18-15.45.18-4.5,0-8.58,0-11.53-.07l-3.49-.09a5.77,5.77,0,0,1-1.28-.1,6.66,6.66,0,0,1,1.28-.1l3.49-.08c2.95-.05,7-.08,11.53-.08,7.26,0,13.41.08,15.45.18v.16A2.83,2.83,0,0,1,181.34,187.48Z"
          style="fill: rgb(38, 50, 56); transform-origin: 166.315px 187.48px"
          id="eltx2hxbegi"
          class="animable"
        ></path>
        <path
          d="M130,220.71a3.14,3.14,0,0,1,.71-.06l2.08-.05,7.62-.1h.1l.06.08,3.36,4.42.05.07v.08c0,2,0,4.22,0,6.52v.26h-3.32l-13.72,0h-.22v-.22c0-2.26,0-4.27.06-6v0l0,0,2.34-3.67a11.47,11.47,0,0,1,.85-1.24,1.63,1.63,0,0,1-.18.38l-.57,1-2.16,3.69,0-.09c0,1.7,0,3.71.05,6l-.22-.22,13.72,0h3.07l-.26.26c0-2.3,0-4.5,0-6.52l.05.14-3.3-4.45.17.08-7.54-.1-2.1,0A4.19,4.19,0,0,1,130,220.71Z"
          style="fill: rgb(38, 50, 56); transform-origin: 135.35px 226.215px"
          id="el3g9obkv6rt"
          class="animable"
        ></path>
        <path
          d="M143.75,225.46a5.83,5.83,0,0,1-1.45.2c-.92.08-2.24.17-3.81.27l.21-.22a4.43,4.43,0,0,1-.79,2.33,3.09,3.09,0,0,1-1,.92,3.41,3.41,0,0,1-1.4.32,3.46,3.46,0,0,1-2.75-1.15,5.18,5.18,0,0,1-1.12-2.39l.22.18c-1.46,0-2.68,0-3.53-.09a5.09,5.09,0,0,1-1.33-.13,5.7,5.7,0,0,1,1.33-.13l3.53-.09H132l0,.19a4.86,4.86,0,0,0,1.05,2.12,3,3,0,0,0,2.36,1,2.76,2.76,0,0,0,1.18-.27,2.55,2.55,0,0,0,.88-.75,4.16,4.16,0,0,0,.74-2.05l0-.21h.19c1.58-.06,2.9-.08,3.83-.09A6,6,0,0,1,143.75,225.46Z"
          style="fill: rgb(38, 50, 56); transform-origin: 135.375px 227.337px"
          id="el0nrmp5h7wsx"
          class="animable"
        ></path>
        <path
          d="M181.34,221.23a3.34,3.34,0,0,1,.85-.08v.16c-2,.1-8.19.18-15.45.18-4.5,0-8.58,0-11.53-.08l-3.49-.08a5.77,5.77,0,0,1-1.28-.1,5.77,5.77,0,0,1,1.28-.1l3.49-.08c2.95-.05,7-.08,11.53-.08,7.26,0,13.41.08,15.45.18v.16A3.34,3.34,0,0,1,181.34,221.23Z"
          style="fill: rgb(38, 50, 56); transform-origin: 166.315px 221.23px"
          id="elw35l47jidq"
          class="animable"
        ></path>
        <path
          d="M181.34,227.78a2.83,2.83,0,0,1,.85-.08v.16c-2,.1-8.19.18-15.45.18-4.5,0-8.58,0-11.53-.08l-3.49-.08a6.66,6.66,0,0,1-1.28-.1,6.07,6.07,0,0,1,1.28-.11l3.49-.08c2.95-.05,7-.07,11.53-.07,7.26,0,13.41.07,15.45.18v.16A4.07,4.07,0,0,1,181.34,227.78Z"
          style="fill: rgb(38, 50, 56); transform-origin: 166.315px 227.78px"
          id="el84knu04yojt"
          class="animable"
        ></path>
        <path
          d="M181.34,234.32a3.34,3.34,0,0,1,.85-.08v.16c-2,.11-8.19.18-15.45.18-4.5,0-8.58,0-11.53-.07l-3.49-.09a5.77,5.77,0,0,1-1.28-.1,6.66,6.66,0,0,1,1.28-.1l3.49-.08c2.95,0,7-.08,11.53-.08,7.26,0,13.41.08,15.45.18v.16A2.83,2.83,0,0,1,181.34,234.32Z"
          style="fill: rgb(38, 50, 56); transform-origin: 166.315px 234.32px"
          id="el9j1ukkmko9o"
          class="animable"
        ></path>
        <path
          d="M380.12,190.55H372.5a3,3,0,0,1-3.05-3V174.85h1v12.66a2,2,0,0,0,2.05,2h7.62a2,2,0,0,0,2-2V175.29h1v12.22A3,3,0,0,1,380.12,190.55Z"
          style="fill: rgb(255, 114, 94); transform-origin: 376.285px 182.7px"
          id="elsxw7pl9diz"
          class="animable"
        ></path>
        <rect
          x="368.2"
          y="174.92"
          width="16.22"
          height="1"
          style="fill: rgb(255, 114, 94); transform-origin: 376.31px 175.42px"
          id="elp5toptew9tl"
          class="animable"
        ></rect>
        <path
          d="M373.36,175.42h-1v-1.11a2,2,0,0,1,2-2h4a2,2,0,0,1,2,2v.89h-1v-.89a1,1,0,0,0-1-1h-4a1,1,0,0,0-1,1Z"
          style="fill: rgb(255, 114, 94); transform-origin: 376.36px 173.865px"
          id="elum9bqemqrnh"
          class="animable"
        ></path>
        <path
          d="M374,186.81a18.2,18.2,0,0,1-.26-4.3,18.14,18.14,0,0,1,.26-4.29,17.53,17.53,0,0,1,.26,4.29A17.58,17.58,0,0,1,374,186.81Z"
          style="fill: rgb(255, 114, 94); transform-origin: 374.002px 182.515px"
          id="elp9eyvzdhe2l"
          class="animable"
        ></path>
        <path
          d="M376.35,186.81a18.2,18.2,0,0,1-.26-4.3,18.14,18.14,0,0,1,.26-4.29,17.53,17.53,0,0,1,.26,4.29A17.58,17.58,0,0,1,376.35,186.81Z"
          style="fill: rgb(255, 114, 94); transform-origin: 376.352px 182.515px"
          id="ellef1xj01e5p"
          class="animable"
        ></path>
        <path
          d="M378.72,186.81a18.2,18.2,0,0,1-.26-4.3,18.14,18.14,0,0,1,.26-4.29,17.53,17.53,0,0,1,.26,4.29A17.58,17.58,0,0,1,378.72,186.81Z"
          style="fill: rgb(255, 114, 94); transform-origin: 378.722px 182.515px"
          id="el3qkcc3e338i"
          class="animable"
        ></path>
        <path
          d="M380.12,231.55H372.5a3.06,3.06,0,0,1-3.05-3.05V215.84h1V228.5a2.05,2.05,0,0,0,2.05,2.05h7.62a2.05,2.05,0,0,0,2-2.05V216.28h1V228.5A3,3,0,0,1,380.12,231.55Z"
          style="fill: rgb(255, 114, 94); transform-origin: 376.285px 223.695px"
          id="elf01hrwsjn65"
          class="animable"
        ></path>
        <rect
          x="368.2"
          y="215.91"
          width="16.22"
          height="1"
          style="fill: rgb(255, 114, 94); transform-origin: 376.31px 216.41px"
          id="elevhlcxpwxtg"
          class="animable"
        ></rect>
        <path
          d="M373.36,216.41h-1V215.3a2,2,0,0,1,2-2h4a2,2,0,0,1,2,2v.89h-1v-.89a1,1,0,0,0-1-1h-4a1,1,0,0,0-1,1Z"
          style="fill: rgb(255, 114, 94); transform-origin: 376.36px 214.855px"
          id="elbt5obt1nio8"
          class="animable"
        ></path>
        <path
          d="M374,227.8a35.61,35.61,0,0,1,0-8.59,35.61,35.61,0,0,1,0,8.59Z"
          style="fill: rgb(255, 114, 94); transform-origin: 374px 223.505px"
          id="el2fas8pgdbj8"
          class="animable"
        ></path>
        <path
          d="M376.35,227.8a35.61,35.61,0,0,1,0-8.59,35.61,35.61,0,0,1,0,8.59Z"
          style="fill: rgb(255, 114, 94); transform-origin: 376.35px 223.505px"
          id="elsowfby2dgcd"
          class="animable"
        ></path>
        <path
          d="M378.72,227.8a35.61,35.61,0,0,1,0-8.59,35.61,35.61,0,0,1,0,8.59Z"
          style="fill: rgb(255, 114, 94); transform-origin: 378.72px 223.505px"
          id="elyw39ainn3jk"
          class="animable"
        ></path>
        <path
          d="M380.12,321.66H372.5a3,3,0,0,1-3.05-3V306h1v12.66a2,2,0,0,0,2.05,2h7.62a2,2,0,0,0,2-2V306.4h1v12.22A3,3,0,0,1,380.12,321.66Z"
          style="fill: rgb(255, 114, 94); transform-origin: 376.285px 313.83px"
          id="elb512oy2q1b5"
          class="animable"
        ></path>
        <rect
          x="368.2"
          y="306.03"
          width="16.22"
          height="1"
          style="fill: rgb(255, 114, 94); transform-origin: 376.31px 306.53px"
          id="elo21fxqwiwhn"
          class="animable"
        ></rect>
        <path
          d="M373.36,306.53h-1v-1.12a2,2,0,0,1,2-2h4a2,2,0,0,1,2,2v.9h-1v-.9a1,1,0,0,0-1-1h-4a1,1,0,0,0-1,1Z"
          style="fill: rgb(255, 114, 94); transform-origin: 376.36px 304.97px"
          id="eldmuazbllibo"
          class="animable"
        ></path>
        <path
          d="M374,317.92a35.61,35.61,0,0,1,0-8.59,35.61,35.61,0,0,1,0,8.59Z"
          style="fill: rgb(255, 114, 94); transform-origin: 374px 313.625px"
          id="elrfzve0kn6yi"
          class="animable"
        ></path>
        <path
          d="M376.35,317.92a35.61,35.61,0,0,1,0-8.59,35.61,35.61,0,0,1,0,8.59Z"
          style="fill: rgb(255, 114, 94); transform-origin: 376.35px 313.625px"
          id="el0i9t8g6rq0st"
          class="animable"
        ></path>
        <path
          d="M378.72,317.92a35.61,35.61,0,0,1,0-8.59,35.61,35.61,0,0,1,0,8.59Z"
          style="fill: rgb(255, 114, 94); transform-origin: 378.72px 313.625px"
          id="eli0ujw2rnto"
          class="animable"
        ></path>
        <path
          d="M384.26,143.3a7.47,7.47,0,0,1-.05-.93c0-.65,0-1.49,0-2.55,0-2.23-.07-5.33-.11-9.08l.21.21L366,131l.26-.26h0v3.29c0,1.09,0,2.16,0,3.21,0,2.1,0,4.12,0,6l-.24-.24,13.1.12,3.79.06,1,0,.37,0a12.53,12.53,0,0,1-1.29,0l-3.73.07-13.26.12h-.24v-.24c0-1.91,0-3.93,0-6v-6.5h0c-.23.23.32-.32.26-.27l18.28.06h.21v.2c0,3.8-.08,6.95-.1,9.21,0,1,0,1.87-.06,2.5A6.55,6.55,0,0,1,384.26,143.3Z"
          style="fill: rgb(38, 50, 56); transform-origin: 375.107px 136.863px"
          id="elfaju55jfuop"
          class="animable"
        ></path>
        <path
          d="M384.26,130.74a3.7,3.7,0,0,1-.65.74c-.45.45-1.1,1.09-1.91,1.87-1.62,1.56-3.88,3.68-6.41,6h-.36l0,0,0,0-.06-.06-.11-.11-.24-.21-.46-.43-.91-.84-1.7-1.59-2.87-2.72c-.81-.78-1.46-1.42-1.9-1.87a3.7,3.7,0,0,1-.65-.74,4.54,4.54,0,0,1,.79.59c.48.41,1.17,1,2,1.75l2.94,2.63,1.73,1.57.91.84.46.42.24.22.12.11.06,0,0,0h0c-.16,0,.36,0-.35,0,2.52-2.33,4.83-4.41,6.51-5.9.85-.75,1.54-1.34,2-1.75A3.8,3.8,0,0,1,384.26,130.74Z"
          style="fill: rgb(38, 50, 56); transform-origin: 375.145px 135.045px"
          id="el5nkpix2vd5m"
          class="animable"
        ></path>
        <path
          d="M372.07,136.36A22,22,0,0,1,369.2,140a20.46,20.46,0,0,1-3.25,3.33,20.41,20.41,0,0,1,2.86-3.67A20.4,20.4,0,0,1,372.07,136.36Z"
          style="fill: rgb(38, 50, 56); transform-origin: 369.01px 139.845px"
          id="elt3siamcjrd"
          class="animable"
        ></path>
        <path
          d="M384.37,143.44a22,22,0,0,1-3.16-3.51,21.74,21.74,0,0,1-2.75-3.84,22,22,0,0,1,3.16,3.51A21.13,21.13,0,0,1,384.37,143.44Z"
          style="fill: rgb(38, 50, 56); transform-origin: 381.415px 139.765px"
          id="elv65vy0cr9p"
          class="animable"
        ></path>
        <path
          d="M394.85,116.87c0,.14-43.62.26-97.42.26S200,117,200,116.87s43.61-.26,97.42-.26S394.85,116.73,394.85,116.87Z"
          style="fill: rgb(38, 50, 56); transform-origin: 297.425px 116.87px"
          id="elrx7qx2n7twd"
          class="animable"
        ></path>
        <path
          d="M394.85,160.3c0,.14-43.62.26-97.42.26S200,160.44,200,160.3s43.61-.26,97.42-.26S394.85,160.15,394.85,160.3Z"
          style="fill: rgb(38, 50, 56); transform-origin: 297.425px 160.3px"
          id="eldaiobh97wt"
          class="animable"
        ></path>
        <path
          d="M394.85,246.26c0,.14-43.62.26-97.42.26S200,246.4,200,246.26s43.61-.26,97.42-.26S394.85,246.11,394.85,246.26Z"
          style="fill: rgb(38, 50, 56); transform-origin: 297.425px 246.26px"
          id="ell2kj18pdnc"
          class="animable"
        ></path>
        <path
          d="M394.85,290.26c0,.15-43.62.26-97.42.26s-97.42-.11-97.42-.26,43.61-.26,97.42-.26S394.85,290.12,394.85,290.26Z"
          style="fill: rgb(38, 50, 56); transform-origin: 297.43px 290.26px"
          id="eltlr1nq6o7j"
          class="animable"
        ></path>
        <path
          d="M225.28,147s.22,0,.6-.14a2.2,2.2,0,0,0,1.28-1.07c.39-.65.19-1.62.22-2.72s0-2.35,0-3.72,0-2.9,0-4.51c0-.41,0-.82,0-1.24a3.53,3.53,0,0,0-.1-1.19,2,2,0,0,0-1.79-1.37H213.56a2,2,0,0,0-1.95,1.63c-.07.92,0,2,0,3,0,2,0,3.9,0,5.73,0,.91,0,1.8,0,2.67a5.22,5.22,0,0,0,.06,1.21,2.06,2.06,0,0,0,.55,1,2.13,2.13,0,0,0,.94.52,4.82,4.82,0,0,0,1.09.06l2.17,0,6.53.08,1.78.05a3,3,0,0,1,.62.05,2.29,2.29,0,0,1-.62,0l-1.78.05-6.53.09h-2.17a4.92,4.92,0,0,1-1.19-.06,2.43,2.43,0,0,1-1.82-1.77,5,5,0,0,1-.08-1.32c0-.87,0-1.76,0-2.67,0-1.83,0-3.74,0-5.73v-1.5a12.1,12.1,0,0,1,0-1.57,2.51,2.51,0,0,1,2.46-2l11.88,0a2.49,2.49,0,0,1,2.19,1.7,4,4,0,0,1,.12,1.34c0,.42,0,.83,0,1.24,0,1.61,0,3.12,0,4.51s0,2.62,0,3.72c0,.55,0,1.06,0,1.53a2.54,2.54,0,0,1-.31,1.29,2.35,2.35,0,0,1-1.43,1.08A1.45,1.45,0,0,1,225.28,147Z"
          style="fill: rgb(38, 50, 56); transform-origin: 219.479px 138.876px"
          id="eltffi47s2n5k"
          class="animable"
        ></path>
        <path
          d="M225.28,276.77s.22,0,.6-.14a2.2,2.2,0,0,0,1.28-1.07c.39-.65.19-1.62.22-2.72s0-2.35,0-3.72,0-2.9,0-4.51c0-.41,0-.82,0-1.24a3.53,3.53,0,0,0-.1-1.19,2,2,0,0,0-1.79-1.37H213.56a2,2,0,0,0-1.95,1.62c-.07.93,0,2,0,3,0,2,0,3.9,0,5.73,0,.91,0,1.8,0,2.66a5.28,5.28,0,0,0,.06,1.22,2,2,0,0,0,.55,1,2,2,0,0,0,.94.52,4.29,4.29,0,0,0,1.09.07l2.17,0,6.53.08,1.78.05a3,3,0,0,1,.62,0,2.29,2.29,0,0,1-.62.05l-1.78.05-6.53.08-2.17,0a4.92,4.92,0,0,1-1.19-.06,2.43,2.43,0,0,1-1.82-1.77,5,5,0,0,1-.08-1.33c0-.86,0-1.75,0-2.66,0-1.83,0-3.74,0-5.73v-1.5a12.1,12.1,0,0,1,0-1.57,2.51,2.51,0,0,1,2.46-2.05l11.88,0a2.49,2.49,0,0,1,2.19,1.7,4,4,0,0,1,.12,1.34c0,.42,0,.83,0,1.24,0,1.61,0,3.12,0,4.51s0,2.62,0,3.72c0,.55,0,1.06,0,1.53a2.54,2.54,0,0,1-.31,1.29,2.29,2.29,0,0,1-1.43,1.07A1.59,1.59,0,0,1,225.28,276.77Z"
          style="fill: rgb(38, 50, 56); transform-origin: 219.479px 268.611px"
          id="el64ka8h0vkw6"
          class="animable"
        ></path>
        <path
          d="M198.56,328s0-.21,0-.61,0-1,0-1.79c0-1.6,0-3.92,0-6.89,0-6,0-14.65,0-25.33,0-21.38.26-50.92.56-83.55s.53-62.16.72-83.56c.07-10.68.12-19.33.16-25.33,0-3,0-5.29.06-6.88,0-.78,0-1.37,0-1.79s0-.61,0-.61,0,.2,0,.61,0,1,0,1.79c0,1.59,0,3.91,0,6.88,0,6,0,14.65,0,25.33-.05,21.38-.26,50.93-.57,83.56s-.53,62.15-.71,83.55c-.07,10.68-.12,19.33-.16,25.33,0,3,0,5.29-.06,6.89,0,.77,0,1.36,0,1.79S198.56,328,198.56,328Z"
          style="fill: rgb(38, 50, 56); transform-origin: 199.31px 209.83px"
          id="elm9cf0zb4dto"
          class="animable"
        ></path>
        <path
          d="M309.91,177.72c0,.14-15,.26-33.56.26s-33.56-.12-33.56-.26,15-.26,33.56-.26S309.91,177.57,309.91,177.72Z"
          style="fill: rgb(38, 50, 56); transform-origin: 276.35px 177.72px"
          id="eli0kcv9phvos"
          class="animable"
        ></path>
        <path
          d="M351.86,185.12c0,.15-24.42.26-54.53.26s-54.54-.11-54.54-.26,24.42-.26,54.54-.26S351.86,185,351.86,185.12Z"
          style="fill: rgb(38, 50, 56); transform-origin: 297.325px 185.12px"
          id="el002lxgt8ww07g"
          class="animable"
        ></path>
        <path
          d="M309.91,264.83c0,.14-15,.26-33.56.26s-33.56-.12-33.56-.26,15-.26,33.56-.26S309.91,264.68,309.91,264.83Z"
          style="fill: rgb(38, 50, 56); transform-origin: 276.35px 264.83px"
          id="elshsxwl2cwng"
          class="animable"
        ></path>
        <path
          d="M351.86,272.23c0,.14-24.42.26-54.53.26s-54.54-.12-54.54-.26,24.42-.26,54.54-.26S351.86,272.09,351.86,272.23Z"
          style="fill: rgb(38, 50, 56); transform-origin: 297.325px 272.23px"
          id="elu12wvcubqim"
          class="animable"
        ></path>
        <path
          d="M309.91,135.86c0,.15-15,.27-33.56.27s-33.56-.12-33.56-.27,15-.26,33.56-.26S309.91,135.72,309.91,135.86Z"
          style="fill: rgb(38, 50, 56); transform-origin: 276.35px 135.865px"
          id="elmdke7wn0hq"
          class="animable"
        ></path>
        <path
          d="M351.86,143.27c0,.14-24.42.26-54.53.26s-54.54-.12-54.54-.26,24.42-.26,54.54-.26S351.86,143.13,351.86,143.27Z"
          style="fill: rgb(38, 50, 56); transform-origin: 297.325px 143.27px"
          id="el2kxsiqjkp45"
          class="animable"
        ></path>
        <path
          d="M309.91,220.4c0,.14-15,.26-33.56.26s-33.56-.12-33.56-.26,15-.26,33.56-.26S309.91,220.26,309.91,220.4Z"
          style="fill: rgb(38, 50, 56); transform-origin: 276.35px 220.4px"
          id="elgcth651syo"
          class="animable"
        ></path>
        <path
          d="M351.86,227.8c0,.15-24.42.26-54.53.26s-54.54-.11-54.54-.26,24.42-.26,54.54-.26S351.86,227.66,351.86,227.8Z"
          style="fill: rgb(38, 50, 56); transform-origin: 297.325px 227.8px"
          id="elci7h7hgr69f"
          class="animable"
        ></path>
        <path
          d="M309.91,306.16c0,.14-15,.26-33.56.26s-33.56-.12-33.56-.26,15-.26,33.56-.26S309.91,306,309.91,306.16Z"
          style="fill: rgb(38, 50, 56); transform-origin: 276.35px 306.16px"
          id="el9wdnta74qv"
          class="animable"
        ></path>
        <path
          d="M351.86,313.56c0,.14-24.42.26-54.53.26s-54.54-.12-54.54-.26,24.42-.26,54.54-.26S351.86,313.42,351.86,313.56Z"
          style="fill: rgb(38, 50, 56); transform-origin: 297.325px 313.56px"
          id="elt6f7e9j80t8"
          class="animable"
        ></path>
        <rect
          x="372.86"
          y="97.07"
          width="13.21"
          height="13.21"
          style="
            fill: rgb(224, 224, 224);
            transform-origin: 379.465px 103.675px;
          "
          id="el2d2cnupjahx"
          class="animable"
        ></rect>
        <rect
          x="351.35"
          y="97.07"
          width="13.21"
          height="13.21"
          style="
            fill: rgb(224, 224, 224);
            transform-origin: 357.955px 103.675px;
          "
          id="el1qvnoet29mj"
          class="animable"
        ></rect>
        <rect
          x="329.84"
          y="97.07"
          width="13.21"
          height="13.21"
          style="
            fill: rgb(224, 224, 224);
            transform-origin: 336.445px 103.675px;
          "
          id="el6qh9h94zxtv"
          class="animable"
        ></rect>
        <polygon
          points="103.29 131.66 108.9 138.98 113.7 131.66 103.29 131.66"
          style="fill: rgb(38, 50, 56); transform-origin: 108.495px 135.32px"
          id="eldgbcfmprj24"
          class="animable"
        ></polygon>
      </g>
      <g
        id="freepik--Character--inject-23"
        class="animable"
        style="transform-origin: 173.803px 329.326px"
      >
        <path
          d="M91.13,226.61c13-17.16,26.76-14.74,36.29-10.59a26.18,26.18,0,0,1,15.3,25c-.16,2.74-.75,5.62.37,8.13,1.79,4,7,5.17,10,8.39,4.13,4.41,3.22,11.38,1.82,17.26s-2.86,12.58.59,17.53c3.17,4.53,9.2,5.75,14.18,8.17a26,26,0,0,1,13.76,16.19,22.2,22.2,0,0,1-4.71,20.48c-4.84,5.26-12.1,7.6-19.17,8.64a70.63,70.63,0,0,1-80.85-73.46"
          style="fill: rgb(38, 50, 56); transform-origin: 131.423px 279.789px"
          id="el2jzej51gdl"
          class="animable"
        ></path>
        <path
          d="M128.1,216.75a37.59,37.59,0,0,0-4.63.26,61.91,61.91,0,0,0-10.76,3.12c-4.15,1.45-8.16,2.26-11.08,2.07a13.89,13.89,0,0,1-3.39-.55,4.72,4.72,0,0,1-1.18-.47,22.9,22.9,0,0,0,4.58.66,33,33,0,0,0,10.91-2.2c4.13-1.45,8-2.7,10.87-3a18.19,18.19,0,0,1,3.44-.09A4.75,4.75,0,0,1,128.1,216.75Z"
          style="fill: rgb(69, 90, 100); transform-origin: 112.58px 219.367px"
          id="elx1rl8u1nt"
          class="animable"
        ></path>
        <path
          d="M158.06,353.24c.5,1.5,10.87,50.5,10.87,50.5l78.89,30.33-4.44,11.51-67.64-5.95h0c-39.48-5.89-41.67-25.26-39.29-46.91l4.09-37.11Z"
          style="fill: rgb(255, 190, 157); transform-origin: 191.806px 399.41px"
          id="elqszutg89q8f"
          class="animable"
        ></path>
        <path
          d="M44.67,326.82c8.13-12.31,20.47-19.41,31.77-23.34l41.5,3a31.64,31.64,0,0,1,25.28,9.33l3.86,102.83,9.53,27H57.88L44.67,326.82"
          style="fill: rgb(255, 114, 94); transform-origin: 100.64px 374.56px"
          id="elqdicb71b7f"
          class="animable"
        ></path>
        <path
          d="M144.74,358s-.09-.05-.24-.16l-.67-.5a12.91,12.91,0,0,0-1.09-.81l-1.55-1c-.62-.33-1.3-.7-2.05-1.07a27.4,27.4,0,0,0-9.09-2.39,27.1,27.1,0,0,0-8.06.5,24.3,24.3,0,0,0-8.51,3.58,17.87,17.87,0,0,0-6.7,7.53,10.76,10.76,0,0,0-.91,5.21,9.81,9.81,0,0,0,2,5.05,7,7,0,0,0,4.56,2.71,4.41,4.41,0,0,0,4.49-1.93,5.79,5.79,0,0,0,.08-4.9,9.31,9.31,0,0,0-1.18-2.2,12.31,12.31,0,0,0-1.66-1.8,17.83,17.83,0,0,0-8.19-4.09,25.15,25.15,0,0,0-8.06-.45A53,53,0,0,0,86.43,364l-3.05,1-.79.26a.82.82,0,0,1-.28.07l.26-.12.78-.31c.68-.26,1.7-.65,3-1.12a50.33,50.33,0,0,1,11.49-2.87,25.6,25.6,0,0,1,8.19.39,18.4,18.4,0,0,1,8.41,4.16c.32.28.62.58.91.89a9,9,0,0,1,.83,1,10,10,0,0,1,1.25,2.3,6.32,6.32,0,0,1-.11,5.34,4.31,4.31,0,0,1-2.15,1.84,5.71,5.71,0,0,1-2.84.34,7.47,7.47,0,0,1-4.91-2.9,10.23,10.23,0,0,1-2.07-5.32,11.11,11.11,0,0,1,1-5.46,18.26,18.26,0,0,1,6.9-7.73,25,25,0,0,1,8.69-3.61,27.48,27.48,0,0,1,8.18-.44,26,26,0,0,1,6.6,1.46,27.5,27.5,0,0,1,2.56,1.07c.76.39,1.44.78,2,1.12l1.54,1.05a13.65,13.65,0,0,1,1.07.86l.63.54Z"
          style="
            fill: rgb(250, 250, 250);
            transform-origin: 113.525px 364.403px;
          "
          id="elwzbc4ad0djj"
          class="animable"
        ></path>
        <polygon
          points="143.22 315.76 167.72 359.82 140.54 368.17 143.22 315.76"
          style="fill: rgb(255, 114, 94); transform-origin: 154.13px 341.965px"
          id="ele3bcsrf3978"
          class="animable"
        ></polygon>
        <path
          d="M146.51,418.93a4.62,4.62,0,0,1-.08-.77c0-.55-.07-1.28-.13-2.2-.09-2-.21-4.74-.37-8.14-.28-6.86-.63-16.35-.94-26.83s-.5-20-.56-26.86c0-3.44,0-6.22,0-8.14,0-.92,0-1.66,0-2.21a4.55,4.55,0,0,1,.05-.77,3.81,3.81,0,0,1,.06.77l.06,2.21c0,2,.1,4.74.18,8.13.18,6.92.43,16.39.71,26.85s.59,19.92.79,26.84c.08,3.39.15,6.16.2,8.14,0,.92,0,1.66,0,2.21A3.76,3.76,0,0,1,146.51,418.93Z"
          style="fill: rgb(38, 50, 56); transform-origin: 145.473px 380.97px"
          id="elv2uu4oaxhke"
          class="animable"
        ></path>
        <path
          d="M150.45,329.18a2.8,2.8,0,0,1-.56.39,15.32,15.32,0,0,1-1.72.94,18.14,18.14,0,0,1-17.26-1.45,11.4,11.4,0,0,1-2.39-2,5.9,5.9,0,0,1-1.48-3,4,4,0,0,1,.89-3.33,3.56,3.56,0,0,1,3.39-1,4.4,4.4,0,0,1,2.93,2,5,5,0,0,1,.53,3.41,6.74,6.74,0,0,1-1.39,3,8.14,8.14,0,0,1-2.45,2,15.34,15.34,0,0,1-5.55,1.56,24.77,24.77,0,0,1-5,.06,24.51,24.51,0,0,1-7-1.69,18.1,18.1,0,0,1-1.78-.83,2.61,2.61,0,0,1-.59-.35,6.07,6.07,0,0,1,.63.26c.41.18,1,.44,1.81.73a25.91,25.91,0,0,0,7,1.51,25,25,0,0,0,4.92-.12,15.06,15.06,0,0,0,5.37-1.55,7.83,7.83,0,0,0,2.3-1.88,6.32,6.32,0,0,0,1.27-2.78,4.49,4.49,0,0,0-.48-3.05,3.83,3.83,0,0,0-2.58-1.76,3.06,3.06,0,0,0-2.91.82,3.5,3.5,0,0,0-.76,2.91,5.42,5.42,0,0,0,1.34,2.72,11.22,11.22,0,0,0,2.29,2,17.59,17.59,0,0,0,9.94,3,18.39,18.39,0,0,0,7-1.34C149.64,329.66,150.42,329.13,150.45,329.18Z"
          style="
            fill: rgb(250, 250, 250);
            transform-origin: 130.735px 325.838px;
          "
          id="elblfm5piyemo"
          class="animable"
        ></path>
        <path
          d="M145.23,418.07l-.28-.19-.8-.58a27.06,27.06,0,0,0-3.27-1.95,29.09,29.09,0,0,0-13.5-3c-5.89,0-12.86,2-19.61,5.88a28,28,0,0,0-4.84,3.46,16.57,16.57,0,0,0-3.82,4.8,9.5,9.5,0,0,0-.91,6.13,5.41,5.41,0,0,0,1.54,2.72,3,3,0,0,0,2.88.71,4.09,4.09,0,0,0,2.31-2,6.94,6.94,0,0,0,.83-3.06,10.7,10.7,0,0,0-1.9-6,14.62,14.62,0,0,0-4.43-4.23,17.16,17.16,0,0,0-5.49-2.15,31.71,31.71,0,0,0-11-.11,65.76,65.76,0,0,0-9.43,2.06A67.68,67.68,0,0,0,60.62,426c-1.45.79-2.55,1.45-3.3,1.9l-.84.52-.3.16s.08-.08.27-.21l.82-.56c.72-.48,1.82-1.16,3.26-2a65,65,0,0,1,12.89-5.52,63.6,63.6,0,0,1,9.48-2.13,31.62,31.62,0,0,1,11.14.06,17.38,17.38,0,0,1,5.65,2.2,15,15,0,0,1,4.59,4.36,11.14,11.14,0,0,1,2,6.25,7.41,7.41,0,0,1-.9,3.3,4.6,4.6,0,0,1-2.62,2.29,3.52,3.52,0,0,1-3.37-.82,6,6,0,0,1-1.69-3,9.94,9.94,0,0,1,1-6.48,17,17,0,0,1,3.94-4.95,29.31,29.31,0,0,1,4.94-3.51c6.82-3.92,13.88-5.83,19.85-5.83a28.82,28.82,0,0,1,13.6,3.16A19.31,19.31,0,0,1,145.23,418.07Z"
          style="fill: rgb(250, 250, 250); transform-origin: 100.705px 424.39px"
          id="el6as27ug69o9"
          class="animable"
        ></path>
        <path
          d="M74.78,338.2c-3-15.37-24.75-15.94-28.56-.75-.06.23-.12.47-.17.71-5.61,23.93,13.34,94.64,18.43,98.72s10.14,8.7,18.84,5.09c5.09-2.11,57.94-48.82,57.94-48.82s12.22,11.71,16.3,10.69,11.86-13,11.86-13,7.56,11.33,8.51,11.5a2.84,2.84,0,0,0,3-4.07c-1-1.53-10.64-22.6-10.64-22.6s7.59-.82,6.06-3.37c0,0-.19-2.34-5.32-1.89S166,365,156.45,365.56s-29.27,3-57,24.28L86.6,398.9Z"
          style="
            fill: rgb(255, 190, 157);
            transform-origin: 113.125px 384.816px;
          "
          id="el2mcbsckcgpg"
          class="animable"
        ></path>
        <path
          d="M99.42,389.84l.32-.26,1-.73,3.75-2.81a148.79,148.79,0,0,1,14.86-9.36,98,98,0,0,1,25-9.66c2.52-.53,5.13-1,7.84-1.31,1.36-.15,2.73-.27,4.13-.37a18.06,18.06,0,0,1,4.27.19,11.22,11.22,0,0,1,4.09,1.59c1.27.77,2.34,1.84,3.62,2.5a4.73,4.73,0,0,0,2,.53c.71,0,1.49-.09,2.27-.06a7,7,0,0,1,2.33.4,2.47,2.47,0,0,1,1.72,1.76l0-.1a1.47,1.47,0,0,1,.2,1.08,1.76,1.76,0,0,1-.63.89,6.12,6.12,0,0,1-1.82.92,21.06,21.06,0,0,1-4,.85l.21-.35,6.78,14.67c1.19,2.5,2.37,5.07,3.66,7.56a4.11,4.11,0,0,1,.49,1,3.23,3.23,0,0,1,0,1.16,3.31,3.31,0,0,1-1.14,2,2.91,2.91,0,0,1-2.19.67l-.29,0a.74.74,0,0,1-.21-.07l-.14-.1a4.11,4.11,0,0,1-.42-.41c-.25-.27-.49-.55-.71-.84-2.61-3.34-4.92-6.8-7.22-10.2h.43a87.19,87.19,0,0,1-5.88,7.94,32,32,0,0,1-3.37,3.51c-.31.26-.64.5-.95.76a11.37,11.37,0,0,1-1.06.63,3.09,3.09,0,0,1-2.47.2,16.09,16.09,0,0,1-4.26-2.09c-1.31-.85-2.55-1.76-3.76-2.7-2.4-1.89-4.65-3.86-6.79-5.88h.32c-16.76,14.74-31.11,27.12-41.37,35.7-5.13,4.29-9.24,7.62-12.12,9.82-1.44,1.1-2.57,1.92-3.36,2.43a6.61,6.61,0,0,1-1.25.69s.44-.22,1.21-.76,1.89-1.36,3.31-2.48c2.84-2.23,6.92-5.6,12-9.92,10.21-8.64,24.52-21.07,41.25-35.84l.16-.14.16.15c2.12,2,4.38,4,6.77,5.83,1.19.93,2.43,1.84,3.72,2.67a15.55,15.55,0,0,0,4.11,2c1.5.56,2.84-.5,4-1.49a32.81,32.81,0,0,0,3.31-3.45,87.46,87.46,0,0,0,5.84-7.89l.21-.33.22.32c2.29,3.39,4.62,6.86,7.19,10.17q.34.42.69.81c.12.12.24.25.36.35l.08.06h0s0,0,0,.09,0,.16,0-.08h0l.24,0a2.39,2.39,0,0,0,1.82-.55,2.56,2.56,0,0,0,.5-3.51c-1.33-2.55-2.49-5.09-3.68-7.6l-6.77-14.69-.14-.31.34,0a20.68,20.68,0,0,0,3.89-.83,5.54,5.54,0,0,0,1.67-.82,1.3,1.3,0,0,0,.46-.63,1,1,0,0,0-.14-.71l0,0,0-.07a2.06,2.06,0,0,0-1.41-1.35,6.48,6.48,0,0,0-2.16-.36c-.75,0-1.47.07-2.27.06a5.35,5.35,0,0,1-2.26-.6c-1.38-.71-2.43-1.78-3.65-2.53a11,11,0,0,0-3.92-1.53c-2.77-.5-5.58-.07-8.27.17s-5.29.76-7.8,1.28a98.62,98.62,0,0,0-25,9.52,158.64,158.64,0,0,0-14.91,9.22l-3.78,2.75-1,.7Z"
          style="fill: rgb(235, 153, 110); transform-origin: 132.431px 403.64px"
          id="elxeq6fc73va"
          class="animable"
        ></path>
        <path
          d="M81.85,416.1s-.11-.26-.21-.74a13.55,13.55,0,0,1-.25-2.09,21.3,21.3,0,0,1,3.67-13.18,15.42,15.42,0,0,1,1.3-1.66c.34-.36.54-.55.56-.53s-.63.9-1.55,2.39a24.15,24.15,0,0,0-3.62,13C81.77,415,81.94,416.09,81.85,416.1Z"
          style="
            fill: rgb(235, 153, 110);
            transform-origin: 84.1389px 406.999px;
          "
          id="el6gzz586mmk5"
          class="animable"
        ></path>
        <path
          d="M60,311.61c-.49.33-18.09,5.59-20.86,23.63S38,384.7,38,384.7l47.74-3.28L72.05,318Z"
          style="fill: rgb(255, 114, 94); transform-origin: 61.6318px 348.155px"
          id="el39s8c1vsra4"
          class="animable"
        ></path>
        <path
          d="M161.44,401.35c-.08,0,.05-.65,0-1.64a8.63,8.63,0,0,0-1-3.71,8.79,8.79,0,0,0-2.51-2.92c-.8-.58-1.39-.82-1.36-.91a3.36,3.36,0,0,1,1.57.61,7.79,7.79,0,0,1,2.76,3,7.64,7.64,0,0,1,.89,4A3.37,3.37,0,0,1,161.44,401.35Z"
          style="fill: rgb(235, 153, 110); transform-origin: 159.185px 396.76px"
          id="ellq9es5157fo"
          class="animable"
        ></path>
        <path
          d="M165.83,396a11.07,11.07,0,0,0-4.88-7.64,2.84,2.84,0,0,1,1.29.58,8.19,8.19,0,0,1,2.4,2.44,8.1,8.1,0,0,1,1.21,3.21A2.75,2.75,0,0,1,165.83,396Z"
          style="fill: rgb(235, 153, 110); transform-origin: 163.441px 392.18px"
          id="elazwfnhzmg"
          class="animable"
        ></path>
        <path
          d="M165.83,384.6c0-.05.45.15,1,.64a7,7,0,0,1,2.26,5.12c0,.73-.13,1.17-.19,1.16a16,16,0,0,0-.89-3.75A16.51,16.51,0,0,0,165.83,384.6Z"
          style="fill: rgb(235, 153, 110); transform-origin: 167.46px 388.056px"
          id="elq7qtsadaias"
          class="animable"
        ></path>
        <path
          d="M79.92,351.47a.67.67,0,0,1-.25,0l-.72-.08a25.43,25.43,0,0,0-2.78-.19,26.48,26.48,0,0,0-10,1.85,25.32,25.32,0,0,0-11.67,9.26A13.67,13.67,0,0,0,52,370.6,9.22,9.22,0,0,0,53.34,375,6.77,6.77,0,0,0,57,377.84a6.39,6.39,0,0,0,4.6-.22,5.23,5.23,0,0,0,2.8-3.38,8.46,8.46,0,0,0-.18-4.41,12.59,12.59,0,0,0-1.9-3.85,19.08,19.08,0,0,0-12.65-7.48,19.37,19.37,0,0,0-10,1.34,22.41,22.41,0,0,0-2.49,1.25l-.62.38-.22.11a14.27,14.27,0,0,1,3.25-1.92,19.06,19.06,0,0,1,10.12-1.52,19.33,19.33,0,0,1,13,7.55,12.84,12.84,0,0,1,2,4,8.92,8.92,0,0,1,.19,4.68,5.73,5.73,0,0,1-3.07,3.72,7,7,0,0,1-5,.25,7.32,7.32,0,0,1-3.91-3.1,9.66,9.66,0,0,1-1.46-4.61,14.16,14.16,0,0,1,2.67-8.6,25.48,25.48,0,0,1,11.94-9.33A25.83,25.83,0,0,1,76.18,351,19,19,0,0,1,79.92,351.47Z"
          style="fill: rgb(250, 250, 250); transform-origin: 58.13px 364.836px"
          id="elus8te0t9s8"
          class="animable"
        ></path>
        <path
          d="M78.29,325.57a6.31,6.31,0,0,1-1.21.56,11.15,11.15,0,0,1-1.48.53,16.81,16.81,0,0,1-2,.56,21.75,21.75,0,0,1-2.47.47c-.9.15-1.86.2-2.88.27a33.25,33.25,0,0,1-6.6-.45,32.56,32.56,0,0,1-6.34-1.88c-.93-.42-1.82-.8-2.6-1.25a22,22,0,0,1-2.16-1.3,19,19,0,0,1-1.68-1.22,11.41,11.41,0,0,1-1.19-1,6.38,6.38,0,0,1-.94-.94c.07-.09,1.44,1.24,4,2.86A22.2,22.2,0,0,0,52.92,324a28.31,28.31,0,0,0,2.58,1.2,31.33,31.33,0,0,0,12.74,2.29c1-.06,2-.09,2.84-.22s1.7-.25,2.45-.4C76.5,326.24,78.25,325.47,78.29,325.57Z"
          style="fill: rgb(250, 250, 250); transform-origin: 62.515px 323.946px"
          id="elp7x8wiff9n"
          class="animable"
        ></path>
        <path
          d="M82.23,356.16a2.7,2.7,0,0,1,.11.48c.06.36.14.82.23,1.39.2,1.28.47,3,.8,5.23.65,4.53,1.56,10.82,2.61,18.12l0,.27h-.26l-11.16.78-25.88,1.7-7.84.46-2.14.1H38a3.2,3.2,0,0,1,.74-.11l2.12-.19,7.84-.62,25.87-1.86,11.15-.75-.23.28c-1-7.31-1.82-13.61-2.42-18.14-.28-2.2-.5-4-.66-5.25-.07-.58-.12-1-.16-1.41A1.67,1.67,0,0,1,82.23,356.16Z"
          style="fill: rgb(38, 50, 56); transform-origin: 61.99px 370.425px"
          id="elgy01gwx5bw8"
          class="animable"
        ></path>
        <g id="elj7vsebjxaa9">
          <g
            style="opacity: 0.2; transform-origin: 81.7983px 330.614px"
            class="animable"
          >
            <path
              d="M59.08,311.11c-.48,1.69.86,3.27,1.73,4.79,1.68,2.92,1.77,6.45,1.92,9.82A68.46,68.46,0,0,0,65.56,344c1.92,5.86,5.22,11.41,10.1,15.19s11.43,5.63,17.4,4.08,11-6.85,11.51-13-3.2-11.63-5.32-17.37c-1.64-4.42-2.37-9.12-3.31-13.75s-2.14-9.28-4.59-13.31-6.34-7.4-11-8.25-9.94,1.35-11.82,5.67"
              id="elzfczu8thpi8"
              class="animable"
              style="transform-origin: 81.7983px 330.614px"
            ></path>
          </g>
        </g>
        <path
          d="M82.14,230.37v5.31l-.35,79.61c0,9.24,7.88,16.64,17.77,16.61h0c10,0,18.25-7.62,18.44-17,.21-9.8.6-20.11.6-20.11s12.66-1.87,15.57-18.3c1.45-8.16,1.27-21.6.78-32.59a17.39,17.39,0,0,0-18.9-16.55Z"
          style="
            fill: rgb(255, 190, 157);
            transform-origin: 108.548px 279.591px;
          "
          id="el1mh3rosiqhc"
          class="animable"
        ></path>
        <path
          d="M131.09,254a2.12,2.12,0,0,1-2.08,2.1,2,2,0,0,1-2.14-1.95,2.11,2.11,0,0,1,2.07-2.09A2,2,0,0,1,131.09,254Z"
          style="fill: rgb(38, 50, 56); transform-origin: 128.98px 254.08px"
          id="eluu7wvg4j1yg"
          class="animable"
        ></path>
        <path
          d="M131.92,250.88c-.27.27-1.84-.95-4.12-1s-4,1.07-4.2.79c-.12-.13.16-.62.89-1.13a5.79,5.79,0,0,1,3.37-1,5.62,5.62,0,0,1,3.27,1.12C131.82,250.25,132.05,250.75,131.92,250.88Z"
          style="fill: rgb(38, 50, 56); transform-origin: 127.764px 249.729px"
          id="elxq6d1nhfm8g"
          class="animable"
        ></path>
        <path
          d="M109.56,253.58a2.11,2.11,0,0,1-2.08,2.09,2,2,0,0,1-2.14-1.94,2.12,2.12,0,0,1,2.07-2.1A2,2,0,0,1,109.56,253.58Z"
          style="fill: rgb(38, 50, 56); transform-origin: 107.45px 253.65px"
          id="el84bv478l4oo"
          class="animable"
        ></path>
        <path
          d="M109.57,250.9c-.27.27-1.85-.94-4.12-1s-4,1.07-4.2.79c-.12-.13.16-.62.89-1.14a5.87,5.87,0,0,1,3.37-1,5.54,5.54,0,0,1,3.28,1.12C109.47,250.28,109.7,250.78,109.57,250.9Z"
          style="fill: rgb(38, 50, 56); transform-origin: 105.414px 249.744px"
          id="el803mwtn49wh"
          class="animable"
        ></path>
        <path
          d="M117.52,267.91a15.13,15.13,0,0,1,3.7-.61c.59,0,1.14-.15,1.24-.54a3,3,0,0,0-.35-1.73c-.53-1.42-1.07-2.9-1.64-4.45-2.27-6.34-3.91-11.54-3.65-11.63s2.3,5,4.57,11.31l1.57,4.47a3.38,3.38,0,0,1,.26,2.29,1.47,1.47,0,0,1-1,.83,4.74,4.74,0,0,1-1,.12A15.63,15.63,0,0,1,117.52,267.91Z"
          style="fill: rgb(38, 50, 56); transform-origin: 120.053px 258.5px"
          id="el1vz76a91by3"
          class="animable"
        ></path>
        <path
          d="M118.61,294.83a41.85,41.85,0,0,1-22-6.28s5.15,11.41,21.66,10.16Z"
          style="fill: rgb(235, 153, 110); transform-origin: 107.61px 293.678px"
          id="el79nsvrklape"
          class="animable"
        ></path>
        <path
          d="M116.46,273.37a4.08,4.08,0,0,0-3.64-1.59,3.75,3.75,0,0,0-2.61,1.34,2.37,2.37,0,0,0-.23,2.7,2.74,2.74,0,0,0,3,.81,8.74,8.74,0,0,0,2.94-1.68,2.59,2.59,0,0,0,.66-.66.79.79,0,0,0,0-.85"
          style="
            fill: rgb(235, 153, 110);
            transform-origin: 113.183px 274.274px;
          "
          id="el1zuigk0j04j"
          class="animable"
        ></path>
        <path
          d="M110.8,269.36c.37,0,.33,2.45,2.42,4.24s4.71,1.56,4.72,1.91c0,.15-.59.47-1.7.48a6.11,6.11,0,0,1-3.95-1.46,5.3,5.3,0,0,1-1.86-3.55C110.35,270,110.64,269.34,110.8,269.36Z"
          style="fill: rgb(38, 50, 56); transform-origin: 114.178px 272.675px"
          id="elocs0q3dcze8"
          class="animable"
        ></path>
        <path
          d="M110.32,242c-.24.61-2.5.28-5.17.55s-4.85,1-5.19.4c-.15-.27.23-.84,1.11-1.42a8.55,8.55,0,0,1,3.86-1.28,8.74,8.74,0,0,1,4,.55C109.93,241.22,110.42,241.71,110.32,242Z"
          style="fill: rgb(38, 50, 56); transform-origin: 105.13px 241.695px"
          id="eljsz30g7gqao"
          class="animable"
        ></path>
        <path
          d="M131.2,244.28c-.41.51-2-.05-3.85-.11s-3.48.28-3.84-.26c-.15-.27.11-.78.81-1.26a5.56,5.56,0,0,1,6.2.31C131.17,243.5,131.38,244,131.2,244.28Z"
          style="fill: rgb(38, 50, 56); transform-origin: 127.365px 243.172px"
          id="elj2z03hji6yn"
          class="animable"
        ></path>
        <path
          d="M102.79,225c4.46,5.81,0,14.13-5.06,19.47s-11.13,11.52-9.68,18.69c1.69,8.32,13.14,13.55,11.53,21.88-.59,3-3,5.65-2.94,8.75,0,5.59,7.3,8.24,9.43,13.4,2.92,7.09-4.85,14.26-4.9,21.92,0,4.35,2.48,8.38,2.66,12.73.35,8.91-10.12,15.53-18.78,13.43S70.38,344.73,68.42,336s-.67-17.76.64-26.57c-.83,3.76-6.53,4.52-9.34,1.89s-3.19-7-2.6-10.79c1.48-9.55,7.66-17.68,10.55-26.89,2.53-8.06,2.46-16.66,3.52-25s3.56-17.12,9.9-22.7,17.38-6.37,22.61.26"
          style="fill: rgb(38, 50, 56); transform-origin: 81.8041px 288.575px"
          id="elpxymjduh4z"
          class="animable"
        ></path>
        <path
          d="M80.21,352a3.71,3.71,0,0,1,.21-.33c.15-.23.36-.55.63-.94a21.12,21.12,0,0,0,3.24-10.58,20.4,20.4,0,0,0-1.62-8.83c-1.31-3.1-3.62-6-6.06-9.16a46.46,46.46,0,0,1-3.53-5.08A20.07,20.07,0,0,1,70.74,311a12.1,12.1,0,0,1,.56-6.89,9.78,9.78,0,0,1,2-3,22.38,22.38,0,0,1,2.83-2.32c2-1.42,4-2.85,5.13-5.07a10.65,10.65,0,0,0,.46-7.29c-1.2-5-4.68-8.9-6.91-13.25a16.72,16.72,0,0,1-2.07-6.91,15.13,15.13,0,0,1,1.29-6.8,29.54,29.54,0,0,1,7.75-10c3-2.67,5.9-4.94,8.31-7.3a26.27,26.27,0,0,0,5.48-7.17,17.48,17.48,0,0,0,1.88-6.54,12.12,12.12,0,0,0-.43-4.28c-.28-1-.52-1.42-.48-1.44a7.9,7.9,0,0,1,.58,1.41,11.65,11.65,0,0,1,.53,4.32,17.07,17.07,0,0,1-1.82,6.65,26.46,26.46,0,0,1-5.48,7.31c-2.41,2.4-5.34,4.69-8.28,7.36a29.42,29.42,0,0,0-7.6,9.92,14.8,14.8,0,0,0-1.24,6.58,16.42,16.42,0,0,0,2,6.7c1.12,2.14,2.51,4.19,3.83,6.34a24.6,24.6,0,0,1,3.14,7,11.18,11.18,0,0,1-.5,7.65c-1.17,2.37-3.36,3.85-5.3,5.25a22,22,0,0,0-2.77,2.26,9.52,9.52,0,0,0-1.94,2.83,11.7,11.7,0,0,0-.54,6.61,20.08,20.08,0,0,0,2.26,6,46.76,46.76,0,0,0,3.49,5c2.41,3.14,4.73,6.11,6.05,9.28a20.47,20.47,0,0,1,1.57,9,20.65,20.65,0,0,1-3.44,10.62c-.29.41-.53.7-.68.91A2,2,0,0,1,80.21,352Z"
          style="fill: rgb(69, 90, 100); transform-origin: 84.0765px 287.37px"
          id="elwhp8r684ygp"
          class="animable"
        ></path>
        <path
          d="M202.84,370.9l-37.9,66.18-53.67,1.86v5.9H268.06l42.67-69.9a4,4,0,0,0-3.39-6H206.28A4,4,0,0,0,202.84,370.9Z"
          style="fill: rgb(38, 50, 56); transform-origin: 211.268px 406.89px"
          id="elo520pr09lfn"
          class="animable"
        ></path>
        <path
          d="M250.31,406.23c-2.38,3.7-6,5.59-8.18,4.21s-1.95-5.49.43-9.19,6-5.59,8.18-4.21S252.69,402.53,250.31,406.23Z"
          style="fill: rgb(224, 224, 224); transform-origin: 246.435px 403.74px"
          id="elhlni2ew3r5p"
          class="animable"
        ></path>
      </g>
      <g
        id="freepik--Plant--inject-23"
        class="animable"
        style="transform-origin: 431.358px 326.9px"
      >
        <path
          d="M439.4,415a3,3,0,0,0-.22-.51l-.69-1.48a37.91,37.91,0,0,1-2-5.91c-1.34-5.26-1.81-13.3.18-22.84a121.4,121.4,0,0,1,4.23-15.34c1.8-5.45,3.88-11.23,5.58-17.47a67.51,67.51,0,0,0,2.75-20.08c-.25-7.1-2.36-14.19-4.67-21.25s-4.69-14-5.41-20.93A31.9,31.9,0,0,1,443,269.85c3.06-5.64,6.91-10.46,9.79-15.46a53.7,53.7,0,0,0,5.89-14.86,55.23,55.23,0,0,0,.37-23,42.44,42.44,0,0,0-2.4-8.14,3.78,3.78,0,0,0,.14.54c.12.39.28.91.49,1.56a60.44,60.44,0,0,1,1.49,6.09,55.88,55.88,0,0,1-.6,22.85,53.69,53.69,0,0,1-5.9,14.67c-2.88,4.94-6.75,9.74-9.87,15.45a32.54,32.54,0,0,0-4,19.72c.72,7.07,3.12,14,5.42,21.09s4.39,14.06,4.66,21.05a67.45,67.45,0,0,1-2.69,19.87c-1.67,6.22-3.73,12-5.5,17.46a121.27,121.27,0,0,0-4.17,15.43c-1.94,9.61-1.39,17.73.06,23a33.64,33.64,0,0,0,2.18,5.9l.76,1.45A2.33,2.33,0,0,0,439.4,415Z"
          style="fill: rgb(38, 50, 56); transform-origin: 447.476px 311.695px"
          id="ellygrpd86ysf"
          class="animable"
        ></path>
        <path
          d="M448.83,259.32s5.85-18.71-4.65-29.67a.64.64,0,0,0-1.11.32C442.51,233.06,441.81,242.47,448.83,259.32Z"
          style="fill: rgb(255, 114, 94); transform-origin: 446.597px 244.377px"
          id="el609ia0z5hxh"
          class="animable"
        ></path>
        <path
          d="M445.32,235.88a17.2,17.2,0,0,0,.4,3.46c.38,2.37.83,5.17,1.33,8.25s.93,5.88,1.31,8.26a18.35,18.35,0,0,0,.62,3.41,14,14,0,0,0-.1-3.48c-.2-2.14-.57-5.08-1.11-8.31s-1.09-6.13-1.54-8.23A15.68,15.68,0,0,0,445.32,235.88Z"
          style="fill: rgb(38, 50, 56); transform-origin: 447.182px 247.57px"
          id="eluxh02v38nl"
          class="animable"
        ></path>
        <path
          d="M442,303.46s-3.58-19.28-18-24a.64.64,0,0,0-.83.8C424.09,283.2,427.87,291.85,442,303.46Z"
          style="fill: rgb(255, 114, 94); transform-origin: 432.569px 291.44px"
          id="el1r95ghyj4mf"
          class="animable"
        ></path>
        <path
          d="M427.89,284.39a17.78,17.78,0,0,0,2,2.86c1.45,1.93,3.15,4.18,5,6.67l5,6.69a19.1,19.1,0,0,0,2.15,2.73,14.35,14.35,0,0,0-1.72-3c-1.17-1.8-2.88-4.22-4.86-6.83s-3.83-4.91-5.22-6.55A14.72,14.72,0,0,0,427.89,284.39Z"
          style="fill: rgb(38, 50, 56); transform-origin: 434.965px 293.865px"
          id="eldjetluv9tf4"
          class="animable"
        ></path>
        <path
          d="M445.85,351.83s2.17-19.49-10.23-28.24a.65.65,0,0,0-1,.52C434.63,327.26,435.74,336.63,445.85,351.83Z"
          style="fill: rgb(255, 114, 94); transform-origin: 440.308px 337.659px"
          id="elwldmgx2u91"
          class="animable"
        ></path>
        <path
          d="M437.93,329.49A17.11,17.11,0,0,0,439,332.8c.83,2.27,1.8,4.92,2.88,7.85s2,5.59,2.86,7.86a19.34,19.34,0,0,0,1.26,3.23,14,14,0,0,0-.76-3.39c-.6-2.07-1.53-4.88-2.67-8s-2.24-5.82-3.09-7.79A15.4,15.4,0,0,0,437.93,329.49Z"
          style="fill: rgb(38, 50, 56); transform-origin: 441.965px 340.615px"
          id="elwaar1hv7xtr"
          class="animable"
        ></path>
        <path
          d="M435,400.48s1.48-19.56-11.22-27.86a.65.65,0,0,0-1,.56C422.91,376.32,424.35,385.65,435,400.48Z"
          style="fill: rgb(255, 114, 94); transform-origin: 428.916px 386.499px"
          id="eld16jw2l4ukn"
          class="animable"
        ></path>
        <path
          d="M426.29,378.44a16.13,16.13,0,0,0,1.16,3.27l3.16,7.74c1.18,2.9,2.24,5.52,3.14,7.75a19.38,19.38,0,0,0,1.38,3.19,14.47,14.47,0,0,0-.89-3.37c-.67-2-1.7-4.82-2.95-7.85s-2.45-5.73-3.36-7.66A15.9,15.9,0,0,0,426.29,378.44Z"
          style="fill: rgb(38, 50, 56); transform-origin: 430.71px 389.415px"
          id="elw54pvud5ief"
          class="animable"
        ></path>
        <path
          d="M457.24,242.88s17.38-9.08,17.72-24.25a.65.65,0,0,0-1-.56C471.36,219.85,464.21,226,457.24,242.88Z"
          style="fill: rgb(255, 114, 94); transform-origin: 466.1px 230.424px"
          id="elk795owmghja"
          class="animable"
        ></path>
        <path
          d="M471.35,223.84a16.8,16.8,0,0,0-2.16,2.72c-1.42,1.95-3.07,4.24-4.9,6.77s-3.5,4.82-4.92,6.76a19,19,0,0,0-2,2.85,13.92,13.92,0,0,0,2.39-2.53c1.37-1.65,3.19-4,5.1-6.65s3.57-5.1,4.73-6.91A15.09,15.09,0,0,0,471.35,223.84Z"
          style="fill: rgb(38, 50, 56); transform-origin: 464.36px 233.39px"
          id="elwy4wb5m34in"
          class="animable"
        ></path>
        <path
          d="M438.57,283.07s17.9-8,19.17-23.13a.65.65,0,0,0-1-.62C454.07,261,446.55,266.66,438.57,283.07Z"
          style="fill: rgb(255, 114, 94); transform-origin: 448.157px 271.142px"
          id="eldmwkrum8mfg"
          class="animable"
        ></path>
        <path
          d="M453.82,264.93a16.27,16.27,0,0,0-2.33,2.58l-5.3,6.46-5.32,6.45a18.33,18.33,0,0,0-2.15,2.73,15,15,0,0,0,2.54-2.39c1.47-1.56,3.43-3.79,5.5-6.32s3.87-4.88,5.14-6.61A15.64,15.64,0,0,0,453.82,264.93Z"
          style="fill: rgb(38, 50, 56); transform-origin: 446.27px 274.04px"
          id="elksxzkm44z6"
          class="animable"
        ></path>
        <path
          d="M448.87,327.9s12.29-15.28,6.53-29.31a.65.65,0,0,0-1.15-.11C452.6,301.15,448.5,309.66,448.87,327.9Z"
          style="fill: rgb(255, 114, 94); transform-origin: 452.891px 313.041px"
          id="elbgnpx76h3cf"
          class="animable"
        ></path>
        <path
          d="M454.18,304.81a17.81,17.81,0,0,0-.9,3.36c-.51,2.35-1.11,5.11-1.77,8.17s-1.28,5.81-1.8,8.16a19.44,19.44,0,0,0-.67,3.4,14.19,14.19,0,0,0,1.17-3.27c.6-2.06,1.33-4.94,2-8.14s1.22-6.11,1.56-8.23A15.72,15.72,0,0,0,454.18,304.81Z"
          style="fill: rgb(38, 50, 56); transform-origin: 451.61px 316.355px"
          id="elaqv073gaakp"
          class="animable"
        ></path>
        <path
          d="M436.7,380.66s17.76-8.3,18.77-23.44a.65.65,0,0,0-1-.61C451.82,358.28,444.4,364.12,436.7,380.66Z"
          style="fill: rgb(255, 114, 94); transform-origin: 446.087px 368.583px"
          id="eldomxzz6z86d"
          class="animable"
        ></path>
        <path
          d="M451.64,362.26a17.46,17.46,0,0,0-2.29,2.62l-5.19,6.56L439,378a18.65,18.65,0,0,0-2.1,2.77,14.7,14.7,0,0,0,2.49-2.43c1.45-1.58,3.37-3.85,5.4-6.42s3.79-4.94,5-6.69A16.17,16.17,0,0,0,451.64,362.26Z"
          style="fill: rgb(38, 50, 56); transform-origin: 444.27px 371.515px"
          id="els1sdf33cpo"
          class="animable"
        ></path>
        <path
          d="M401.32,303.57s0,.14,0,.43,0,.75,0,1.25c.05,1.18.12,2.77.21,4.76.22,4.18.54,10,.93,17h0v0c1.27,7.09,4.88,15,9.74,22.59,3.56,5.63,7.29,10.74,10.51,15.5a109.49,109.49,0,0,1,7.88,13,54.59,54.59,0,0,1,3.69,9.56c.31,1.17.54,2.08.68,2.71a4.91,4.91,0,0,0,.26.94,5.8,5.8,0,0,0-.12-1c-.08-.64-.27-1.57-.55-2.76a50.87,50.87,0,0,0-3.49-9.71,103.83,103.83,0,0,0-7.79-13.18c-3.2-4.79-6.91-9.91-10.45-15.51-4.83-7.6-8.43-15.34-9.74-22.32v0c-.49-7-.89-12.79-1.19-17-.16-2-.29-3.56-.38-4.74,0-.5-.09-.91-.13-1.25A1.75,1.75,0,0,0,401.32,303.57Z"
          style="fill: rgb(38, 50, 56); transform-origin: 418.27px 347.44px"
          id="el2gerrwiguq"
          class="animable"
        ></path>
        <path
          d="M411.89,348.16s-7.52-18.1,1.94-30a.65.65,0,0,1,1.14.22C415.81,321.44,417.36,330.75,411.89,348.16Z"
          style="fill: rgb(255, 114, 94); transform-origin: 412.445px 333.034px"
          id="elq15mr6h8si"
          class="animable"
        ></path>
        <path
          d="M413.26,324.5a15.57,15.57,0,0,0-.59,3.43c-.27,2.13-.57,5.08-.8,8.34s-.34,6.23-.34,8.37a14,14,0,0,0,.21,3.48,19.4,19.4,0,0,0,.3-3.46c.16-2.4.35-5.22.56-8.34s.42-5.94.58-8.34A16.75,16.75,0,0,0,413.26,324.5Z"
          style="fill: rgb(38, 50, 56); transform-origin: 412.408px 336.31px"
          id="eln8jtmbtn84"
          class="animable"
        ></path>
        <g id="el36cy1ncq1a6">
          <g
            style="opacity: 0.3; transform-origin: 412.445px 333.034px"
            class="animable"
          >
            <path
              d="M411.89,348.16s-7.52-18.1,1.94-30a.65.65,0,0,1,1.14.22C415.81,321.44,417.36,330.75,411.89,348.16Z"
              id="elk7r88zd05th"
              class="animable"
              style="transform-origin: 412.445px 333.034px"
            ></path>
          </g>
        </g>
        <path
          d="M435.2,391.8s-6-18.66,4.36-29.71a.65.65,0,0,1,1.12.31C441.27,365.48,442.05,374.89,435.2,391.8Z"
          style="fill: rgb(255, 114, 94); transform-origin: 437.262px 376.838px"
          id="eld3l2hdzpfij"
          class="animable"
        ></path>
        <path
          d="M438.48,368.33a15.19,15.19,0,0,0-.87,3.37c-.44,2.1-1,5-1.47,8.24s-.85,6.18-1,8.32a14.68,14.68,0,0,0-.07,3.48,18.14,18.14,0,0,0,.59-3.42c.35-2.38.77-5.18,1.23-8.26s.9-5.89,1.26-8.27A17.68,17.68,0,0,0,438.48,368.33Z"
          style="fill: rgb(38, 50, 56); transform-origin: 436.739px 380.035px"
          id="elh62msc06wjn"
          class="animable"
        ></path>
        <g id="elrf7o7rlg2ei">
          <g
            style="opacity: 0.3; transform-origin: 437.262px 376.838px"
            class="animable"
          >
            <path
              d="M435.2,391.8s-6-18.66,4.36-29.71a.65.65,0,0,1,1.12.31C441.27,365.48,442.05,374.89,435.2,391.8Z"
              id="elikwzmdyg4o"
              class="animable"
              style="transform-origin: 437.262px 376.838px"
            ></path>
          </g>
        </g>
        <path
          d="M402.31,326s-16.06-11.24-14.44-26.33a.65.65,0,0,1,1.08-.43C391.28,301.38,397.58,308.41,402.31,326Z"
          style="fill: rgb(255, 114, 94); transform-origin: 395.033px 312.538px"
          id="elf5ljcnlxv9u"
          class="animable"
        ></path>
        <g id="el9mf0wh4td37">
          <g
            style="opacity: 0.3; transform-origin: 395.033px 312.538px"
            class="animable"
          >
            <path
              d="M402.31,326s-16.06-11.24-14.44-26.33a.65.65,0,0,1,1.08-.43C391.28,301.38,397.58,308.41,402.31,326Z"
              id="elr49t15o2m9m"
              class="animable"
              style="transform-origin: 395.033px 312.538px"
            ></path>
          </g>
        </g>
        <path
          d="M390.78,305.33a15,15,0,0,0,1.33,3.21c.92,1.94,2.25,4.59,3.8,7.47s3,5.44,4.2,7.25a14.57,14.57,0,0,0,2.05,2.82,18.86,18.86,0,0,0-1.6-3.08l-4-7.34-4-7.35A17.43,17.43,0,0,0,390.78,305.33Z"
          style="fill: rgb(38, 50, 56); transform-origin: 396.47px 315.705px"
          id="elwvro17h7oi"
          class="animable"
        ></path>
        <path
          d="M428.72,373.3s-19.38-3-24.57-17.28a.65.65,0,0,1,.78-.85C408,356,416.71,359.56,428.72,373.3Z"
          style="fill: rgb(255, 114, 94); transform-origin: 416.415px 364.224px"
          id="elfkqa3a15ci9"
          class="animable"
        ></path>
        <g id="elcccl2xpx1nh">
          <g
            style="opacity: 0.3; transform-origin: 416.415px 364.224px"
            class="animable"
          >
            <path
              d="M428.72,373.3s-19.38-3-24.57-17.28a.65.65,0,0,1,.78-.85C408,356,416.71,359.56,428.72,373.3Z"
              id="elhhv9y8xaxi"
              class="animable"
              style="transform-origin: 416.415px 364.224px"
            ></path>
          </g>
        </g>
        <path
          d="M409.24,359.8a15.67,15.67,0,0,0,2.62,2.29c1.67,1.34,4,3.13,6.7,5s5.13,3.54,7,4.66a13.77,13.77,0,0,0,3.08,1.63,19.83,19.83,0,0,0-2.79-2.06L419,366.53l-6.81-4.85A17.3,17.3,0,0,0,409.24,359.8Z"
          style="fill: rgb(38, 50, 56); transform-origin: 418.94px 366.59px"
          id="eluuv7knq0di8"
          class="animable"
        ></path>
        <polygon
          points="451.64 408.96 446.09 445.41 423.89 445.37 416.42 408.96 451.64 408.96"
          style="fill: rgb(69, 90, 100); transform-origin: 434.03px 427.185px"
          id="elf1d6hogvuji"
          class="animable"
        ></polygon>
        <path
          d="M419.67,423s.05.06.18.14.32.2.59.33a7.92,7.92,0,0,0,2.48.74,9.1,9.1,0,0,0,4.08-.38,8.58,8.58,0,0,0,4.39-3.19,6.2,6.2,0,0,0,1-3.05,3.33,3.33,0,0,0-1.51-3.13,3.62,3.62,0,0,0-3.72.3,5.42,5.42,0,0,0-2.12,3.35,14.15,14.15,0,0,0-.28,4.12,13.63,13.63,0,0,0,.9,4.21,9.32,9.32,0,0,0,2.57,3.67,7.13,7.13,0,0,0,4.27,1.78,7.74,7.74,0,0,0,4.45-1.29,13.35,13.35,0,0,0,3.31-3,7.29,7.29,0,0,0,1.68-4,4.64,4.64,0,0,0-1.58-3.86,3.61,3.61,0,0,0-3.93-.24,4.23,4.23,0,0,0-2.27,3,5.69,5.69,0,0,0,2.81,5.77,7.85,7.85,0,0,0,5.36.78,12.15,12.15,0,0,0,3.81-1.5,20.65,20.65,0,0,0,2.14-1.44,4,4,0,0,0,.69-.59,6.76,6.76,0,0,0-.76.49c-.49.32-1.21.81-2.18,1.34a12.25,12.25,0,0,1-3.76,1.36,7.49,7.49,0,0,1-5.08-.81,5.26,5.26,0,0,1-2.53-5.3,3.67,3.67,0,0,1,2-2.59,3,3,0,0,1,3.34.2,4.09,4.09,0,0,1,1.35,3.39,6.68,6.68,0,0,1-1.57,3.67,12.68,12.68,0,0,1-3.16,2.85,7.28,7.28,0,0,1-4.1,1.2,6.61,6.61,0,0,1-3.9-1.64,8.68,8.68,0,0,1-2.42-3.43,13.23,13.23,0,0,1-.87-4,14,14,0,0,1,.24-3.95,4.89,4.89,0,0,1,1.88-3,3.08,3.08,0,0,1,3.17-.31,2.84,2.84,0,0,1,1.29,2.67,5.83,5.83,0,0,1-.92,2.84,8.33,8.33,0,0,1-4.14,3.1A9.08,9.08,0,0,1,423,424,10.5,10.5,0,0,1,419.67,423Z"
          style="fill: rgb(38, 50, 56); transform-origin: 434.32px 422.987px"
          id="el8ev2m0rcfkm"
          class="animable"
        ></path>
      </g>
      <defs>
        <filter id="active" height="200%">
          <feMorphology
            in="SourceAlpha"
            result="DILATED"
            operator="dilate"
            radius="2"
          ></feMorphology>
          <feFlood
            flood-color="#32DFEC"
            flood-opacity="1"
            result="PINK"
          ></feFlood>
          <feComposite
            in="PINK"
            in2="DILATED"
            operator="in"
            result="OUTLINE"
          ></feComposite>
          <feMerge>
            <feMergeNode in="OUTLINE"></feMergeNode>
            <feMergeNode in="SourceGraphic"></feMergeNode>
          </feMerge>
        </filter>
        <filter id="hover" height="200%">
          <feMorphology
            in="SourceAlpha"
            result="DILATED"
            operator="dilate"
            radius="2"
          ></feMorphology>
          <feFlood
            flood-color="#ff0000"
            flood-opacity="0.5"
            result="PINK"
          ></feFlood>
          <feComposite
            in="PINK"
            in2="DILATED"
            operator="in"
            result="OUTLINE"
          ></feComposite>
          <feMerge>
            <feMergeNode in="OUTLINE"></feMergeNode>
            <feMergeNode in="SourceGraphic"></feMergeNode>
          </feMerge>
          <feColorMatrix
            type="matrix"
            values="0   0   0   0   0                0   1   0   0   0                0   0   0   0   0                0   0   0   1   0 "
          ></feColorMatrix>
        </filter>
      </defs>
    </svg>
  </main>
</template>



<style scoped>
svg#freepik_stories-inbox-cleanup:not(.animated) .animable {
  opacity: 0;
}
svg#freepik_stories-inbox-cleanup.animated
  #freepik--background-complete--inject-23 {
  animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) lightSpeedRight;
  animation-delay: 0s;
}
svg#freepik_stories-inbox-cleanup.animated #freepik--Floor--inject-23 {
  animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) slideRight;
  animation-delay: 0s;
}
svg#freepik_stories-inbox-cleanup.animated #freepik--Screen--inject-23 {
  animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) lightSpeedRight;
  animation-delay: 0s;
}
svg#freepik_stories-inbox-cleanup.animated #freepik--Character--inject-23 {
  animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) zoomOut;
  animation-delay: 0s;
}
svg#freepik_stories-inbox-cleanup.animated #freepik--Plant--inject-23 {
  animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) slideDown;
  animation-delay: 0s;
}
@keyframes lightSpeedRight {
  from {
    transform: translate3d(50%, 0, 0) skewX(-20deg);
    opacity: 0;
  }
  60% {
    transform: skewX(10deg);
    opacity: 1;
  }
  80% {
    transform: skewX(-2deg);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slideRight {
  0% {
    opacity: 0;
    transform: translateX(30px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes zoomOut {
  0% {
    opacity: 0;
    transform: scale(1.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>