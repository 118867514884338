<template>
  <main
    class="dropdown-wrapper"
    ref="dropdownWrapperRef"
    @mouseleave="searchResultsState = false"
  >
    <section class="dropdown-header">
      <div
        class="dropdown pointer"
        @click="getDistanceToBottom(), openDropdown()"
        ref="dropdownRef"
      >
        <p class="-xstr" v-if="!selectedItems.length">{{ placeholder }}</p>
        <div v-else class="selected-items-wrapper">
          <div class="selected-item" v-for="item in selectedItems" :key="item">
            <p class="-xstb">{{ item.name }}</p>
          </div>
        </div>

        <i
          class="i-arrow-open-down"
          :class="{ rotateIcon: searchResultsState }"
        ></i>
      </div>
    </section>

    <section
      :class="[
        dropdownOpenBottom ? 'search-wrapper-bottom' : 'search-wrapper-top',
      ]"
      v-show="searchResultsState"
      ref="searchWrapperRef"
    >
      <div class="search-input-wrapper">
        <input
          type="text"
          v-model="search"
          placeholder="Search..."
          autocomplete="off"
          class="-xstr search-input"
          :ref="searchInputRef"
          tabindex="0"
        />
      </div>

      <ul
        class="-xstr search-results"
        v-if="searchResultsState"
        ref="searchResultsRef"
        @scroll.native="handleScroll"
      >
        <li
          v-for="(item, index) in getItems"
          :key="item"
          @click="commitItem(item)"
          :ref="`search_item_ref${index}`"
          class="search-item"
          :class="{ selected: item.selected }"
        >
          <img :src="item.img" class="search-img" v-if="item.img" />
          <p class="-xstr">{{ item.name }}</p>
        </li>
      </ul>
    </section>
  </main>
</template>

<script>
export default {
  //imgs is an array of img urls, the setImgState is if the object should be built with an img or not, titles are strings,
  props: ["placeholder", "titles", "imgs", "setImgState"],
  emits: ["setItems"],
  data() {
    return {
      results: this.titles.map((item, index) => {
        return {
          name: item,
          img: this.imgs ? this.imgs[index] : null,
          selected: false,
        };
      }),
      search: "",
      searchResultsState: false,
      selectedItems: [],
      dropdownOpenBottom: false,
      //refs
      dropdownWrapperRef: "dropdownWrapperRef",
      dropdownRef: "dropdownRef",
      searchInputRef: "searchInputRef",
      searchWrapperRef: "searchWrapperRef",
      searchResultsRef: "searchResultsRef",
      // infinite scroll state
      itemsPerPage: 20,
    };
  },
  methods: {
    openDropdown() {
      this.searchResultsState = !this.searchResultsState;
      this.setSearchWrapperWidth();

      const searchInput = this.$refs[this.searchInputRef];
      searchInput.style.border = "1px solid red !important;";
      this.$nextTick(() => searchInput.focus());
    },
    commitItem(item) {
      item.selected = !item.selected;

      this.selectedItems = this.results.filter((item) => item.selected);
      this.$emit("setItems", this.selectedItems);
    },
    setSearchWrapperWidth() {
      const domEl = this.$refs[this.dropdownRef];
      this.$nextTick(() => {
        this.$refs[this.searchWrapperRef].style.width =
          domEl.offsetWidth + "px";
      });
    },
    getDistanceToBottom() {
      const domEl = this.$refs[this.dropdownWrapperRef];
      const maxDropdownResultsHeight = 265;
      this.$nextTick(() => {
        // Get the position of the element relative to the viewport
        //DOMRect object which is the smallest rectangle which contains the entire element, including its padding and border-width
        const rect = domEl.getBoundingClientRect();

        //the result is in pixels
        const distanceToBottom = window.innerHeight - rect.bottom;

        distanceToBottom > maxDropdownResultsHeight
          ? (this.dropdownOpenBottom = true)
          : (this.dropdownOpenBottom = false);
      });
    },
    handleScroll() {
      const searchResults = this.$refs[this.searchResultsRef];
      // This code checks if the user has scrolled to the bottom of the search results
      // and if so, loads the next page of search results.
      if (
        searchResults.scrollTop + searchResults.offsetHeight >=
        searchResults.scrollHeight
      ) {
        this.itemsPerPage += this.itemsPerPage;
      }
    },
  },
  mounted() {
    console.log(this.results);
  },
  computed: {
    getItems() {
      const filtered = this.results.filter((item) =>
        item.name.toLowerCase().includes(this.search.toLowerCase())
      );

      console.log(filtered);
      return filtered.slice(0, this.itemsPerPage);
    },
  },
};
</script>

<style scoped>
.dropdown-wrapper {
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: flex-end;
}

.dropdown-header {
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 8px;
}

input[type="text"],
.dropdown {
  border: 1px solid var(--gray5);
  border-radius: 4px;
  outline: none;
}

input[type="text"] {
  height: 32px;
  padding: 8px;
}

.dropdown {
  min-height: 38px;
  height: auto;
  max-height: 60px;
  padding-block: 4px;
  padding-inline: 8px;
  background: var(--white1);
  display: flex;
  align-items: flex-start;
  overflow: auto;
}

.dropdown > p {
  align-self: center;
  justify-self: flex-start !important;
}

.selected-items-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  gap: 4px;
  width: 100%;
  transition: 200ms;
  margin: auto;
}

.selected-item {
  color: var(--white1);
  padding-block: 4px;
  padding-inline: 8px;
  background: var(--focus1);
  border-radius: 1rem;
  animation: fade-in-left 200ms;
  white-space: nowrap;
}

i {
  width: 14px;
  height: 100%;
  position: absolute;
  right: 8px;
  top: 0;
  align-self: center !important;
}

.rotateIcon {
  transform: rotateZ(180deg);
}

input[type="text"],
.dropdown {
  width: 100%;
}

span {
  color: var(--error);
}

input[type="text"]:focus,
.dropdown:focus {
  border-color: var(--focus1);
  box-shadow: 0 0 1px 4px var(--focus2);
}

.search-wrapper-bottom {
  background: var(--white1);
  user-select: none;
  border: 1px solid var(--gray5);
  border-top: none;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px,
    inset rgba(149, 157, 165, 0.1) 0px 8px 24px;
  animation: slide-bottom 200ms;
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 100;
  display: flex;
  flex-flow: column;
}

.search-wrapper-top {
  background: var(--white1);
  user-select: none;
  border: 1px solid var(--gray5);
  border-bottom: none;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  animation: slide-bottom 200ms;
  position: absolute;
  bottom: 100%;
  right: 0;
  z-index: 100;
  display: flex;
  flex-flow: column-reverse;
}

.search-input-wrapper {
  padding: 4px;
}

.search-input {
  box-shadow: none !important;
}

.search-results {
  top: 100%;
  right: 0;
  max-height: 220px;
  overflow: auto;
}

.search-item:first-child {
  margin-top: 1px;
}

.search-item {
  width: 100%;
  padding: 8px;
  cursor: pointer;
  background: var(--white1);
  display: flex;
  gap: 8px;
  align-items: center;
}

.search-item:hover {
  background: var(--focus3);
}

.search-img {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  object-fit: cover;
}

.selected {
  background: var(--focus2);
  box-shadow: 0 0 1px 1px var(--focus1);
}

.selected:hover {
  background: var(--focus2);
}

@keyframes slide-bottom {
  from {
    transform: translateY(-10px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fade-in-left {
  from {
    transform: translateX(-10px);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}
</style>
