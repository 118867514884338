<template>
  <main class="form-wrapper">
    <h1 class="-xltb column-span-4" style="white-space: none">Create Unit</h1>
    <section class="column-span-4 steps-wrapper">
      <section
        v-for="(step, index) in steps"
        :key="step"
        class="step"
        :class="{ 'step-active': currentStep >= index + 1 }"
      >
        <p
          class="-stb step-number"
          :class="{ 'step-number-active': currentStep >= index + 1 }"
        >
          {{ index + 1 }}
        </p>
        <p class="-stb">{{ step }}</p>
      </section>
    </section>
    <hr class="column-span-4" />
    <section
      class="column-span-4 step-content"
      ref="step1"
      v-show="currentStep === 1"
    >
      <div class="column-span-4">
        <p class="-xstr span-row">
          Specify the foundational details for your project.
        </p>

        <div class="column-span-4">
          <div class="column-span-1 flex-column">
            <p class="-xstb">
              Unit Title <span title="Mandatory Field">*</span>
            </p>
            <p class="-xstr">Descriptive name for your unit</p>
          </div>
          <div class="field column-span-2">
            <input
              ref="titleRef"
              type="text"
              v-model.trim="title"
              minlength="2"
              maxlength="100"
              @focus="validateString(title, 'titleRef')"
              class="-xstb"
            />
            <p class="-xstb error-msg">{{ get_error_msg(title, 2, 100) }}</p>
          </div>
        </div>

        <div class="column-span-4">
          <div class="column-span-1 flex-column">
            <p class="-xstb">
              Reference <span title="Mandatory Field">*</span>
            </p>
            <p class="-xstr">Provide a unique identifier.</p>
          </div>
          <div class="field column-span-1">
            <input
              ref="referenceRef"
              type="text"
              v-model.trim="reference"
              minlength="2"
              maxlength="10"
              @focus="validateString(reference, 'referenceRef')"
              class="-xstb"
            />
            <p class="-xstb error-msg">{{ get_error_msg(reference, 2, 10) }}</p>
          </div>
        </div>

        <div class="column-span-4">
          <div class="column-span-1 flex-column">
            <p class="-xstb">Code</p>
            <p class="-xstr">For a rapid project classification.</p>
          </div>
          <div class="field column-span-1">
            <input
              ref="codeRef"
              type="text"
              v-model.trim="code"
              minlength="2"
              maxlength="6"
              class="-xstb"
              @focus="validateString(code, 'codeRef')"
            />
            <p class="-xstb error-msg">{{ get_error_msg(code, 2, 6) }}</p>
          </div>
        </div>
      </div>
    </section>
    <section
      class="column-span-4 step-content"
      ref="step2"
      v-show="currentStep === 2"
    >
      <p class="-xstr span-row">Clarify the intricacies of your project.</p>
      <div class="column-span-4">
        <div class="column-span-1 flex-column">
          <p class="-xstb">Category <span title="Mandatory Field">*</span></p>
          <p class="-xstr">Select a template as a starting point.</p>
        </div>
        <div class="field column-span-2">
          <div
            class="width100"
            ref="categoryRef"
            @click="validateString(category, 'categoryRef')"
          >
            <SearchDropdown
              :placeholder="'Set the category'"
              :titles="setSearchDropdownCategories"
              :setImgState="true"
              :imgs="setSearchDropdownCategoryIcons"
              @setItem="setCategory"
            />
          </div>

          <p class="-xstb error-msg">{{ error_msg }}</p>
        </div>
      </div>
      <!-- <hr class="column-span-4" /> -->
      <div class="column-span-4">
        <div class="column-span-1 flex-column">
          <p class="-xstb">Purpose <span title="Mandatory Field">*</span></p>
          <p class="-xstr">Specify the intended function or use.</p>
        </div>
        <div class="field column-span-2">
          <div class="width100" ref="purposeRef">
            <MultiSearchDropdown
              :placeholder="'Set the purpose of this unit'"
              :titles="setPurposesList"
              @setItems="setPurpose"
            />
          </div>
          <p class="-xstb error-msg">{{ error_msg }}</p>
        </div>
      </div>
      <!-- <hr class="column-span-4" /> -->
      <div class="column-span-4">
        <div class="column-span-1 flex-column">
          <p class="-xstb">
            Type of Work <span title="Mandatory Field">*</span>
          </p>
          <p class="-xstr">Select the nature of the project.</p>
        </div>
        <div class="field column-span-2">
          <div class="width100" ref="typeOfWorkRef">
            <MultiSearchDropdown
              :placeholder="'Define the Type of Work'"
              :titles="setWorkTypesList"
              @setItems="setWorkType"
            />
          </div>
          <p class="-xstb error-msg">{{ error_msg }}</p>
        </div>
      </div>
    </section>
    <section
      class="column-span-4 step-content"
      ref="step3"
      v-show="currentStep === 3"
    >
      <p class="-ntb span-row">Summary</p>

      <section class="column-span-4 flex-column gap8">
        <div class="column-span-4">
          <p class="-xstb column-span-1">Project Title:</p>
          <p class="-str column-span-2">{{ title }}</p>
        </div>
        <div class="column-span-4">
          <p class="-xstb column-span-1">Project Reference:</p>
          <p class="-str column-span-2">{{ reference }}</p>
        </div>
        <div class="column-span-4" v-if="code.length">
          <p class="-xstb column-span-1">Project Code:</p>
          <p class="-str column-span-2">{{ code }}</p>
        </div>
        <div class="column-span-4">
          <p class="-xstb column-span-1">Category:</p>
          <p class="-str column-span-2">{{ category.name }}</p>
        </div>
        <div class="column-span-4" v-if="purpose.length">
          <p class="-xstb column-span-1">
            {{ purpose.length > 1 ? "Purposes:" : "Purpose" }}
          </p>
          <div class="column-span-2">
            <p class="-str" v-for="p in purpose" :key="p">{{ p.name }}</p>
          </div>
        </div>
        <div class="column-span-4" v-if="typeOfWork.length">
          <p class="-xstb column-span-1">
            {{ typeOfWork.length > 1 ? "Types of work:" : "Type of work" }}
          </p>
          <div class="column-span-2">
            <p class="-str" v-for="type in typeOfWork" :key="type">
              {{ type.name }}
            </p>
          </div>
        </div>
        <hr class="column-span-4" />

        <div class="column-span-4">
          <div class="column-span-1 flex-column">
            <p class="-xstb">Add a description</p>
            <p class="-xstr">(Optional)</p>
          </div>

          <div class="column-span-3" style="width: calc(100% - 4px)">
            <textarea
              rows="4"
              cols="50"
              maxlength="255"
              v-model.trim="description"
              ref="descRef"
              class="-xstb"
            ></textarea>
            <p class="-xstr" style="color: var(--gray3); text-align: right">
              {{ $getRemainingChars(description, 255) }} characters left
            </p>
          </div>
        </div>
      </section>
    </section>
    <hr class="column-span-4" />
    <section class="btns-wrapper">
      <div class="btn-item" :class="{ opacity0: currentStep == 1 }">
        <BaseButton
          :state="'secondary'"
          :title="'Previous Step'"
          @click="previousStep"
        />
      </div>
      <div class="btn-item">
        <BaseButton
          :state="'primary'"
          :title="[currentStep !== lastStep ? 'Next Step' : 'Submit']"
          @click="handlePrimaryBtn"
        />
      </div>
    </section>
  </main>
</template>

<script>
import BaseButton from "../../utils/BaseButton.vue";
import SearchDropdown from "../../utils/SearchDropdown.vue";
import MultiSearchDropdown from "../../utils/MultiSearchDropdown.vue";
import {
  addErrInputText,
  removeErrInputText,
} from "../../../helpers/styleToggler";
import { validateForm } from "../../../helpers/formValidations";
import { nextTick } from "process";
import { createUnit } from "@/services/requests/post/projects";
export default {
  emits: ["closeModal"],
  components: {
    BaseButton,
    SearchDropdown,
    MultiSearchDropdown,
  },
  data() {
    return {
      tempCategoriesList: [
        {
          name: "Building",
          icon: require("../../../assets/icons/unitTemplates/building-template.svg"),
        },
      ],
      tempWorkTypeList: [
        {
          name: "New Construction",
          icon: "",
        },
        {
          name: "Demolition",
          icon: "",
        },
        {
          name: "Rehabilitation",
          icon: "",
        },
        {
          name: "Reconstruction",
          icon: "",
        },
        {
          name: "Alteration",
          icon: "",
        },
        {
          name: "Expansion",
          icon: "",
        },
        {
          name: "Conservation",
          icon: "",
        },
        {
          name: "Other types of intervention",
          icon: "",
        },
      ],
      tempPurposeList: [
        {
          name: "Residential Buildings",
          icon: "",
        },
        {
          name: "Educational Buildings",
          icon: "",
        },
        {
          name: "Institutional Buildings",
          icon: "",
        },
        {
          name: "Assembly Buildings",
          icon: "",
        },
        {
          name: "Business Buildings",
          icon: "",
        },
        {
          name: "Mercantile Buildings",
          icon: "",
        },
        {
          name: "Industrial Buildings",
          icon: "",
        },
        {
          name: "Storage Buildings",
          icon: "",
        },
        {
          name: "Wholesale Establishment",
          icon: "",
        },
        {
          name: "Mixed Land Use Buildings",
          icon: "",
        },
        {
          name: "Hazardous Buildings",
          icon: "",
        },
        {
          name: "Detached Buildings",
          icon: "",
        },
        {
          name: "Educational Buildings",
          icon: "",
        },
      ],
      steps: ["Basic Information", "Define Template", "Confirm & Submit"],
      currentStep: 1,
      lastStep: 3,
      //form variables
      reference: "",
      code: "",
      title: "",
      category: { name: "", icon: "" },
      purpose: [],
      typeOfWork: [],
      description: "",
      //refs
      referenceRef: "referenceRef",
      codeRef: "codeRef",
      titleRef: "titleRef",
      categoryRef: "categoryRef",
      purposeRef: "purposeRef",
      descriptionRef: "descriptionRef",
      //static text
      error_msg: "This field is required!",
    };
  },
  methods: {
    async handlePrimaryBtn() {
      switch (this.currentStep) {
        case 1:
          if (!this.validateStep1()) return;
          this.currentStep++;
          console.log(this.currentStep);
          return;
        case 2:
          if (!this.validateStep2()) return;
          this.currentStep++;
          console.log(this.currentStep);
          return;
        case 3:
          const data = {
            frame_id: 1,
            unit_reference: this.reference,
            unit_code: this.code,
            unit_title: this.title,
            unit_description: this.description,
          };
          const res = await createUnit(data);
          console.warn(res);
          return;

        default:
          console.log("nothing to do");
          return;
      }
    },
    validateStep1() {
      const fieldsToValidate = [
        {
          ref: this.referenceRef,
          value: this.reference,
          minlength: 2,
          maxlength: 10,
        },
        { ref: this.titleRef, value: this.title, minlength: 2, maxlength: 100 },
      ];
      if (this.code.length == 1)
        fieldsToValidate.push({
          ref: this.codeRef,
          value: this.code,
          minlength: 2,
          maxlength: 6,
        });

      const infoToValidate = fieldsToValidate.map((item) => item.value);
      const elsShowErr = fieldsToValidate.map((item) => this.$refs[item.ref]);
      const minlengths = fieldsToValidate.map((item) => item.minlength);
      const maxlengths = fieldsToValidate.map((item) => item.maxlength);

      return validateForm(elsShowErr, infoToValidate, minlengths, maxlengths); //returns true if all fields are valid
    },
    validateStep2() {
      const fieldsToValidate = [
        {
          ref: this.categoryRef,
          value: this.category.name,
          minlength: 1,
          maxlength: 255,
        },
      ];

      const infoToValidate = fieldsToValidate.map((item) => item.value);
      const elsShowErr = fieldsToValidate.map((item) => this.$refs[item.ref]);
      const minlengths = fieldsToValidate.map((item) => item.minlength);
      const maxlengths = fieldsToValidate.map((item) => item.maxlength);

      return validateForm(elsShowErr, infoToValidate, minlengths, maxlengths); //returns true if all fields are valid
    },
    submitForm() {
      console.log("submitting form...");
    },
    previousStep() {
      const firstStep = 1;
      if (this.currentStep === firstStep) return;
      this.currentStep--;
      console.log(this.currentStep);
    },
    setCountry(country) {
      this.country.name = country.title;
      this.country.flag = country.img;

      console.log("THIS IS THE COUNTRY: ", this.country);
    },
    setCity(city) {
      this.city.name = city.title;
    },
    setCategory(category) {
      this.category.name = category.title;
      this.category.icon = category.img;
    },
    setPurpose(purpose) {
      this.purpose = purpose;
    },
    setWorkType(workType) {
      this.typeOfWork = workType;
    },
    validateString(value, inputRef) {
      const input = this.$refs[inputRef];
      nextTick(() => {
        if (!input.classList.contains("error-input")) return;
        removeErrInputText(this.$refs[inputRef]);
      });
    },

    validateEl(stringToValidate) {
      return stringToValidate.trim() ? true : false;
    },
    removeErrFromEl(refsArray) {
      const errorClassName = "error-input";
      refsArray.forEach((ref) => {
        const elClasses = this.$refs[ref].classList.value; //this is a string

        if (!elClasses.includes(errorClassName)) {
          return;
        } else {
          removeErrInputText(this.$refs[ref]);
        }
      });
    },
    get_error_msg(toValidate, minlength, maxlength) {
      return !toValidate.length
        ? this.error_msg
        : `Input must be between ${minlength} and ${maxlength} characters in length.`;
    },
  },
  computed: {
    setSearchDropdownCountries() {
      return this.countriesList.map((country) => country.name);
    },
    setSearchDropdownFlags() {
      return this.countriesList.map((country) => country.flag);
    },
    setSearchDropdownCities() {
      return this.citiesList.map((city) => city.name);
    },
    setSearchDropdownCategories() {
      return this.tempCategoriesList.map((category) => category.name);
    },
    setSearchDropdownCategoryIcons() {
      return this.tempCategoriesList.map((category) => category.icon);
    },
    setPurposesList() {
      return this.tempPurposeList.map((p) => p.name);
    },
    setWorkTypesList() {
      return this.tempWorkTypeList.map((w) => w.name);
    },
  },
  watch: {
    currentStep: {
      handler: function (newVal) {
        const domEl = this.$refs[`step${newVal}`];
        domEl.scrollIntoView({
          behavior: "smooth",
          inline: "start",
        });
      },
    },
  },
};
</script>

<style scoped>
.form-wrapper {
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding-inline: 32px;
  padding-block: 16px;
  gap: 12px;
  background: var(--white1);
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

.span-row {
  grid-column: span 4;
  align-self: center;
  justify-self: start;
  padding-inline: 4px;
}

.column-span-4 {
  grid-column: span 4;
  align-self: start;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 12px;
  padding-inline: 4px;
}

.column-span-3 {
  grid-column: span 3;
  align-self: start;
}

.column-span-2 {
  grid-column: span 2;
  align-self: start;
  position: relative;
}

.column-span-1 {
  grid-column: span 1;
  align-self: start;
}

.steps-wrapper {
  display: flex;
  justify-content: flex-start;
  gap: 2rem;
  user-select: none;
}

.step {
  display: flex;
  align-items: center;
  padding-inline: 8px;
  gap: 8px;
  border-bottom: 2px solid var(--gray5);
  color: var(--gray4);
  height: 40px;
}

.step-number {
  border-radius: 50%;
  min-width: 24px;
  max-width: 24px;
  min-height: 24px;
  max-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: var(--gray5) 0 0 0 2px;
}

.step-number-active {
  box-shadow: var(--highlight) 0 0 0 2px;
}

.step-active {
  border-bottom: 2px solid var(--highlight);
  color: var(--highlight);
}

.main-content-wrapper {
  grid-column: span 4;
  max-width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  transition: 200ms ease;
  isolation: isolate;
  overflow-x: hidden;
}

.step-content {
  transition: 400ms ease-out;
  animation: smooth-hslide-in 320ms ease-out;
}

.field {
  display: flex;
  flex-direction: column;
}

.city-wrapper {
  /*the icon space */
  padding-left: 40px;
}

hr {
  border: none;
  border-top: 1px solid var(--gray6);
  margin-block: 8px;
}

.btns-wrapper {
  grid-column: 1 / span 4;
  width: 100%;
  display: flex;
  justify-content: space-between;
  justify-self: end;
  padding-top: 8px;
}

/* .error-msg {
  white-space: nowrap;
} */

.selected,
.selected:hover {
  background: var(--success-light);
}

/* *** GLBOALIZAR *** */
input[type="text"],
select,
option,
textarea {
  border: 1px solid var(--gray5);
  border-radius: 2px;
  padding: 8px;
  outline: none;
}

input[type="text"],
select {
  height: 38px;
}

input[type="text"],
select,
textarea {
  width: 100%;
}

textarea {
  resize: none;
  height: 80px;
}

span {
  color: var(--error);
}

/**EFFECTS**/
input[type="text"]:focus,
select:focus,
option:focus,
textarea:focus {
  border-color: var(--focus1);
  box-shadow: 0 0 1px 3px var(--focus2);
}

.flag {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  object-fit: cover;
  outline: 1px solid var(--gray6);
}

.templates-wrapper {
  --tempalte-item-width: 132px;
  grid-column: span 4;
  display: grid;
  grid-template-columns: repeat(auto-fill, var(--tempalte-item-width));
  justify-content: center;
  gap: 1rem;
  padding: 1rem;
  background: var(--white2);
  border: 1px solid var(--gray6);
  border-radius: 2px;
  max-height: 220px;
  overflow: auto;
}

.template-item {
  position: relative;
  background: var(--white1);
  width: var(--tempalte-item-width);
  height: 140px;
  border: 1px solid var(--gray5);
  border-radius: 2px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding-inline: 4px;
  padding-block: 1rem;
  gap: 1rem;
  transition: 200ms;
  overflow: hidden;
  opacity: 0.4;
}

.template-item:hover {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 12px;
}

.template-item > img {
  width: 60px;
  height: 60px;
  object-fit: contain;
  filter: grayscale(100%);
  border-radius: 2px;
}

.template-item-description {
  transition: 400ms ease;
  position: absolute;
  padding: 8px;
  top: 100%;
  color: var(--white1);
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  overflow: auto;
}

.template-item:hover > .template-item-description {
  top: 0;
}

p {
  user-select: none;
}

.-xltb {
  white-space: nowrap;
}

.step,
.step-number {
  transition: all 200ms ease-in-out !important;
}

.exit {
  opacity: 0;
  transform: translateX(-20%);
}

.custom-fade-in {
  animation: custom-fade-in 400ms ease-in-out;
}

@keyframes custom-fade-in {
  from {
    opacity: 0;
    transform: translateY(-20%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* *** QUERIES *** */
@media screen and (max-width: 599px) {
  .column-span-3 {
    grid-column: span 4;
    width: 100%;
  }

  .column-span-2 {
    grid-column: span 4;
    width: 100%;
  }

  .column-span-1 {
    grid-column: span 4;
    width: 100%;
  }

  .step-number {
    display: none;
  }

  .steps-wrapper {
    justify-content: space-between;
  }
}

@media screen and (max-width: 454px) {
  .error-msg {
    white-space: normal;
  }

  .step-number {
    display: flex;
  }

  .step p:last-child {
    display: none;
  }
}
</style>
